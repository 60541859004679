import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RemoteLibraryService } from 'remote-library';

import { DocumentFile, DocumentState } from './document';

@Component({
  selector: 'app-document-files',
  templateUrl: './document-files.component.html',
  styleUrls: ['./document-files.component.scss'],
})
export class DocumentFiles implements OnInit {

  private allowedExts = ['png', 'jpeg', 'jpg', 'pdf'];

  public loading: boolean;
  public files: DocumentFile[] = [];
  public errorMessage: string;
  public maxSize = 10 * 1024 * 1024;
  public documentToDelete?: DocumentFile;
  protected errorMessageTimer = undefined;
  protected config;

  public modalButtons;

  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.config = this.remoteService.selfAdjustService.myPage('document-files');
    const maxFiles: number = this.config.maxFiles || 2;
    this.remoteService.selfAdjustService.actualSelfAdjust.documents = this.remoteService.selfAdjustService.actualSelfAdjust.documents || [];
    this.fillFiles(maxFiles);

    this.modalButtons = [
      {
        text: this.remoteService.selfAdjustLanguageService.strLang('delete_cancel'),
        type: 'link',
        callback: () => this.documentToDelete = undefined,
      },
      {
        text: this.remoteService.selfAdjustLanguageService.strLang('delete_confirm'),
        type: 'danger',
        callback: () => {
          this.deleteFile(this.documentToDelete);
          this.documentToDelete = undefined;
        },
      }
    ];
  }

  ngAfterViewInit() {
    this.remoteService.selfAdjustService.removeIndexLoader();
    DocumentFiles.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }

    /**
     * @description Función para carga de estilos personalizados
     * @param customStylesFolder Nombre de la carpeta donde están los estilos personalizados
     */
    private static loadDynamicStyles(customStylesFolder) {
      if (customStylesFolder) {
        try {
          require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
        } catch (error) {
          console.log('Error loading the dynamic styles');
        }
      }
    }

  fillFiles(maxFiles: number): void {
    for (let i = 0; i < maxFiles; i++) {
      this.files.push({
        id: i,
        name: '',
        type: `damage_${i}`,
        state: DocumentState.Empty,
        disabled: false,
        uuid: undefined,
      });
    }

    const selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.files.forEach((file, index) => {
      const selfadjustFiles = selfadjust.documents;
      const selfadjustFile = selfadjustFiles.find((file) => file.index == index);
      if (selfadjustFile) {
        file.state = DocumentState.Loaded;
        file.name = selfadjustFile.name;
        file.uuid = selfadjustFile.uuid;
      }
    });
  }

  private async getMediaUrl(file: File) {
    const extension = file.name.split('.').reverse()[0];
    const { id: selfadjustId } = this.remoteService.selfAdjustService.actualSelfAdjust;
    const { media_url, media_id } = await this.remoteService.selfAdjustService
      .getMediaUrl(selfadjustId, extension.toLowerCase() === 'jpeg' ? 'jpg' : extension, 'selfadjustDocuments')
      .toPromise();
    return {
      mediaUrl: media_url,
      mediaId: media_id,
    };
  }

  private async uploadToS3(resized: string, mediaUrl: string, contentType: string) {
    await this.remoteService.selfAdjustService.bucketImage(resized, mediaUrl, contentType).toPromise();
  }

  private async updateFiles(uuid: string, name: string, index: number) {
    const { id: selfadjustId } = this.remoteService.selfAdjustService.actualSelfAdjust;
    const file = {
      uuid,
      name,
      index,
    };
    await this.remoteService.selfAdjustService.addFile(selfadjustId, file).toPromise();

    try {
      this.remoteService.selfAdjustService.actualSelfAdjust.documents.push(file);
    } catch (e) {
      console.log('Error: ', e);
    }
  }

  encodeFileToBase64URL(file: File): Promise<string> {
    if (file.type.startsWith('image/')) {
      return this.remoteService.selfAdjustService.resizeAndDraw({ files: [file] }, 1280);
    }

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.onerror = (error) => reject(error);
    });
  }

  async fileChangedHandler(document: DocumentFile) {
    this.errorMessage = undefined;
    if (document.file.size > this.maxSize) {
      const message = this.remoteService.selfAdjustLanguageService.strLang(
        'document_files_max_file_size_exceeded',
        { maxSize: this.maxSize / (1024 * 1024) }
      );
      this.showErrorMessage(message);
      return;
    }

    const name = document.file.name.toLowerCase();
    const [ext] = name.split('.').reverse();
    if (!this.allowedExts.includes(ext.toLowerCase())) {
      // Show a message
      const message = this.remoteService.selfAdjustLanguageService.strLang('document_invalid_format');
      this.showErrorMessage(message);
      return;
    }

    this.files.forEach((documentFile) => {
      if (document != documentFile) {
        documentFile.disabled = true;
      }
    });

    document.state = DocumentState.Uploading;

    try {
      document.name = this.getUniqueName(document.file.name);

      const encodedContent = await this.encodeFileToBase64URL(document.file);
      const { mediaUrl, mediaId } = await this.getMediaUrl(document.file);
      await this.uploadToS3(encodedContent, mediaUrl, document.file.type);
      await this.updateFiles(mediaId, document.name, document.id);

      document.uuid = mediaId;
      document.state = DocumentState.Loaded;
    } catch (e) {
      console.log('Error: ', e);
      this.showErrorMessage('Error in image upload. Please, try again.');
      document.state = DocumentState.Empty;
    }

    this.files.forEach((documentFile) => (documentFile.disabled = false));
  }

  public fileDeletedHandler(document: DocumentFile) {
    this.documentToDelete = document;
  }

  async deleteFile(document: DocumentFile) {
    this.errorMessage = undefined;
    document.state = DocumentState.Uploading;

    this.files.forEach((documentFile) => {
      if (document != documentFile) {
        documentFile.disabled = true;
      }
    });

    const selfadjustId = this.remoteService.selfAdjustService.actualSelfAdjust.id;
    try {
      const response = await this.remoteService.selfAdjustService.deleteFile(selfadjustId, document.uuid).toPromise();
      const documents = (await response.json()).updatedSelfadjust.documents || [];
      this.remoteService.selfAdjustService.actualSelfAdjust.documents = documents;
      document.state = DocumentState.Empty;
    } catch (e) {
      console.error(e);
      this.showErrorMessage('document_delete_error');
      document.state = DocumentState.Loaded;
    }

    this.files.forEach((documentFile) => (documentFile.disabled = false));
  }

  getUniqueName(name: string) {
    const nameParts = name.split('.');
    let extension = nameParts.pop();
    extension = extension.toLowerCase() === 'jpeg' ? 'jpg' : extension;
    const fileName = nameParts.join('.');
    let index = 0;
    name = `${fileName}.${extension}`;

    while (this.files.find((file) => file.name === name)) {
      index++;
      name = `${fileName}${index}.${extension}`;
    }

    return name;
  }

  showErrorMessage(message: string) {
    if (this.errorMessageTimer) {
      clearTimeout(this.errorMessageTimer);
    }
    this.errorMessage = message;
    this.errorMessageTimer = setTimeout(() => {
      this.errorMessage = undefined;
    }, 5000);
  }

  someLoaded() {
    return this.files.some((file) => file.state === DocumentState.Loaded);
  }

  someUploading() {
    return this.files.some((file) => file.state === DocumentState.Uploading);
  }

  nextPage() {
    this.loading = true;
    const data = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      status: this.remoteService.selfAdjustService.AppStatus.inprocess,
      logInfo: {
        component: 'document-files',
        action: 'continue',
      },
      fields2Update: {},
    };
    this.remoteService.selfAdjustService.pushData(data).subscribe(() => {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      const commands = [
        ...this.remoteService.selfAdjustService.nextPage,
        {
          secretKey: this.remoteService.selfAdjustService.secretKey,
        },
      ];
      this.loading = false;
      this.router.navigate(commands);
    }, (err) => {
      console.log('Error in selfadjust-update', err);
    });
  }

  backPage() {
    console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    const commands = [
      ...this.remoteService.selfAdjustService.backPage,
      {
        secretKey: this.remoteService.selfAdjustService.secretKey,
      },
    ];
    this.router.navigate(commands);
  }
}
