export enum DocumentState {
  Empty = 'empty',
  Uploading = 'uploading',
  Loaded = 'loaded',
};

export type DocumentFile = {
  id: number,
  name: string,
  uuid: string | undefined,
  type: string,
  file?: File,
  state: DocumentState,
  disabled: boolean,
};