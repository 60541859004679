import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { RemoteLibraryService } from "remote-library";
import { map } from "rxjs/operators";

// TODO: unhardcode this and move it to backend
const domainCompanyMap = {
  'verificacion.axa.es': 'd2b6cf85-b8e9-411d-9c15-39fad63ddc84',
  'verificacion.directseguros.es': '26c85e7c-f1fd-44d6-b7bd-0cf8f5561d22',
};

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  loaded = false;
  company;
  public cookiesPolicy = false;

  constructor(
    private remoteService: RemoteLibraryService,
    @Inject(DOCUMENT) private document: Document
  ) {}
  
  ngOnInit() {
    const companyId = domainCompanyMap[Object.keys(domainCompanyMap).find(domain => this.document.location.hostname == domain)];
    if(companyId) {
      this.remoteService.selfAdjustService.getCompanyData(companyId).subscribe((company) => {
        this.remoteService.selfAdjustService.actualCompany = company;
        this.remoteService.selfAdjustService.actualSelfAdjust = {};
        this.remoteService.selfAdjustService.setCompanyConfig(company);
        this.loaded = true;
        this.remoteService.selfAdjustService.removeIndexLoader();
        const { cookiesPolicy = {} } = this.remoteService.selfAdjustService.actualCompany;
        this.cookiesPolicy = this.remoteService.commonService.showCookiesModal(cookiesPolicy);
        return this.company = company;
      });
    } else {
      this.loaded = true;
      this.remoteService.selfAdjustService.removeIndexLoader();
    }
  }
}