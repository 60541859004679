import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RemoteLibraryService } from 'remote-library';
import { ISelfadjustError } from 'projects/remote-library/src/interfaces/selfadjust-error.interface';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit {
  @Input() error: ISelfadjustError;
  @Output() finish = new EventEmitter<string>();

  public steps: any;
  public imagePath: string;
  public showLegalFooter = false;

  constructor(private remoteLibraryService: RemoteLibraryService) {}

  ngOnInit() {

    let language = this.remoteLibraryService.selfAdjustLanguageService.getGenericLocale();

    const ios = this.remoteLibraryService.selfAdjustService.isIos;

    this.imagePath = `./assets/images/error/${ios ? 'ios' : 'android'}/${language}/`;

    this.showLegalFooter = this.remoteLibraryService.selfAdjustService.actualCompany.showLegalFooter;

    if (!this.error.steps) {
      return;
    }

    this.steps = ios ? this.error.steps.ios : this.error.steps.android;
  }

  public translate(key: string) {
    return this.remoteLibraryService.selfAdjustLanguageService.strLang(key);
  }

  public finishError() {
    const { securityKey } = this.remoteLibraryService.selfAdjustService.actualSelfAdjust;
    const link = `${window.location.origin}/#/landing?secretKey=${securityKey}`;
    this.finish.emit(link);
  }
}
