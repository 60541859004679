import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import { NgForm } from '@angular/forms';
import { skip, take } from 'rxjs/operators';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/platform-browser';


@Component({
  selector: 'app-information-form',
  templateUrl: './information-form.component.html',
  styleUrls: ['./information-form.component.scss'],
})
export class InformationFormComponent implements OnInit {

  @ViewChild('myForm')
  myForm: NgForm;
  loading = false;
  loaded = false;
  emailConfirmError: String = '';
  dateError: String = '';
  timeError: String = '';
  formError: String = '';
  textError: any = {};
  selectorValues: any = {};


  @ViewChild('fillValues')
  fillValues: ElementRef;
  @ViewChild('mainBody')
  private myScrollContainer: ElementRef;

  firstName: any;
  isMandatory = true;
  informationForm: any;
  selfadjust: any;
  compareReopenInfo = {};
  email_confirm: any;
  unfixed: any;
  showLegalFooter: Boolean = null;

  constructor(
    public router: Router,
    public remoteService: RemoteLibraryService,
    public cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
   ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.informationForm = this.remoteService.selfAdjustService.myPage('information-form');
    this.isMandatory = this.informationForm.mandatory;
    this.selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.formatFields();
    if (this.informationForm.fixed !== undefined && !this.informationForm.fixed) {
      this.unfixed = true;
      if (this.informationForm.selfadjustFields) {
        this.informationForm.selfadjustFields.forEach(element => {
          if (element) {
            this.compareReopenInfo[`${element.fieldName}`] = this.selfadjust[`${element.fieldName}`];
          }
        });
      } else {
        if (this.informationForm.fixedFields[0]) {
          this.compareReopenInfo['email'] = this.selfadjust.email;
        }
        if (this.informationForm.fixedFields[1]) {
          this.compareReopenInfo['textfield2'] = this.selfadjust.textfield2;
        }
        if (this.informationForm.fixedFields[2]) {
          this.compareReopenInfo['textfield3'] = this.selfadjust.textfield3;
        }
      }
      this.myForm.valueChanges.pipe(skip(1)).subscribe(this.isFixed.bind(this));
    }
    this.loading = false;
    this.loaded = true;
    this.showLegalFooter = this.remoteService.selfAdjustService.actualCompany.showLegalFooter;
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      if (customStylesFolder === 'Banorte'
      || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-information-form')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  isFixed(val) {
    if (this.remoteService.selfAdjustService.isPageFixed('information-form')) {
      this.unfixed = false;
    } else {
      for (let key in val) {
        if (val[key] === this.compareReopenInfo[key]) {
          this.unfixed = true;
          break;
        } else {
          this.unfixed = false;
        }
      }
    }
  }

  next(myForm) {
    this.loading = true;
    let data: any;
    this.revalidation();
    if(this.myForm.errors){
      this.formError = `There are some invalid values, please check the form for any errors`;
      this.scrollToTop();
      this.loading = false;
    }else{
      if (this.informationForm.selfadjustFields) {
        data = {
          security_key: this.remoteService.selfAdjustService.secretKey,
          status: this.informationForm.fixed !== undefined ? this.remoteService.selfAdjustService.AppStatus.reopened : this.remoteService.selfAdjustService.AppStatus.inprocess,
          fields2Update: {},
          logInfo: {
            component: 'information-form',
            action: 'continue'
          }
        };
        myForm._directives.forEach(e => {
          if (e.value!=='') {
            data.fields2Update[e.name] = e.value;
          }
        });
        for (let item of this.informationForm.selfadjustFields) {
          if (item && item.requires && !this.myForm.value[item.requires]) {
            data.fields2Update[item.fieldName] = null;
          }
        }
      } else {
        data = {
          security_key: this.remoteService.selfAdjustService.secretKey,
          fields2Update: {
            email: this.myForm.value.email,
            textfield2: this.myForm.value.textfield2,
            textfield3: this.myForm.value.textfield3
          },
          status: this.informationForm.fixed !== undefined ? this.remoteService.selfAdjustService.AppStatus.reopened : this.remoteService.selfAdjustService.AppStatus.inprocess,
          logInfo: {
            component: 'information-form',
            action: 'continue'
          }
        };
      }
      if (this.informationForm.fixed !== undefined && !this.informationForm.fixed) {
        this.remoteService.selfAdjustService.fixedPage('information-form');
      }
      if (this.remoteService.selfAdjustService.getReopenInfo() && this.informationForm.fixed !== undefined) {
        data.fields2Update['reopenInfo'] = { pages: null };
        data.fields2Update['reopenInfo'].pages = this.remoteService.selfAdjustService.getReopenInfo();
      }
      if (data) {
        this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(res => {
          console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
          this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]).then(function(){
            if(this.remoteService.selfAdjustService.nextPage[1] == 'information-form') {
              document.querySelector('app-information-form').querySelector('div.mainBody').scrollTo(0,0);
              this.ngOnInit();
            }
          }.bind(this));
        } ,err => {
          console.log(err)
        });
      }
    }
  }

  back() {
    console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]).then(function(){
      if (this.remoteService.selfAdjustService.backPage[1] === 'information-form') {
        this.ngOnInit();
      }
    }.bind(this));
  }

  checkEmailConfirm(email) {
    if (!this.myForm.value.email_confirm || this.myForm.value.email_confirm === '') {
      this.emailConfirmError = 'Repeat email address';
      this.myForm.control.setErrors({'nomatch': true});
    } else if (email !== this.myForm.value.email_confirm) {
      this.emailConfirmError = 'Email missmatch';
      this.myForm.control.setErrors({'nomatch': true});
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      this.emailConfirmError = 'Invalid Email';
      this.myForm.control.setErrors({'nomatch': true});
    } else {
      this.emailConfirmError = '';
      this.myForm.control.setErrors(null);
    }
  }

  checkMaxDate(date, max, min) {
    if (max || min) {
      if (moment(date, 'YYYY-MM-DD').isAfter(moment(max, 'YYYY-MM-DD'))) {
        this.dateError = `You can't select a future date`;
        this.myForm.control.setErrors({'nomatch': true});
      } else if (moment(date, 'YYYY-MM-DD').isBefore(moment(min, 'YYYY-MM-DD'))) {
        this.dateError = `You can't select a past date`;
        this.myForm.control.setErrors({'nomatch': true});
      } else {
        this.dateError = '';
        this.myForm.control.setErrors(null);
      }
    }
  }

  checkValidText(text, mandatory, fieldName) {
    if(mandatory) {
      if(text.length > 0 && text.trim() === '') {
        this.textError[fieldName] = 'Enter a valid text';
        this.myForm.control.setErrors({'nomatch': true});
      } else {
        this.textError[fieldName] = '';
        this.checkTextErrors();
      }
    }
  }

  checkTextErrors() {
    let withError = false;
    Object.keys(this.textError).forEach(function(key) {
      if(this.textError[key] !== '') {
        withError = true;
      }
    });
    if (!withError) {
      this.myForm.control.setErrors(null);
    }
  }

  checkMaxTime(time, max, min, referenceDate) {
    this.timeError = '';
    if(referenceDate && this.selfadjust[referenceDate]){
      referenceDate = this.selfadjust[referenceDate];
      if((max == 'today' && moment(referenceDate,'YYYY-MM-DD').isAfter(moment().startOf('day'))) || (min == 'today' && moment(referenceDate,'YYYY-MM-DD').isBefore(moment().startOf('day')))){
        this.timeError = `Invalid date`
        this.myForm.control.setErrors({'nomatch': true});
      }
    }else if(referenceDate){
      this.timeError = `Set date first`
      this.myForm.control.setErrors({'nomatch': true});
    }
    if ((max || min) && !this.timeError) {
      if(max == 'today' && moment(referenceDate,'YYYY-MM-DD').isSame(moment().startOf('day'))){
        max = moment().format('HH:mm');
      }
      if(min == 'today' && moment(referenceDate,'YYYY-MM-DD').isSame(moment().startOf('day'))){
        min = moment().format('HH:mm');
      }
      console.log('max', max)
      if (moment(time, 'HH:mm').isAfter(moment(max,'HH:mm'))) {
        this.timeError = `You can't select a future time`;
        this.myForm.control.setErrors({'nomatch': true});
      } else if (moment(time, 'HH:mm').isBefore(moment(min, 'HH:mm'))) {
        this.timeError = `You can't select a past time`;
        this.myForm.control.setErrors({'nomatch': true});
      } else {
        this.timeError = '';
        this.myForm.control.setErrors(null);
      }
    }
  }


  // Funcion para finalizar el formatos de los campos del form
  formatFields() {
    if (this.informationForm.selfadjustFields) {
      this.informationForm.selfadjustFields.map(element => {
        if(element) {
          if (element.dataType === 'date') { // Cambio en formato para campos tipo DATE
            element.maxDate = element.maxDate === 'today' ? moment(new Date()).format('YYYY-MM-DD') : element.maxDate;
            element.minDate = element.minDate === 'today' ? moment(new Date()).format('YYYY-MM-DD') : element.minDate;
          }
          if (element.dataType === 'emailConfirm') {
            if (this.informationForm.fixed !== undefined && !this.informationForm.fixed) {
              this.email_confirm = this.selfadjust[element.fieldName];
            }
          }
          // Check chained selector!!!!
          if (['selectorFromDB', 'chainedSelector', 'multiList', 'labelValue'].includes(element.dataType)) {
            let value = element.options_selector || element.listName;
            this.remoteService.selfAdjustService.getSelector(value).pipe(take(1)).subscribe((result: any) => {
              if (result.success) {
                this.selectorValues[element.fieldName] = result.items;
              } else {
                this.selectorValues[element.fieldName] = [];
              }              
            }, (err: any) => {
              console.log("loadData catch", err);
            });
          }
          return element;
        }
      });
    }
  }

  /**
   * Funcion para revalidar campos del form al hacer submit para evitar valores erroneos que no han pasado por su validacion.
   */
  revalidation(){
    if(this.informationForm.selfadjustFields) {
      for(let item of this.informationForm.selfadjustFields){
        if (item) {
          switch (item.dataType) {
            case 'time':
              this.checkMaxTime(this.selfadjust[item.fieldName], item.maxTime, item.minTime, item.referenceDate);
              break;
            case 'date':
              this.checkMaxDate(this.selfadjust[item.fieldName], item.maxDate, item.minDate);
              break;
            case 'emailConfirm':
              this.checkEmailConfirm(this.selfadjust[item.fieldName]);
          }
        }
      }
    }
  }

  /**
   * Funcion para hacer scroll hasta arriba de la vista
   */
  scrollToTop() {
    try {
      this.myScrollContainer.nativeElement.scrollTop = 0;
    } catch (err) { }
  }
}
