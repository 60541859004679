import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parentFilter'
})
export class ParentFilterPipe implements PipeTransform {

    transform(items: any[], value : string): any[] {  
        if (!items) return [];
        if (!value || value.length == 0) return [];
        return items.filter(it => it.parent === value);
    }
}