import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import { take } from "rxjs/operators";

import * as carAI from '../../aiHandlers/carHandler.js';
import * as motoAI from '../../aiHandlers/motoHandler.js';

@Component({
  selector: 'app-vehicle-type',
  templateUrl: './vehicle-type.component.html',
  styleUrls: ['./vehicle-type.component.scss']
})
export class VehicleTypeComponent implements OnInit {

  vehicleTypeQuestionInfo: any;
  // array con posibles tipos de vehiculos
  vehicleTypeSelector: string[];
  // tipo de vehiculo del autoajuste y modelo del formulario
  vehicleType: number;
  // falg de tipo de vehiculo ya elegido por el agente
  vehicleSelected: boolean;
  // flag de llamadas asincronas a servicios ejecutandose
  loading: boolean = false;

  constructor(
    public router: Router,
    public remoteService: RemoteLibraryService,
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.vehicleTypeQuestionInfo = this.remoteService.selfAdjustService.myPage('vehicle-type');
    if (this.remoteService.selfAdjustService.actualCompany.selfadjustInfo.vehicleTypeSelector != null) {
      this.vehicleTypeSelector = this.remoteService.selfAdjustService.actualCompany.selfadjustInfo.vehicleTypeSelector;
    }
    if (this.remoteService.selfAdjustService.actualSelfAdjust.vehicleType != null) {
      this.vehicleType = this.remoteService.selfAdjustService.actualSelfAdjust.vehicleType;
      this.vehicleSelected = true;
    }
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
        console.log('loadDynamicStyles catch', error);
      }
    }
  }

  selectVehicle(type) {
    this.vehicleType = type;
  }

  navigate(page) {
    console.log(
      'INFO: Navega a:', page,
      'con secretKey:', this.remoteService.selfAdjustService.secretKey
    );
    const linkParametersArray = [
      ...page,
      {
        secretKey: this.remoteService.selfAdjustService.secretKey,
      },
    ];
    this.router.navigate(linkParametersArray);
  }

  backPage() {
    this.navigate(this.remoteService.selfAdjustService.backPage);
  }

  nextPage() {
    this.navigate(this.remoteService.selfAdjustService.nextPage);
  }

  /**
  * @description al cambiar el tipo de vehiculo hay que traerse de nuevo la empresa
  * y su selfadjustInfo y aplicar el cambio de vehiculo al selfadjustInfo original.
  */
  loadCompany() {
    return new Promise((resolve, reject) => {
      const companyId = this.remoteService.selfAdjustService.actualSelfAdjust.master_company_id;
      this.remoteService.selfAdjustService.getCompanyData(companyId).pipe(take(1)).subscribe((company: any) => {
        if (!company || (company && company.errorMessage)) {
          console.log('getCompanyData error', company);
          reject();
        } else {
          this.remoteService.selfAdjustService.actualCompany = company;
          this.remoteService.selfAdjustService.setCompanyConfig(company);
          resolve();
        }
      }, (err: any) => {
        console.log('getCompanyData catch', err);
        reject();
      });
    });
  }

  async next() {
    this.loading = true;
    const data = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      status: this.remoteService.selfAdjustService.AppStatus.inprocess,
      fields2Update:{},
      logInfo: {
        component: 'vehicle-type',
        action: 'continue',
      },
    };
    if (this.vehicleType !== this.remoteService.selfAdjustService.actualSelfAdjust.vehicleType) {
      data['fields2Update']['vehicleType'] = this.vehicleType;
      this.remoteService.selfAdjustService.actualSelfAdjust.vehicleType = this.vehicleType;
      try {
        await this.loadCompany()
      } catch(e) {
        this.loading = false;
        return;
      }
      this.vehicleTypeQuestionInfo = this.remoteService.selfAdjustService.myPage('vehicle-type');
    }

    console.log('Start loading model...');
    this.remoteService.aiService.load({ carAI, motoAI }, true);
    console.log('Loading model in background...');
    
    this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe((res) => {
      this.nextPage();
    }, (err) => {
      console.log('pushData error', err);
      this.loading = false;
    });
  }
}
