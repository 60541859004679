import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, ComponentFactoryResolver, ViewChild, ViewContainerRef, ComponentRef, OnDestroy } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HelpPageBlock } from '../document-pictures-help/models/help-page-block';
import { UploadAreaState } from '../shared/upload-area/upload-area.component';
import { GalleryComponent } from '../shared/gallery/gallery.component';

type DocumentConfig = {
  id: string;
  name: string;
  name_es?: string;
  type: string;
  mode?: 'document' | 'image';
  capture: 'camera' | 'none';
  image: string;
  bgImage: string;
  isBGShow: boolean;
  loading: boolean;
  auxId: string;
  mandatory: boolean;
  detectDamagesOnlyIn?: string[];
  isFullfiled?: boolean;
  fix?: boolean;
  helpPage?: HelpPageBlock[];
}

type DocumentFile = {
  id: string;
  auxId: string;
  name: string;
  icon: string;
  type: string;
  mandatory: boolean;
  state: UploadAreaState;
  mode: 'document' | 'image';
  capture: 'camera' | 'none';
  image?: string;
}

const DEFAULT_DOC_CONFIG: DocumentConfig[] = [
  {
    id: 'policy',
    name: 'Policy',
    name_es: 'Póliza',
    type: 'policy',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_policy',
    mandatory: true
  },
  {
    id: 'license',
    name: 'Driver license',
    name_es: 'Licencia de conducir',
    type: 'license',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_license',
    mandatory: true
  },
  {
    id: 'plate',
    name: 'Plate',
    name_es: 'Placa',
    type: 'registration',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_plate',
    mandatory: true
  },
  {
    id: 'vin',
    name: 'VIN',
    name_es: 'VIN',
    type: 'vin',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_vin',
    mandatory: true
  }
];

@Component({
  selector: 'app-document-pictures',
  templateUrl: './document-pictures.component.html',
  styleUrls: [
    './document-pictures.component.scss'
  ]
})
export class DocumentPicturesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @Output() public onChangedNumImgDoc = new EventEmitter<boolean>();
  public numImages: number;  
  public image: string;
  public actualSelfadjust: any;
  public vehicleType: string;
  public notfication = new Subject();
  public documentConfigs: DocumentConfig[];
  public documentFiles: DocumentFile[];
  public loading = false;
  public loaded = false;
  public documentPictures: any;
  public nextAvailable = false;
  public openNotification = false;
  public showHelp = false;
  // Hacer esto genérico
  public showHelpInterior = false;
  public showHelpDisk = false;
  public showHelpOdometer = false;
  public showHelpTires = false;
  public showHelpWindscreen = false;
  // Fin ayudas Hollard
  public targetName: string;
  public photosQuantity: number;
  public unfixed = false;
  public unfixedArray: boolean[];
  public errorMessage = 'Error in image upload. Please, try again.';
  public isIos = navigator.userAgent.indexOf('iPhone') >= 0;
  public showLegalFooter: Boolean = null;
  public helpPage?: HelpPageBlock[];
  private galleryRef: ComponentRef<GalleryComponent>;
  private destroy$ = new Subject<void>();
  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public cd: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  private calculateDocumentState(doc: DocumentConfig) {
    if (!this.unfixed) { // No es una reapertura
      return doc.isFullfiled ? UploadAreaState.COMPLETED : UploadAreaState.READY;
    } else {
      return doc.fix ? UploadAreaState.RETAKE : UploadAreaState.READY;
    }
  }

  private initDocumentFiles() {
    this.documentFiles = this.documentConfigs.map((doc) => ({
      id: doc.id,
      auxId: doc.auxId,
      capture: doc.capture,
      icon: doc.isBGShow ? doc.bgImage : '',
      mode: doc.mode ? doc.mode : 'image',
      image: doc.image,
      name: this.getTitleName(doc.name),
      mandatory: doc.mandatory,
      type: doc.type,
      state: this.calculateDocumentState(doc)
    }))
  }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();

    if (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') {
      DEFAULT_DOC_CONFIG.forEach((config) => {
        config.bgImage = '/assets/images/logo/camera_new.svg';
      });
    }

    this.restoreHelp();

    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.documentPictures = this.remoteService.selfAdjustService.myPage('document-pictures');

    if (this.documentPictures.documentArray) {
      this.documentConfigs = this.documentPictures.documentArray;
      if (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') {
        this.documentConfigs.forEach((item) => {
          item.bgImage = '/assets/images/logo/camera_new.svg';
        });
      }
      this.modifyPhotoPosition(this.photosQuantity);
    } else {
      this.documentConfigs = DEFAULT_DOC_CONFIG;
    }

    this.photosQuantity = this.documentPictures.photosQuantity ? this.documentPictures.photosQuantity : this.documentConfigs.length;
    this.nextAvailable = this.checkMandatoryFullfilled();
    this.numImages = 0;
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.vehicleType = this.actualSelfadjust.vehicleType;
    this.showLegalFooter = this.remoteService.selfAdjustService.actualCompany.showLegalFooter;

    // Comprobamos si es una reapertura pq documentPictures.fixed es false y asignamos array de elementos a arreglar
    if (this.documentPictures.fixed !== undefined && !this.documentPictures.fixed) {
      this.unfixed = true;
      this.unfixedArray = this.documentConfigs.map(item => !item.fix || this.documentPictures.fixed);
    } else {
      this.unfixed = false;
      this.unfixedArray = this.documentConfigs.map(item => true);
    }

    this.updatePage(this.actualSelfadjust);

    if (this.remoteService.selfAdjustService.logo_camera) {
      for (let i = 0; i < this.documentConfigs.length; i++) {
        this.documentConfigs[i].bgImage = this.remoteService.selfAdjustService.logo_camera;
      }
    }

    this.initDocumentFiles();
  }

  ngAfterViewInit() {
    if (this.documentPictures.documentArray) {
      this.changePhotoUpload();
    }

    this.loaded = true;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      if (customStylesFolder === 'Banorte'
        || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-document-pictures')[0]['style'].position = 'fixed';
      }

      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
        console.log(error)
      }
    }
  }

  getTitleName(name) {
    return this.remoteService.selfAdjustLanguageService.strLang(name) ? this.remoteService.selfAdjustLanguageService.strLang(name) : name;
  }

  checkMandatoryFullfilled() {
    let mandatoryFullfilled = true;

    for (const img of this.documentConfigs) {
      if (img.mandatory && !img.isFullfiled) {
          mandatoryFullfilled = false;
          break;
      }
    }

    return this.unfixedArray ? !this.unfixedArray.includes(false) && mandatoryFullfilled : mandatoryFullfilled;
  }

  changePhotoUpload() {
    this.documentConfigs.forEach(e => {
      this.remoteService.nativeCameraService.setCameraCapture(document.querySelector(`input[id=${e.id}]`), e.capture, e.mode === 'document');
    });
  }

  modifyPhotoPosition(photosNumber) {
    const resizePosition = document.getElementsByClassName('fitImg')[0];

    if (photosNumber === 1) {
      resizePosition['style']['justify-content'] = 'center';
      resizePosition['style']['align-content'] = 'center';
    } else if (photosNumber === 2) {
      resizePosition['style']['justify-content'] = 'space-between';
    }
  }

  async imageUpload(file: HTMLInputElement, imageType: string, index: number) {
    this.restoreHelp();
    this.loading = true;
    const self = this;
    const docFile = this.documentFiles[index];
    const docConfig = this.documentConfigs[index];
    const oldState = docFile.state;
    docFile.state = UploadAreaState.LOADING;
    this.cd.detectChanges();

    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();

    if (docFile.mode === 'image') {
      this.remoteService.selfAdjustService.resizeAndDraw(file, 1280).then(result => {
      const contentType = result.slice(result.indexOf('image/'), result.indexOf(';'));
      const extension = contentType.split('image/')[1];
      const caption = this.documentConfigs[index].name;

      const newImg = {
        name: result,
        type: imageType,
        caption
      };

      this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust.id, extension).pipe(take(1)).subscribe(media => {
        this.remoteService.selfAdjustService.bucketImage(result, media['media_url'], contentType).pipe(take(1)).subscribe(response => {
          const logInfo = {
            component: 'document-pictures',
            action: 'push-image'
          };
          const detectDamagesOnlyIn = this.documentConfigs[index].detectDamagesOnlyIn;
          this.remoteService.selfAdjustService.addImage(
            this.actualSelfadjust.id, this.actualSelfadjust['securityKey'], media['media_id'],
            imageType, extension, logInfo, coordinates, caption, null, null, null, detectDamagesOnlyIn).subscribe(e => {
              this.remoteService.selfAdjustService.updateLocalImages(newImg);
              self.documentConfigs[index].image = result;
              self.documentConfigs[index].isBGShow = false;
              self.documentConfigs[index].isFullfiled = true;
              self.numImages = self.numImages + 1;
              this.documentConfigs[index].loading = false;
              if (this.documentPictures.fixed !== undefined && !this.documentPictures.fixed) {             
                this.isFixed(index);
              }
              file.value = '';
              docFile.image = result;
              docFile.state = UploadAreaState.COMPLETED;
              docConfig.isFullfiled = true;
              self.nextAvailable = this.checkMandatoryFullfilled();
              this.cd.detectChanges();
            },
          err => {
            console.log('Error in addImage', err);
            this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang('Error in image upload. Please, try again.');
            this.showNotification(index);
          });
        },
        err => {
          console.log('Error in bucketImage', err);
          this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang('Error in image upload. Please, try again.');
          this.showNotification(index);
        });
      },
      err => {
        console.log('Error in getMediaUrl', err);
        this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang('Error in image upload. Please, try again.');
        this.showNotification(index);
      });
      });
  } else {
    try {
      const preexistingDocument = (this.remoteService.selfAdjustService.actualSelfAdjust.documents || []).find(doc => doc.type === docConfig.id);
      if (preexistingDocument) {
        await this.remoteService.selfAdjustService.deleteFile(this.actualSelfadjust.id, preexistingDocument.uuid).toPromise();
        this.remoteService.selfAdjustService.actualSelfAdjust.documents = this.remoteService.selfAdjustService.actualSelfAdjust.documents.filter((d) => d.uuid !== preexistingDocument.uuid);
      }

      await this.remoteService.filesService.uploadFile(file.files[0], docConfig.id);
      const base64 = await this.remoteService.filesService.encodeFileToBase64URL(file.files[0]);
      docFile.image = base64;
      docConfig.image = base64;
      if (this.documentPictures.fixed !== undefined && !this.documentPictures.fixed) {             
        this.isFixed(index);
      }
      docFile.state = UploadAreaState.COMPLETED;
      docConfig.isFullfiled = true;
      this.nextAvailable = this.checkMandatoryFullfilled();
      this.cd.detectChanges();
    } catch (err) {
      if (err.message === 'File size exceeded') {
        this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang('document_files_max_file_size_exceeded');
      } else if (err.message === 'File format not allowed') {
        this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang('document_invalid_format');
      } else {
        this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang('Error in image upload. Please, try again.');
      }

      docFile.state = oldState;
      file.value = '';
      this.showNotification(index);
      return;
    }
  }

  this.loading = false;
  }

  /**
   * Devuelve a su valor inicial las ayudas de la toma de fotos
   */
  restoreHelp() {
    this.helpPage = undefined;
    this.showHelp = false;
    this.showHelpOdometer = false;
    this.showHelpInterior = false;
    this.showHelpDisk = false;
    this.showHelpTires = false;
    this.showHelpWindscreen = false;
  }

  isFixed(index) {
    this.unfixedArray[index] = true;

    if (!this.unfixedArray.includes(false)) {
      this.unfixed = false;
      this.remoteService.selfAdjustService.fixedPage('document-pictures');
    }
  }
  
  onClick(event: MouseEvent, carimage: DocumentFile) {
    if (carimage.state === UploadAreaState.READY || carimage.state === UploadAreaState.RETAKE) {
      const docConfig = this.documentConfigs.find(doc => doc.id === carimage.id);
      this.targetName = carimage.id;
      this.helpPage = docConfig.helpPage;

      if(this.helpPage) {
        return;
      }

      if (carimage.id.includes('vin') && this.documentPictures.vinHelp) {
        this.showHelp = true;
      } else if (carimage.id === 'milleage' || carimage.id === 'odometer') {
        this.showHelpOdometer = true;
      } else if (carimage.id === 'interior' && this.documentPictures.interiorHelp) {
        this.showHelpInterior = true;
      } else if (carimage.id === 'license_disc' && this.documentPictures.diskHelp) {
        this.showHelpDisk = true;
      } else if (carimage.id === 'tires' && this.documentPictures.tiresHelp) {
        this.showHelpTires = true;
      } else if (carimage.id === 'windscreen') {
        this.showHelpWindscreen = true;
      } else {
        this.openCamera();
      }
    } else if (carimage.state === UploadAreaState.COMPLETED) {
      this.showGallery(this.documentConfigs.findIndex(e => e.id === carimage.id));
    }
  }

  checkForHelp(carimage){
    if(carimage.helpPage) {
      return true;
    }

    return carimage.id === 'milleage' || carimage.id === 'odometer'
    || this.documentPictures.interiorHelp && carimage.id === 'interior'
    || this.documentPictures.diskHelp && carimage.id === 'license_disc'
    || this.documentPictures.vinHelp && carimage.id.includes('vin')
    || this.documentPictures.tiresHelp && carimage.id === 'tires'
    || carimage.id === 'windscreen';
  }

  closeHelp() {
    this.helpPage = undefined;
    this.showHelp = false;
  }

  closeHelpOdometer() {
    this.showHelpOdometer = false;
  }

  closeHelpInterior() {
    this.showHelpInterior = false;
  }

  closeHelpDisk() {
    this.showHelpDisk = false;
  }

  closeHelpTires() {
    this.showHelpTires = false;
  }

  closeHelpWindscreen() {
    this.showHelpWindscreen = false;
  }

  openCamera() {
    try {
      (<HTMLInputElement>document.querySelector(`input[id=${this.targetName}]`)).click();
    } catch(e) {
      console.log('Error: ', e);
    }
  }

  showNotification(index) {
    this.openNotification = true;

    setTimeout(() => {
      this.openNotification = false;
      if (typeof index === 'number') {
        this.documentConfigs[index].loading = false;
        this.documentFiles[index].state = this.documentConfigs[index].fix ? UploadAreaState.RETAKE : UploadAreaState.READY;
      }
      this.loading = false;
      this.cd.detectChanges();

    }, 5000);

    this.cd.detectChanges();
  }

  updatePage(actualSelfadjust) {
    this.fillPictureDatas();

    console.log("actualSelfadjust",actualSelfadjust)

    if (actualSelfadjust.images) {
      actualSelfadjust.images.map(async (e) => {
        this.documentConfigs.forEach((element) => {
          if (element.type === e.type && element.name === e.caption) {
            element.image = e.name;
            element.isBGShow = false;
            element.isFullfiled = true;
            this.numImages++;
          }
          if (element.fix && !this.remoteService.selfAdjustService.isPageFixed('document-pictures')) {
            delete element.image;
            element.isBGShow = true;
          }
        });
      });

      (actualSelfadjust.documents || []).forEach((doc) => {
        const element = this.documentConfigs.find((c) => c.mode === 'document' && c.id === doc.type);
        if (element) {
          if (element.fix && !this.remoteService.selfAdjustService.isPageFixed('document-pictures')) {
            delete element.image;
            element.isBGShow = true;
          } else {
            element.image = doc.name;
            element.isFullfiled = true;
            this.numImages++;
          }
        }
      })
    }

    this.nextAvailable = this.checkMandatoryFullfilled();
  }

  fillPictureDatas() {
    let aux = this.documentPictures.photosQuantity - this.documentConfigs.length;
    const pathDefaultCameraImg = (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') ? '/assets/images/logo/camera_new.svg' : '/assets/images/logo/camera.svg';
    const cameraLogoImg = this.remoteService.selfAdjustService.logo_camera ? this.remoteService.selfAdjustService.logo_camera : pathDefaultCameraImg;

    while (aux > 0) {
      let num = this.documentConfigs.length + 1;
      this.documentConfigs.push({
        id: `image${num}`,
        name: 'Document',
        type: `document_${num}`,
        capture: 'camera',
        image: null,
        bgImage: cameraLogoImg,
        isBGShow: true,
        loading: false,
        auxId: 'aux_document',
        mandatory: false
      });
      aux--;
    }
  }

  private showGallery(index: number): void {
    const factory = this.resolver.resolveComponentFactory(GalleryComponent);
    this.galleryRef = this.container.createComponent(factory);

    const isImage = (d: DocumentConfig) => {
      if (d.image) {
        if (d.image.startsWith('data:application/pdf')) {
          return false;
        }
        const url = new URL(d.image);
        return url.pathname.split('.').pop().toLowerCase() !== 'pdf';
      }
      return false;
    }

    this.galleryRef.instance.images = this.documentConfigs.filter(d => d.isFullfiled).map((d, index) => ({
      fix: d.fix,
      fixed: this.unfixedArray[index],
      name: isImage(d) ? d.image : '/assets/icons/document.svg',
      number: index + 1,
      isDocument: d.mode === 'document',
      captureMode: d.capture || 'camera',
      warning: false
    }));
    this.galleryRef.instance.index = this.documentConfigs.filter(d => d.isFullfiled).indexOf(this.documentConfigs[index]);
    this.galleryRef.instance.reopened = this.unfixedArray.some(u => !u);

    this.galleryRef.instance.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.galleryRef.destroy();
      });

    this.galleryRef.instance.onRepeat
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: { file: HTMLInputElement, index: number }) => {
        const { file, index } = data;
        const realIndex = this.documentConfigs.indexOf(this.documentConfigs.filter(d => d.isFullfiled)[index]);
        this.imageUpload(file, this.documentConfigs[realIndex].type, realIndex);
        this.galleryRef.destroy();
      });
  }

  movePage(action) {
    this.loading = true;

    if (action === 'next') {
      const data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'document-pictures',
          action: 'continue'
        },
        fields2Update: {}
      };

      if (this.remoteService.selfAdjustService.getReopenInfo() && this.documentPictures.fixed !== undefined) {
        data.fields2Update['reopenInfo'] = { pages: null };
        data.fields2Update['reopenInfo'].pages = this.remoteService.selfAdjustService.getReopenInfo();
      }

      this.remoteService.selfAdjustService.pushData(data).subscribe((res) => {
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        console.log('this.remoteService.selfAdjustService.nextPage', this.remoteService.selfAdjustService.nextPage)
        this.router.navigate(
          [...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]
        );
      },

      err=>{
        console.log("Error in selfadjust-update",err);
        let index = undefined;
        this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang('Error in image upload. Please, try again.');
        this.showNotification(index);
      });
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }
}
