import * as tf from '@tensorflow/tfjs'

const moto_4_parts_labels = ['front', 'left', 'other', 'rear', 'right'];
var modelMoto;


export async function loadMotoPartModel () {
  return new Promise((resolveOrig, rejectOrig) =>  {
    let modelMotoUrl = `../assets/models/moto_4_parts/model.json`;
    let modelMotoPromise = new Promise((resolve,reject) => {
      resolve(tf.loadLayersModel(modelMotoUrl))
    });
    Promise.all([modelMotoPromise])
      .then(detectionModels => {
        try {
          tf.tidy(() => {
            detectionModels[0].predict(tf.zeros([1, 224, 224, 3]));
          })
          modelMoto = detectionModels[0];
          resolveOrig(false);
        } catch (err) {
          console.log("Error inicializing models",err)
          rejectOrig(err);
        }
      })
      .catch(err => {
        console.log("Error loading models", err);
        rejectOrig(err);
      })
  })
}

export async function predictMotoPart(img) {
  return tf.tidy(() => { 
    const image = tf.browser.fromPixels(img).toFloat();
    const resized = tf.image.resizeBilinear(image, [224, 224]);
    const offset = tf.scalar(255 / 2);
    const normalized = resized.sub(offset).div(offset);
    const input =  normalized.expandDims(0);
    let pred = Array.from(modelMoto.predict(input).dataSync());
    let predictions = moto_4_parts_labels
      .map((label, index) => ({ label, accuracy: pred[index] }))
      .sort((a, b) => b.accuracy - a.accuracy)
    input.dispose();
    return predictions;
  });
}

