export const PICTURE_VEHICLE_DATAS = {
    car: [
        // CONFIGURACIÓN POR DEFECTO
        // {
        //     id: 'front',
        //     name: 'Front',
        //     name_es: 'Parte delantera',
        //     type: 'front',
        //     image: null,
        //     object2detect: 'license',
        //     bgImage: '../../assets/images/logo/camera.svg',
        //     isBGShow: true,
        //     newImg: 'newFront',
        //     uploadOK: true,
        //     enableAI: true,
        //     blockFitting: true,
        // },
        // {
        //     id: 'rear',
        //     name: 'Rear',
        //     name_es: 'Parte trasera',
        //     type: 'rear',
        //     image: null,
        //     object2detect: 'license',
        //     bgImage: '../../assets/images/logo/camera.svg',
        //     isBGShow: true,
        //     newImg: 'newRear',
        //     uploadOK: true,
        //     enableAI: true,
        //     blockFitting: true,
        // },
        // {
        //     id: 'rear-left',
        //     name: 'Left',
        //     name_es: 'Lat. izquierdo',
        //     type: 'rearLeft',
        //     image: null,
        //     object2detect: 'wheel',
        //     bgImage: '../../assets/images/logo/camera.svg',
        //     isBGShow: true,
        //     newImg: 'newLeft',
        //     uploadOK: true,
        //     enableAI: true,
        //     blockFitting: true,
        // },
        // {
        //     id: 'front-left',
        //     name: 'Left',
        //     name_es: 'Lat. izquierdo',
        //     type: 'frontLeft',
        //     image: null,
        //     object2detect: 'wheel',
        //     bgImage: '../../assets/images/logo/camera.svg',
        //     isBGShow: true,
        //     newImg: 'newFrontLeft',
        //     uploadOK: true,
        //     enableAI: true,
        //     blockFitting: true,
        // },
        // {
        //     id: 'rear-right',
        //     name: 'Right',
        //     name_es: 'Lat. derecho',
        //     type: 'rearRight',
        //     image: null,
        //     object2detect: 'wheel',
        //     bgImage: '../../assets/images/logo/camera.svg',
        //     isBGShow: true,
        //     newImg: 'newRight',
        //     uploadOK: true,
        //     enableAI: true,
        //     blockFitting: true,
        // },
        // {
        //     id: 'front-right',
        //     name: 'Right',
        //     name_es: 'Lat. derecho',
        //     type: 'frontRight',
        //     image: null,
        //     object2detect: 'wheel',
        //     bgImage: '../../assets/images/logo/camera.svg',
        //     isBGShow: true,
        //     newImg: 'newFrontRight',
        //     uploadOK: true,
        //     enableAI: true,
        //     blockFitting: true,
        // },

        // CONFIGURACIÓN CHUBB
        {
            bgimage: '../../assets/images/logo/camera.svg',
            enableAI: false,
            id: 'front',
            image: null,
            isBGShow: true,
            name: 'Front',
            name_es: 'Parte delantera',
            newImg: 'newFront',
            object2detect: 'license',
            type: 'front',
            uploadOK: true
        },
        {
            bgimage: '../../assets/images/logo/camera.svg',
            enableAI: false,
            id: 'rear',
            image: null,
            isBGShow: true,
            name: 'Rear',
            name_es: 'Parte trasera',
            newImg: 'newRear',
            object2detect: 'license',
            type: 'rear',
            uploadOK: true
        },
        {
            bgimage: '../../assets/images/logo/camera.svg',
            enableAI: true,
            id: 'rear-left',
            image: null,
            isBGShow: true,
            name: 'Left',
            name_es: 'Lat. izquierdo',
            newImg: 'newLeft',
            object2detect: 'wheel',
            type: 'rearLeft',
            uploadOK: true
        },
        {
            bgimage: '../../assets/images/logo/camera.svg',
            enableAI: true,
            id: 'front-left',
            image: null,
            isBGShow: true,
            name: 'Left',
            name_es: 'Lat. izquierdo',
            newImg: 'newFrontLeft',
            object2detect: 'wheel',
            type: 'frontLeft',
            uploadOK: true
        },
        {
            bgimage: '../../assets/images/logo/camera.svg',
            enableAI: true,
            id: 'rear-right',
            image: null,
            isBGShow: true,
            name: 'Right',
            name_es: 'Lat. derecho',
            newImg: 'newRight',
            object2detect: 'wheel',
            type: 'rearRight',
            uploadOK: true
        },
        {
            bgimage: '../../assets/images/logo/camera.svg',
            enableAI: true,
            id: 'front-right',
            image: null,
            isBGShow: true,
            name: 'Right',
            name_es: 'Lat. derecho',
            newImg: 'newFrontRight',
            object2detect: 'wheel',
            type: 'frontRight',
            uploadOK: true
        },
    ],
    moto: [
        {
            id: 'front',
            name: 'Front',
            name_es: 'Parte delantera',
            type: 'front',
            image: null,
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newFront',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'rear',
            name: 'Rear',
            name_es: 'Parte trasera',
            type: 'rear',
            image: null,
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newRear',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'left',
            name: 'Left',
            name_es: 'Lat. izquierdo',
            type: 'left',
            image: null,
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newLeft',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'right',
            name: 'Right',
            name_es: 'Lat. derecho',
            type: 'right',
            image: null,
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newRight',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
    ],
    truck: [
        {
            id: 'front',
            name: 'Front',
            name_es: 'Parte delantera',
            type: 'front',
            image: null,
            object2detect: 'license',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newFront',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'rear',
            name: 'Rear',
            name_es: 'Parte trasera',
            type: 'rear',
            image: null,
            object2detect: 'license',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newRear',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'rear-left',
            name: 'Left',
            name_es: 'Lat. izquierdo',
            type: 'rearLeft',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newLeft',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'front-left',
            name: 'Left',
            name_es: 'Lat. izquierdo',
            type: 'frontLeft',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newFrontLeft',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'rear-right',
            name: 'Right',
            name_es: 'Lat. derecho',
            type: 'rearRight',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newRight',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'front-right',
            name: 'Right',
            name_es: 'Lat. derecho',
            type: 'frontRight',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newFrontRight',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
    ],
    pickup: [
        {
            id: 'front',
            name: 'Front',
            name_es: 'Parte delantera',
            type: 'front',
            image: null,
            object2detect: 'license',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newFront',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'rear',
            name: 'Rear',
            name_es: 'Parte trasera',
            type: 'rear',
            image: null,
            object2detect: 'license',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newRear',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'rear-left',
            name: 'Left',
            name_es: 'Lat. izquierdo',
            type: 'rearLeft',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newLeft',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'front-left',
            name: 'Left',
            name_es: 'Lat. izquierdo',
            type: 'frontLeft',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newFrontLeft',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'rear-right',
            name: 'Right',
            name_es: 'Lat. derecho',
            type: 'rearRight',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newRight',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
        {
            id: 'front-right',
            name: 'Right',
            name_es: 'Lat. derecho',
            type: 'frontRight',
            image: null,
            object2detect: 'wheel',
            bgImage: '../../assets/images/logo/camera.svg',
            isBGShow: true,
            newImg: 'newFrontRight',
            uploadOK: true,
            enableAI: null,
            blockFitting: null,
        },
    ]
};
