import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RemoteLibraryService } from 'remote-library';

@Injectable({
  providedIn: 'root'
})
export class FullScreenGuard implements CanActivate {

  constructor(private remoteService: RemoteLibraryService){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Promise((resolve,reject) => {
        this.remoteService.selfAdjustService.toggleFullscreen(true).then(res => {
          resolve(true)
        }).catch(err => {
          console.log('Err Fullscreening:', err)
          resolve(true)
        });

      })
  }
}
