import { Component, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';

@Component({
  selector: 'app-selfadjust-error-layout',
  templateUrl: './selfadjust-error-layout.component.html',
  styleUrls: ['./selfadjust-error-layout.component.scss'],
})
export class SelfadjustErrorLayoutComponent implements OnInit {
  public logos: string[];

  constructor(remoteLibraryService: RemoteLibraryService) {
    const { selfAdjustService: { customStylesFolder, logo: { landing }, logo_selfadjust, logo_final } } = remoteLibraryService as any;

    const landingLogo = landing();
    let logo: string[] = [ '../../assets/images/logo/bdeoLogoNew.svg' ];

    if (customStylesFolder !== 'bdeo' && !landingLogo && !logo_selfadjust) {
      logo = [ '../../assets/images/logo/Union.svg', '../../assets/images/logo/Bdeo.svg' ];
    }

    if (landingLogo || logo_selfadjust) {
      logo = [ landingLogo || logo_final || logo_selfadjust ];
    }

    this.logos = logo;

    try {
      console.log(`./customStyles/${remoteLibraryService.selfAdjustService.actualCompany.customStylesFolder}/customStyle.scss`);
      require(`style-loader!./customStyles/${remoteLibraryService.selfAdjustService.actualCompany.customStylesFolder}/customStyle.scss`);
    } catch(e) { }
  }

  ngOnInit() {}
}
