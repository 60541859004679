import { Component, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { take } from 'rxjs/operators';
import { Zone } from './environmental-capture-zone/environmental-capture-zone.component';

@Component({
  selector: 'app-environmental-capture-wrapper',
  templateUrl: './environmental-capture-wrapper.component.html',
  styleUrls: [
    './environmental-capture-wrapper.component.scss',
  ],
})
export class EnvironmentalCaptureWrapperComponent implements OnInit {

  environmentalCapture;
  zone: Zone;
  zones: Zone[] = [];

  constructor(public remoteService: RemoteLibraryService) {}

  getZoneCompletedSteps(zone) {
    const selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    const completedSteps = selfadjust.images
      .filter(image => image.type == 'damage' && image.vehiclePart == zone)
      .map(image => image.step);
    
    let count = 0;
    if(completedSteps.includes(1)) {
      count++
    }

    if(completedSteps.includes(2)) {
      count++
    }

    if(completedSteps.includes(3)) {
      count++
    }

    return count;
  }

  generateSteps() {
    return [
      {
        title: 'Photograph of the damaged part',
        description: 'Shows the damage and the area in which it is located',
        collapsed: true,
        completed: false,
        photos: [],
      },{
        title: 'First photo up close (front)',
        description: 'Get closer and take the photo very close',
        collapsed: true,
        disabled: true,
        completed: false,
        photos: [],
      },{
        title: 'Photograph of the damage very close 2',
        description: 'Take the same photo up close but from another angle',
        collapsed: true,
        disabled: true,
        completed: false,
        photos: [],
      },
    ];
  }

  generateTitle(part) {
    const partCapitalized = part.charAt(0).toUpperCase() + part.slice(1);
    return `${partCapitalized} zone`;
  }

  async ngOnInit() {
    this.environmentalCapture = this.remoteService.selfAdjustService.myPage('environmental-capture');
    const selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    
    this.remoteService.selfAdjustService.orderedVehicleZones.filter(zone => selfadjust.carpart[zone]).forEach(zone => {
      this.zones.push({
        name: zone,
        title: this.generateTitle(zone),
        completedSteps: this.getZoneCompletedSteps(zone),
        steps: this.generateSteps()
      })
    });

    this.remoteService.selfAdjustService.actualSelfAdjust = 
      (await this.remoteService.selfAdjustService.loadData(this.remoteService.selfAdjustService.secretKey).pipe(take(1)).toPromise() as any).entity;
  };
};
