import { Component, OnInit, Input, ViewChild,ChangeDetectorRef, NgZone } from "@angular/core";
import html2canvas from 'html2canvas';
import { RemoteLibraryService } from "remote-library";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";


@Component({
  selector: "app-moto-parts",
  templateUrl: "./moto-parts.component.html",
  styleUrls: ["./moto-parts.component.scss"],
})
export class MotoPartsComponent implements OnInit {
  inputsArr:Array<any>;
  loading: Boolean = false;
  checkedArr:Array<any> = [];
  availableMoto:Boolean = false;
  selfAdjustInfo: any;
  motoParts: any;
  public partVals = {
    frontwheel: false,
    windscreen: false,
    controlpanel: false,
    seat: false,
    rearwheel: false,
    handlebarleft: false,
    leftside: false,
    leftpipe: false,
    handlebarright: false,
    rightside: false,
    rightpipe: false
  };
  @ViewChild("motoPartsContainer")
  public motoPartsContainer: any;

  @Input()
  public motoPartsImage: string;
  @ViewChild('myForm')
  public myForm: NgForm;
  public secretKey;
  public msg;
  public msgUploading;
  openNotification: Boolean = false;
  unfixed: boolean;

  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public zone: NgZone,
    public cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.remoteService.selfAdjustService.changeLabels();
    this.remoteService.selfAdjustLanguageService.browserLanguage();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.motoParts = this.remoteService.selfAdjustService.myPage('moto-parts');
    this.selfAdjustInfo = this.remoteService.selfAdjustService.actualSelfAdjust;
    if(this.motoParts.reopen){
      this.unfixed = true;
    }
    this.availableMoto = !this.motoParts.mandatory;
    if (this.selfAdjustInfo.status == this.remoteService.selfAdjustService.AppStatus.final) {
      console.log('INFO: Navega a: final con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate(['final', {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
    this.updatePage(this.selfAdjustInfo);
  }
 
  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  checked(aux) {
    if(this.motoParts.reopen){
      this.unfixed = false;
    }
    this.partVals[aux] = !this.partVals[aux];
    if (this.partVals[aux]==true) {
      this.checkedArr.push('1');
    } else {
      this.checkedArr.pop();
    }
    if (this.motoParts.mandatory) {
      if (this.checkedArr.length >= 1) {
        this.availableMoto = true;
      } else {
        this.availableMoto = false;
      }
    }
  }

  async submitmotoMap(action) {
    this.loading = true;
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    html2canvas(this.motoPartsContainer.nativeElement, {} as any).then(canvas => {
      this.motoPartsImage = canvas.toDataURL('image/jpeg', 0.7);
      let contentType = this.motoPartsImage.slice(this.motoPartsImage.indexOf('image/'),this.motoPartsImage.indexOf(';'));
      const caption = 'Damaged parts';
      this.remoteService.selfAdjustService.getMediaUrl(this.selfAdjustInfo['id'], 'jpeg').subscribe(res => {
        this.remoteService.selfAdjustService.bucketImage(this.motoPartsImage, res['media_url'], contentType).subscribe((result: any) => {
          this.remoteService.selfAdjustService.addImage( this.selfAdjustInfo['id'], this.selfAdjustInfo['securityKey'],
            res['media_id'], "moto", 'jpeg', undefined, coordinates, caption).subscribe(e => {
              this.movePage(action);
            },
            err => {
              console.log("Error in addImage",err);
              this.showNotification();
            });       
        },
        err=>{
            console.log("Error in bucketImage",err);
            this.showNotification();
        })
      },
      err=>{
          console.log("Error in getMediaUrl",err);
          this.showNotification();
      })
    });
  }

  showNotification() {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      this.cd.detectChanges();
    },5000)
    this.loading = false;
    this.cd.detectChanges();
  }

  updatePage(data) {
    console.log("data",data)
    for (let part in data.motopart) {
      if (data.motopart[part]) {
       this.partVals[part] = data.motopart[part];
       this.checkedArr.push('1');
       this.availableMoto = true;
      }
    }
    if(this.myForm){
      this.myForm['_directives'].forEach(e => {
        if (data[e.name]) {
          e._updateValue(data[e.name]);
        }
      });
    }
  }

  movePage(action) {
    this.loading = true;
    if(action=='next'){
      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.motoParts.reopen ? this.remoteService.selfAdjustService.AppStatus.reopened :  this.remoteService.selfAdjustService.AppStatus.inprocess,
        fields2Update: {
          motopart: this.partVals,
        },
        logInfo: {
          component: 'moto-parts',
          action: 'continue'
        }
      };
      this.remoteService.selfAdjustService.actualSelfAdjust.motopart = this.partVals;
      if(this.motoParts.selfadjustFields){
        this.myForm["_directives"].forEach(e=>{
          data.fields2Update[e.name] = e.value
        })
      }
      this.remoteService.selfAdjustService.pushData(data).subscribe(res=>{
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
      },
      err=>{
          console.log("Error in selfadjust-update",err);
          this.showNotification();
      });   
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }
}