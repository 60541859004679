import { Routes } from "@angular/router";
import { LandingPageComponent } from './landing-page/landing-page.component';
import { VehicleTypeComponent } from './vehicle-type/vehicle-type.component';
import { SignaturePageComponent } from './signature-page/signature-page.component';
import { FinalPageComponent } from './final-page/final-page.component';
import { RepairCentersPageComponent } from './repaircenters-page/repaircenters-page.component';
import { InformationFormComponent } from "./information-form/information-form.component";
import { CarPartsComponent } from './car-parts/car-parts.component';
import { MotoPartsComponent } from './moto-parts/moto-parts.component';
import { DamagePicturesComponent } from "./damage-pictures/damage-pictures.component";
import VehicleSidePicturesComponent from "./vehicle-side-pictures/vehicle-side-pictures.component";
import { SidePicturesComponent } from "./side-pictures/side-pictures.component";
import { MotoSidePicturesComponent } from "./moto-side-pictures/moto-side-pictures.component";
import { DocumentPicturesComponent } from "./document-pictures/document-pictures.component";
import { FeedbackPageComponent } from "./feedback-page/feedback-page.component";
import { SidePicturesNoiaComponent } from "./side-pictures-noia/side-pictures-noia.component";
import { MotoSidePicturesNoiaComponent } from "./moto-side-pictures-noia/moto-side-pictures-noia.component";
import { UseConditionsComponent } from "./use-conditions/use-conditions.component";
import { CancelledViewComponent } from "./cancelled-view/cancelled-view.component";
import { DamageQuestionComponent } from "./damage-question/damage-question.component";
import { FullScreenGuard } from "../guards/full-screen.guard";
import { CoherenceGuard } from "../guards/coherence.guard";
import { WarningBrowserComponent } from "./warning-browser/warning-browser.component";
import { WarningDeviceComponent } from './warning-device/warning-device.component';
import { StatusGuard } from "../guards/status.guard";
import { SupportBrowsersGuard } from "../guards/support-browsers.guard";
import { SupportDevicesGuard } from '../guards/support-devices.guard';
import { PresenceInsuredComponent } from "./presence-insured/presence-insured.component";
import { VehiclePartsComponent } from "./vehicle-parts/vehicle-parts.component";
import { ClaimDamagesComponent } from './claim-damages/claim-damages.component';
import { EnvironmentalCaptureWrapperComponent } from './environmental-capture/environmental-capture-wrapper.component';
import { ScreenshotGuard } from "../guards/screenshot.guard";
import { DocumentFiles } from './document-files/document-files.component';
import { InformationPageComponent } from "./information-page/information-page.component";
// Errors
import { SelfadjustErrorLayoutComponent } from "./layout/selfadjust-error-layout/selfadjust-error-layout.component";
import { PermissionCameraErrorComponent } from "./error/permission-camera-error/permission-camera-error.component";
import { PermissionLocationErrorComponent } from "./error/permission-location-error/permission-location-error.component";
import { InUseCameraErrorComponent } from "./error/in-use-camera-error/in-use-camera-error.component";
import { NoCameraErrorComponent } from "./error/no-camera-error/no-camera-error.component";
import { GenericCameraErrorComponent } from "./error/generic-camera-error/generic-camera-error.component";
import { SamsungPermissionCameraErrorComponent } from "./error/samsung-permission-camera-error/samsung-permission-camera-error.component";
import { NotFoundComponent } from "./error/not-found/not-found.component";
import { BlockedGuard } from "../guards/blocked.guard";
import { WarningBlockedComponent } from "./shared/warning-blocked/warning-blocked.component";
import { TruckSidePicturesComponent } from "./truck-side-pictures/truck-side-pictures.component";

export const routes: Routes = [
  {
    path: 'blocked',
    component: WarningBlockedComponent,
  },
  {
    path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
    data: { preload: true, delay: false },
    canActivate: [ BlockedGuard, StatusGuard, SupportBrowsersGuard, SupportDevicesGuard ],
  },
  {
    path: 'landing',
    component: LandingPageComponent,
    canActivate: [BlockedGuard, StatusGuard, SupportBrowsersGuard, SupportDevicesGuard],
    data: { preload: true, delay: false },
  },
  {
    path: 'vehicle-type',
    component: VehicleTypeComponent,
    canActivate: [BlockedGuard, StatusGuard, SupportBrowsersGuard, SupportDevicesGuard],
    canDeactivate: [ ScreenshotGuard ],
    data: { preload: true, delay: false, caption: 'Vehicle type' },
  },
  {
    path: 'final',
    component: FinalPageComponent,
    canActivate: [BlockedGuard, StatusGuard, SupportBrowsersGuard, SupportDevicesGuard],
    data: { preload: true, delay: false },
  },
  {
    path: 'repaircenters',
    component: RepairCentersPageComponent,
    canActivate: [BlockedGuard, StatusGuard, SupportBrowsersGuard, SupportDevicesGuard],
    data: { preload: true, delay: false },
  },
  {
    path: 'use-conditions/:type',
    component: UseConditionsComponent,
    //canActivate: [StatusGuard, SupportBrowsersGuard, SupportDevicesGuard],
    data: { preload: true, delay: false },
  },
  {
    path: 'cancelled',
    component: CancelledViewComponent,
    data: { preload: true, delay: false },
  },

  { path: 'error', component: SelfadjustErrorLayoutComponent, children: [
    { path: 'camera', children: [
      { path: 'permission', component: PermissionCameraErrorComponent },
      { path: 'permission/samsung', component: SamsungPermissionCameraErrorComponent },
      { path: 'in-use', component: InUseCameraErrorComponent },
      { path: 'no-camera', component: NoCameraErrorComponent },
      { path: '', component: GenericCameraErrorComponent },
      { path: '**', redirectTo: '' },
    ]},
    { path: 'location', component: PermissionLocationErrorComponent },
    { path: '**', redirectTo: 'camera' },
  ]},
  {
    path: 'warning-browser',
    component: WarningBrowserComponent,
    data: { preload: true, delay: false },
  },
  {
    path: 'warning-device',
    component: WarningDeviceComponent,
    data: { preload: true, delay: false },
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: ':order',
    canActivate: [BlockedGuard, CoherenceGuard, StatusGuard, SupportBrowsersGuard, SupportDevicesGuard],
    children: [
      { path: '', component: LandingPageComponent },
      { path: 'landing', component: LandingPageComponent},
      { path: 'vehicle-type', component: VehicleTypeComponent, data: { preload: true, delay: false, caption: 'Vehicle type' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'information-form', component: InformationFormComponent, data: { preload: true, delay: false, caption: 'information-form' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'car-parts', component: CarPartsComponent, data: { preload: true, delay: false } },
      { path: 'vehicle-parts', component: VehiclePartsComponent, data: { preload: true, delay: false } },
      { path: 'moto-parts', component: MotoPartsComponent, data: { preload: true, delay: false } },
      { path: 'damage-pictures', component: DamagePicturesComponent, data: { preload: true, delay: false, caption: 'Damages' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'vehicle-side-pictures', component: VehicleSidePicturesComponent, data: { preload: true, delay: false, caption: '360º pictures', name: 'vehicle-side-pictures' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'presence-insured', component: PresenceInsuredComponent, data: { preload: true, delay: false } },
      { path: 'side-pictures-ai', canActivate: [FullScreenGuard], component: SidePicturesComponent, data: { preload: true, delay: false, caption: '360º pictures', name: 'side-pictures-ai' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'side-pictures', canActivate: [FullScreenGuard], component: SidePicturesNoiaComponent, data: { preload: true, delay: false, caption: '360º pictures', name: 'side-pictures' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'moto-side-pictures-ai', canActivate: [FullScreenGuard], component: MotoSidePicturesComponent, data: { preload: true, delay: false, caption: '360º pictures', name: 'moto-side-pictures-ai' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'moto-side-pictures', canActivate: [FullScreenGuard], component: MotoSidePicturesNoiaComponent, data: { preload: true, delay: false, caption: '360º pictures', name: 'moto-side-pictures' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'truck-side-pictures-ai', canActivate: [FullScreenGuard], component: TruckSidePicturesComponent, data: { preload: true, delay: false, caption: '360º pictures', name: 'truck-side-pictures-ai' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'truck-side-pictures', canActivate: [FullScreenGuard], component: TruckSidePicturesComponent, data: { preload: true, delay: false, caption: '360º pictures', name: 'truck-side-pictures-ai' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'document-pictures', component: DocumentPicturesComponent, data: { preload: true, delay: false, caption: 'Documents' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'signature', component: SignaturePageComponent, data: { preload: true, delay: false } },
      { path: 'feedback', component: FeedbackPageComponent, data: { preload: true, delay: false, caption: 'feedback' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'damage-question', component: DamageQuestionComponent, data: { preload: true, delay: false } },
      { path: 'final', component: FinalPageComponent, data: { preload: true, delay: false } },
      { path: 'repaircenters', component: RepairCentersPageComponent, data: { preload: true, delay: false } },
      { path: 'claim-damages', component: ClaimDamagesComponent, data: { preload: true, delay: false, caption: 'Damages', name: 'claim-damages' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'environmental-capture', component: EnvironmentalCaptureWrapperComponent, data: { preload: true, delay: false, caption: 'Damages', name: 'environmental-capture' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'document-files', component: DocumentFiles, data: { preload: true, delay: false, caption: 'Documents', name: 'document-files' }, canDeactivate: [ ScreenshotGuard ] },
      { path: 'information-page', component: InformationPageComponent, data: { preload: true, delay: false, caption: 'information-page' }, canDeactivate: [ ScreenshotGuard ] },
    ],
  },
];
