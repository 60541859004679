import {Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None // Al querer sobrescribir las propiedades de right y bottom del botón no queremos que angular nos encapsule la clase 'zEWidget-launcher'
})
export class AppComponent implements OnInit {
  title = 'selfAdjustBdeo';
  loaded = false;
  packagedVersion;
  observer: MutationObserver;
  iframeElement: Element;
  activatedComponent: string;
  private secretKeySetted;
  constructor(
    private router: Router,
    public remoteService: RemoteLibraryService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
  ) {
    this.secretKeySetted = new Promise(async resolve => {
      try {
        await this.remoteService.selfAdjustService.processURL(window.location.href);
      } catch {
        this.router.navigate(['/not-found'])
      }
      resolve(true);
    });
    
    this.secretKeySetted.then(() => {
      if (this.remoteService.selfAdjustService.packagedVersion) {
        this.packagedVersion = this.remoteService.selfAdjustService.packagedVersion;
        console.log('VERSION: ', this.packagedVersion)
      }
      iconRegistry.addSvgIcon('exclamation-triangle',sanitizer.bypassSecurityTrustResourceUrl('assets/images/exclamation-triangle-outline.svg'));
    });
  }

  private redirectSelfadjust = function () {
    const Statuses = this.remoteService.selfAdjustService.AppStatus;
    const hasSecretKey = Object.keys(this.activatedRoute.snapshot.queryParams).length !== 0 || location.href.includes('secretKey');
    if ([Statuses.start].includes(this.remoteService.selfAdjustService.actualSelfAdjust.status) || !hasSecretKey) {
      console.log('INFO: Navega a: landing con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate(['landing', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
    } else if (this.remoteService.selfAdjustService.actualSelfAdjust.status == Statuses.final) {
      console.log('INFO: Navega a: final con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate(['final', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
    } else if (this.remoteService.selfAdjustService.actualSelfAdjust.status === Statuses.review) {
      if (this.remoteService.selfAdjustService.actualSelfAdjust.repair_centers) {
        console.log('INFO: Navega a: repaircenters con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate(['repaircenters', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
      } else {
        console.log('INFO: Navega a: final con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate(['final', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
      }
    } else if (this.remoteService.selfAdjustService.actualSelfAdjust.status == Statuses.cancelled) {
      console.log('INFO: Navega a: cancelled con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate(['cancelled', { secretKey: this.remoteService.selfAdjustService.secretKey }])
    }
    this.loaded = true;
    this.remoteService.selfAdjustService.removeIndexLoader();
    this.remoteService.selfAdjustService.chooseVehicle(this.remoteService.selfAdjustService.actualSelfAdjust.vehicleType)
  };

  private toLandingWithError = function () {
    this.loaded = true;
    this.remoteService.selfAdjustService.removeIndexLoader();
    this.remoteService.selfAdjustService.actualSelfAdjust = null;
    this.remoteService.selfAdjustService.actualCompany = null;
    console.log('INFO: Navega a: landing con secretKey:', this.remoteService.selfAdjustService.secretKey);
    this.router.navigate(['landing', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
  };

  private loadSelfadjust = function () {
    return new Promise((resolve) => {
      this.remoteService.selfAdjustService.loadData(this.remoteService.selfAdjustService.secretKey).pipe(take(1)).subscribe((result: any) => {
        if (result.errorMessage) {
          console.log('INFO: Error al cargar el autoajuste', result);
          this.loaded = true;
          this.router.navigate(['/not-found']);
        } else if (result.entity.deleted) {
          console.log('INFO: El autoajuste ha sido eliminado');
          this.loaded = true;
          this.router.navigate(['/not-found']);
        } else {
          this.remoteService.selfAdjustService.actualSelfAdjust = result.entity;
        }
        resolve(result.entity);
      }, (err: any) => {
        console.log("loadData catch", err);
        this.toLandingWithError();
        resolve(null);
      });
    });
  };

  private loadSelfadjustCompany = function () {
    return new Promise((resolve) => {
      this.remoteService.selfAdjustService.getCompanyData(this.remoteService.selfAdjustService.actualSelfAdjust.master_company_id).pipe(take(1)).subscribe((company: any) => {
        if (company && company.errorMessage || !company) {
          console.log('INFO: Error al cargar la compañía', company.errorMessage);
          this.toLandingWithError();
        } else {
          this.remoteService.selfAdjustService.actualCompany = company;
          this.companyCssClass = company.companyCode;
          if (company.apiKeyChat) {
            this.remoteService.selfAdjustService.actualCompany.apiKeyChat = company.apiKeyChat;
            this.insertZenDeskWidget();
          }
          this.remoteService.selfAdjustService.setCompanyConfig(this.remoteService.selfAdjustService.actualCompany);
          if (this.remoteService.selfAdjustService.actualSelfAdjust.reopenInfo) {
            this.remoteService.selfAdjustService.setReopenInfo(this.remoteService.selfAdjustService.actualSelfAdjust.reopenInfo);
          }
          this.remoteService.selfAdjustLanguageService.browserLanguage();
        }
        this.redirectSelfadjust();
        resolve(company);
      }, (err: any) => {
        console.log('getCompanyData catch', err);
        this.toLandingWithError();
        resolve(null);
      });
    });
  };

  private recorderInit = function (company) {
    const cookiesPolicy = (company.cookiesPolicy || {}).show;
    this.remoteService.selfAdjustService.recorderInit(cookiesPolicy);
  };

  private loadCustomStyles = (company) => {
    // Loading company.styles.externalCustomStyles from company, see selfadjust-company lambda
    const externalCustomStyles = ((company || {}).styles || {}).externalCustomStyles;
    if (externalCustomStyles && externalCustomStyles.includes('.css')) {
      // Render the custom style url in <head>
      this.remoteService.htmlRender.loadCssStylesheet(this.renderer, externalCustomStyles as string);
    }
  }

  async ngOnInit() {
    await this.secretKeySetted;
    if (this.remoteService.selfAdjustService.actualSelfAdjust) {
      this.redirectSelfadjust();
      return;
    }
    await this.loadSelfadjust();
    const company: any = await this.loadSelfadjustCompany();
    this.recorderInit(company);
    /**
     * Custom Styles loading
     * We may load our customs styles EVEN AFTER all other styles,
     * including those injected in the component using this hack:
     * setTimeout(() => this.loadCustomStyles(company), 0);
     *
     * Maybe we should use RxJS instead... but for now:
     * Using setTimeout() will render the external stylesheet in THE LAST POSITION
     * inside document.head object
     */
    this.loadCustomStyles(company);
  }

  /**
   * @description Insertado de boton de ZenDesk con una mecanica que permite que cambie de lugar, dependiendo del componente activo
   */
  insertZenDeskWidget() {
    if (this.remoteService.selfAdjustService.actualCompany.apiKeyChat) {


      // Los cambios de clases del boton se hacen cada vez que la navegacion cambia el componente
      this.router.events.subscribe((res: any) => {
        if (res && res.url) {
          try {
            //Se quita la clase del componente viejo que ya esta utilizando
            this.iframeElement.classList.toggle(this.activatedComponent, false);

            //Se busca el componente nuevo
            let order = parseInt(res.url.split('/')[1]);
            this.activatedComponent = order ? this.remoteService.selfAdjustService.customNavigation[order] && this.remoteService.selfAdjustService.customNavigation[order].name : 'landing';

            //Se agrega la clase del componente nuevo
            this.iframeElement.classList.toggle(this.activatedComponent, true);
          } catch (e) {
            console.log('Error in ZenDesk widget class changer:', e);
          }
        }
      })

      //Se inserta un elemento script en el DOM para invocar al widget de ZenDesk
      console.log('Activando widget de ZenDesk');
      const zendeskScript = document.createElement('script');
      zendeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + this.remoteService.selfAdjustService.actualCompany.apiKeyChat;
      zendeskScript.id = 'ze-snippet';
      const zendeskWidgetContainer = document.getElementById('zendeskWidgetContainer');
      zendeskWidgetContainer.appendChild(zendeskScript);
      //Este 'observer' se encarga de detectar cambios en el dom para insertar la clase correcta para la posicion del boton de ZenDesk
      let config = { attributes: true, childList: true, characterData: true };
      this.observer = new MutationObserver(mutations => {
        this.iframeElement = document.getElementById('launcher');
        if (this.iframeElement) {
          this.observer.disconnect();
          let order = parseInt(this.router.url.split('/')[1]);
          this.activatedComponent = order ? this.remoteService.selfAdjustService.customNavigation[order] && this.remoteService.selfAdjustService.customNavigation[order].name : 'landing';
          this.iframeElement.classList.add('zEWidget-launcher', 'active', this.activatedComponent)
          const config2 = { attributes: true, childList: false, subtree: false, attributeFilter: ['class'] };
          // Este 'observer' se ocupa de volver a agregar las clases css correctas cuando
          const observer = new MutationObserver((mutations2: any, observer) => {
            for (let mutation of mutations2) {
              if (mutation.attributeName === "class") {
                let classList = mutation.target.className.split(' ');
                order = parseInt(this.router.url.split('/')[1]);
                this.activatedComponent = order ? this.remoteService.selfAdjustService.customNavigation[order] && this.remoteService.selfAdjustService.customNavigation[order].name : 'landing';
                if (classList.indexOf(this.activatedComponent) === -1 && classList.indexOf('active') === -1 && classList.indexOf('zEWidget-launcher') !== -1) {
                  this.iframeElement.classList.toggle(this.activatedComponent, true);
                  this.iframeElement.classList.toggle('active', true);
                }
              }
            }
          });
          observer.observe(this.iframeElement, config2);
        }
      });
      this.observer.observe(document.body, config);
    } else {
      console.log('La compañía no tiene zenDesk configurado');
    }
  }
}
