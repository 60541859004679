import { Component, OnInit } from '@angular/core';
import { ISelfadjustError } from 'projects/remote-library/src/interfaces/selfadjust-error.interface';

@Component({
  selector: 'app-samsung-permission-camera-error',
  templateUrl: './samsung-permission-camera-error.component.html',
  styleUrls: ['./samsung-permission-camera-error.component.scss'],
})
export class SamsungPermissionCameraErrorComponent implements OnInit {
  public error: ISelfadjustError = {
    icon: '../../assets/images/error/shared/camera.svg',
    name: 'Camera permissions denied',
    description: [
      'Sorry, you cannot continue without accepting camera permissions.',
      'Please follow the steps below to fix this:',
    ],
    steps: {
      android: [
        {
          description: "Click on the hamburger menu at the bottom right. If you don't see the navigation bar, try scrolling the screen.",
          image: '../step1_samsung.png',
        },
        {
          description: 'Click on "Settings".',
          image: 'camera/samsung/step2.png',
        },
        {
          description: 'Click on "Websites & Downloads".',
          image: 'camera/samsung/step3.png',
        },
        {
          description: 'Check "Site permissions" and click to give permissions if they are disabled.',
          image: 'camera/samsung/step4.png',
        },
        {
          description: 'Click the "Back" arrow, then click "Manage Web Data". Click "Delete", then select the links in the photo verification process, and finally click "Delete" at the bottom of the screen.',
          image: 'camera/samsung/step5.png',
        },
        {
          description: 'Click "Back" to hide the actions again and start the process again.',
        },
      ],
      ios: [],
    },
    button: 'Continue',
  };

  constructor() {}

  ngOnInit() {}

  public continue(link: string) {
    window.location = link as any;
  }
}
