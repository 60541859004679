import {Component, OnInit, AfterContentInit, AfterContentChecked, ChangeDetectorRef, OnDestroy, AfterViewInit} from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as iaCar from '../../aiHandlers/carHandler.js';
import * as iaMoto from '../../aiHandlers/motoHandler.js';
import { VEHICLE_PARTS } from './constants/vehicleParts';
import { PICTURE_VEHICLE_DATAS } from './constants/pictureVehicleDatas';
import { ImageValidation } from 'projects/remote-library/src/interfaces/selfadjust/ImageValidation.interfaces.js';

declare var window: Window & { screen: any };

@Component({
  selector: 'app-vehicle-side-pictures',
  templateUrl: './vehicle-side-pictures.component.html',
  styleUrls: [
    './vehicle-side-pictures.component.scss',
  ],
})
class VehicleSidePicturesComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy, AfterViewInit {
  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) { }
  // Component Data
  actualSelfadjust: any;
  sidePictures: any;
  pictureDatas: any[];
  oneTime = 0;
  vehicleType: string;

  // Component flags
  isIos: boolean;
  supportWebGL2: boolean;
  companyEnableIA: boolean;
  loading = false;
  loaded = false;
  cameraON: boolean;
  withoutAITemplate: boolean;
  enableCamera = false;
  authPhoto = false;
  blockFitting = true;
  openNotification = false;
  destroyed = false;

  // View Elements
  video: HTMLVideoElement;
  stream2Delete: MediaStream;
  backCameras: any;

  // View Configs
  carPartDetection: boolean;
  carPartBlocker: boolean;
  occlusionBlocker: boolean;
  plateType: string;
  motoPartDetection: boolean;
  motoPartBlocker: boolean;

  // Camera
  aspectRatio = 16 / 9;
  canvasHeight = Math.floor(Math.min(window.innerWidth, window.innerHeight));
  canvasWidth = Math.floor(this.canvasHeight * this.aspectRatio);
  videoWidth: number;
  videoHeight: number;
  vertical: Boolean;
  canvasForHDImage: HTMLCanvasElement;
  photo2Detect: HTMLCanvasElement;  // Elementos canvas para deteccion de la foto
  photo2DetectCtx: CanvasRenderingContext2D;
  photo2preview: HTMLCanvasElement; // Elementos canvas para generacion de preview
  photo2previewCtx: CanvasRenderingContext2D;
  attempts = 0;
  currentImageType: string;
  currentImageIndex: number;
  preview: any;
  previewOrientation: 'landscape' | 'portrait'; // Flag para determinar orientacion en la que se genero el preview
  previewFixedStyles: any; // Estilos determinados para un preview generado (No cambian en caso de cambio de orientacion)
  errorMessage: string;
  isNotPart = false;
  viewPhoto = false;
  private forceImgValidityParts: { [key: string]: boolean } = {};

  // Reopen Configs
  reopened = false;
  unfixed = false;
  unfixedArray: boolean[];

  // Template vehicle
  object = '';
  msg = '';
  infoModalBright = false;
  autoDetection: any;
  firstTime = true;
  msgBright = '';
  intervalTime = 1000;

  shouldHideRotateButton = false;
  imageValidity: ImageValidation;

  private orientation = {
    get: () => {
      let result: 'portrait' | 'landscape';

      try {
        result = window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape';
      } catch(e) {
        // Older versions of iOS trigger event at start and is still portrait when changing to landscape
        result = !window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape';
      }

      return result;
    },
    set: () => {
      this.vertical = this.orientation.get() === 'portrait';
    }
  }

  /**
     * @description Función para carga de estilos personalizados
     * @param customStylesFolder Nombre de la carpeta donde están los estilos personalizados
     */
  private static loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
        console.log('Error loading the dynamic styles');
      }
    }
  }

  private static detectWebGLContext() {
    const canvas = document.createElement('canvas');
    try {
      const gl = canvas.getContext('webgl2');
      if (gl) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error creating webgl2 context', error);
      return false;
    }
  }

  static async predictObjectFitting(result) {
    const prediction = await iaCar.predictObjectFitting(result);
    return prediction;
  }

  static async predictCarPartOcclusion(result) {
    const prediction = await iaCar.predictCarPartOcclusion(result);
    return prediction;
  }

  static async predictMotoPart(image) {
    const prediction = await iaMoto.predictMotoPart(image);
    return prediction;
  }

  ngOnInit() {
    this.destroyed = false;
    this.isIos = navigator.userAgent.indexOf('iPhone') >= 0;
    this.supportWebGL2 = VehicleSidePicturesComponent.detectWebGLContext() || this.isIos;
    this.sidePictures = this.remoteService.selfAdjustService.myPage('vehicle-side-pictures');
    this.companyEnableIA = !!((localStorage.getItem('enableIA') !== undefined
      || this.remoteService.selfAdjustService.actualCompany.enableIA));
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.setVehicleType();
  }
  ngAfterViewInit(): void {
    VehicleSidePicturesComponent.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }
  setVehicleType() {
    this.vehicleType = this.actualSelfadjust.vehicleType || 'car';
    this.pictureDatas = this.sidePictures.pictureDatas || PICTURE_VEHICLE_DATAS[this.vehicleType];
  }

  ngAfterContentInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    VehicleSidePicturesComponent.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);

    // Configuraciones de vista
    if (this.vehicleType === 'car') {
      this.carPartDetection = this.sidePictures.carPartDetection === false
        ? this.sidePictures.carPartDetection : true;
      this.carPartBlocker = this.sidePictures.carPartBlocker === false
        ? this.sidePictures.carPartBlocker : true;
      this.occlusionBlocker = this.sidePictures.occlusionBlocker === true
        ? this.sidePictures.occlusionBlocker : false;
      this.plateType = this.sidePictures.plateType ? this.sidePictures.plateType : 'normal';
    } else if (this.vehicleType === 'moto') {
      this.motoPartDetection = this.sidePictures.motoPartDetection === false
        ? this.sidePictures.motoPartDetection : true;
      this.motoPartBlocker = this.sidePictures.motoPartBlocker === false
        ? this.sidePictures.motoPartBlocker : true;
    }

    this.orientation.set();
    this.orientationChange = this.orientationChange.bind(this);
    try {
      window.screen.orientation.addEventListener('change', this.orientationChange, false);
    } catch(e) {
      window.addEventListener('orientationchange', this.orientationChange, false);
    }

    // La dimensión del canvas afecta muchos elementos.
    // Por eso el videoWidth y el videoHeight afecta solo al element video que sus dimensiones dependen de la orientación
    this.video = document.getElementById('videoElement') as HTMLVideoElement;
    if (this.vertical) {
      this.videoHeight = this.canvasWidth;
      this.videoWidth = this.canvasHeight;
    } else {
      this.videoWidth = this.canvasWidth;
      this.videoHeight = this.canvasHeight;
    }
    this.canvasForHDImage = document.createElement('canvas');
    this.photo2Detect = document.createElement('canvas');
    this.photo2DetectCtx = this.photo2Detect.getContext('2d');
    this.photo2Detect.width = this.canvasWidth;
    this.photo2Detect.height = this.canvasHeight;

    this.photo2preview = document.createElement('canvas');
    this.photo2previewCtx = this.photo2preview.getContext('2d');
    this.loadAI();
  }

  ngAfterContentChecked() {
    if (this.actualSelfadjust && this.oneTime < 1) {
      if (this.actualSelfadjust.reopenInfo && this.sidePictures.sidePictures) {
        this.reopened = true;
        this.unfixedArray = [];
        this.unfixed = !this.remoteService.selfAdjustService.isPageFixed('vehicle-side-pictures');
        for (const data of this.sidePictures.sidePictures) {
          for (const img of this.pictureDatas) {
            if (data.type === img.type) {
              img.fix = data.fix;
              this.unfixedArray.push(!data.fix);
            }
          }
        }
      }
      this.updatePage();
      this.oneTime += 1;
    }
  }

  /**
   * @description Función para pedir permisos de cámara al usuario (Solo llama a autoInitVideo())
   */
   loadAI() {
    this.loadModels().then(async() => {
      const stream = await this.remoteService.nativeCameraService.autoInitVideo(this.destroyed, this.backCameras, this.attempts);
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.backCameras = this.remoteService.selfAdjustService.filterBackCameras(devices);
        this.shouldHideRotateButton = this.remoteService.selfAdjustService.shouldDisableRotationButton || false;
      });
      this.stream2Delete = stream;
      window['stream'] = stream;
      this.video.srcObject = stream;
      this.loaded = true;

      this.remoteService.nativeCameraService.on.stream.subscribe((stream) => {
        this.stream2Delete = stream;
        window['stream'] = stream;
        this.video.srcObject = stream;
      });
    });
  }

  movePage(action) {
    this.loading = true;
    if (action === 'next') {
      const data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'vehicle-side-pictures',
          action: 'continue',
        },
        fields2Update: {},
      };
      this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(() => {
        this.remoteService.selfAdjustService.toggleFullscreen(false);
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage,
          'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage,
          { secretKey: this.remoteService.selfAdjustService.secretKey }]);
      },
      (err) => {
        console.log('Error in selfadjust-update', err);
        this.uploadKO(undefined);
      });
    } else {
      this.remoteService.selfAdjustService.toggleFullscreen(false);
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage,
        'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage,
        { secretKey: this.remoteService.selfAdjustService.secretKey }]);
    }
  }

  /**
   * @description Función que marca la foto como que no se ha subido correctamente y muestra un mensaje de error
   * @param index Posición de la foto en el array de pictureDatas
   */
  uploadKO(index) {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      if (typeof index === 'number') {
        this.pictureDatas[index].uploadOK = false;
      }
      this.loading = false;
      this.cd.detectChanges();
    }, 5000);
    this.cd.detectChanges();
  }

  /**
   * @description Funcion para otorgar estilos CSS correctos a las piezas del overlay
   * @param id Pieza del overlay que se esta pintando
   */
  overlayCss(id) {
    for (const element of this.pictureDatas) {
      // Caso con image
      if (element.id === id && element.image != null) {
        return {
          fill: this.remoteService.selfAdjustService.fillColorOn,
          stroke: this.remoteService.selfAdjustService.strokeColorOn,
          'fill-opacity': '.4',
        };
      }
      if (element.id === id) { // Caso sin image
        return {
          fill: this.remoteService.selfAdjustService.fillColorOFF,
          stroke: this.remoteService.selfAdjustService.strokeColorOFF,
          'fill-opacity': '.121',
        };
      }
    }
    return 'Err: No part found';
  }

  /**
   * @description Función encargada de eliminar referencias externas que causen que el componente
   * no termine su ciclo de vida
   */
  ngOnDestroy() {
    this.destroyed = true;
    if (this.stream2Delete) {
      this.stream2Delete.getTracks().forEach(track => track.stop());
      window['stream'] = undefined;
      this.video.srcObject = undefined;
    }

    try {
      window.screen.orientation.removeEventListener('change', this.orientation.set);
    } catch(e) {
      window.removeEventListener('orientationchange', this.orientation.set);
    }

    delete this.cd;
    console.log('Destroying Vehicle-Side-Pictures Component!');
  }

  async loadModels() {
    let loaded;
    try {
      loaded = await this.remoteService.aiService.ready();
    } catch(e) {
      // Models is not initialize, initialize it
      // Ocurrs when user refresh page and skip vehicle type page.
      this.remoteService.aiService.load({ carAI: iaCar, motoAI: iaMoto }, true);

      try {
        loaded = await this.remoteService.aiService.ready();
      } catch(e) { }
      
    }

    if (!loaded) {
      this.reloadVehicleComponent();
      console.log('Error loading AI models');
    }
  }

  reloadVehicleComponent() {
    console.log('INFO: Navega a:', this.remoteService.selfAdjustService.actualPage.value,
    'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    this.router.navigate([this.remoteService.selfAdjustService.actualPage.value,
    'vehicle-side-pictures', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
  }

  loadTemplates(imageType, index) {
    this.currentImageType = imageType;
    this.currentImageIndex = index;
    this.cameraControl(true);
    this.authPhoto = false;
    this.blockFitting = !!this.pictureDatas[index].blockFitting;
    this.object = this.pictureDatas[index].object2detect;
    const idName = (this.vehicleType === 'car'
      && (this.currentImageType === 'front' || this.currentImageType === 'rear'))
      ? this.currentImageType + this.plateType : this.currentImageType;
    if (document.getElementById(`${idName}Photo`)) {
      document.getElementById(`${idName}Photo`).style.display = 'flex';
    }
    if (this.pictureDatas[this.currentImageIndex].enableAI) {
      this.msg = this.remoteService.selfAdjustLanguageService.strLang(`Fit the ${this.object} on the template`);
      this.withoutAITemplate = false;
    } else {
      this.msg = this.remoteService.selfAdjustLanguageService.strLang('Fit the vehicle on the template');
      this.withoutAITemplate = true;
    }
    try {
      this.cd.detectChanges();
    } catch (err) {
      console.log('Error detectando los cambios', err);
    }
  }

  cameraControl(toggle) {
    if (toggle) {
      this.enableCamera = true;
    } else {
      const idName = (this.vehicleType === 'car'
        && (this.currentImageType === 'front' || this.currentImageType === 'rear'))
        ? this.currentImageType + this.plateType : this.currentImageType;
      if (document.getElementById(`${idName}Photo`)) {
        document.getElementById(`${idName}Photo`).style.display = 'none';
      }
      this.enableCamera = false;
      this.cameraON = false;
    }
  }

  detectPhoto() {
    this.infoModalBright = true;
    let t0;
    this.autoDetection = setInterval(() => {
      if (this.firstTime && this.pictureDatas[this.currentImageIndex].enableAI) {
        t0 = performance.now();
      }
      const data = this.drawVideoImgInCanvas(this.photo2DetectCtx).data;
      let colorSum = 0;
      for (let x = 0, len = data.length; x < len; x += 4) {
        colorSum += Math.floor(0.299 * data[x] + 0.587 * data[x + 1] + 0.114 * data[x + 2]);
      }
      const brightness = Math.floor(colorSum / (this.photo2Detect.width * this.photo2Detect.height));
      if (brightness < 80) {
        this.msgBright = this.remoteService.selfAdjustLanguageService.strLang('Too dark environment');
      } else if (brightness > 200) {
        this.msgBright = this.remoteService.selfAdjustLanguageService.strLang('Too bright environment');
      } else {
        this.msgBright = '';
      }
      if (this.pictureDatas[this.currentImageIndex].enableAI && this.supportWebGL2) {
        const cropImg = iaCar.cropObjectFitting(this.photo2DetectCtx, this.photo2Detect.width, this.photo2Detect.height, this.currentImageType, this.vertical);
        VehicleSidePicturesComponent.predictObjectFitting(cropImg).then((prediction) => {
          if (prediction[0].label === this.pictureDatas[this.currentImageIndex].object2detect) {
            this.authPhoto = true;
          } else {
            this.authPhoto = false;
          }
          if (this.firstTime) {
            const t1 = performance.now();
            this.firstTime = false;
            this.intervalTime = (t1 - t0) > 500 ? (t1 - t0) : 500;
          }
        });
      } else {
        this.authPhoto = true;
        this.blockFitting = false;
      }
    }, this.intervalTime);
  }

  /**
   * Funcion para pintar la imagen con la rotacion correcta y obtener su ImageData
   * (con rotacion de 90 grados cuando this.vertical == true, sin rotacion cuando this.vertical == false)
   *
   * @param canvasCtx Objeto CanvasRenderingContext2D a utilizar para pintar la imagen debidamente rotada
   * @returns ImageData
   */
  drawVideoImgInCanvas(canvasCtx: CanvasRenderingContext2D): ImageData {
    // Aqui se detecta la orientacion del movil para determinar si es necesario girar el canvas para
    // pintar la imagen proveniente del video.
    if (this.vertical) {
      const rad = Math.PI / 2;
      // La rotacion consiste en lo siguiente:
      // - Translacion del centro del canvas a lo que seria el centro de la imagen
      canvasCtx.translate(this.videoHeight / 2, this.videoWidth / 2);
      // - Rotacion del canvas con respecto al nuevo centro
      canvasCtx.rotate(rad * (-1));
      // - Pintado de la imagen proveniente del video
      canvasCtx.drawImage(this.video, (this.videoWidth / 2) * (-1) , (this.videoHeight / 2) * (-1), this.videoWidth, this.videoHeight);
      // - Se regresa la rotacion del canvas y la translacion realizada para mantener al canvas en su forma original
      canvasCtx.rotate(rad);
      canvasCtx.translate((this.videoHeight / 2) * (-1), (this.videoWidth / 2) * (-1));
    } else {
      canvasCtx.drawImage(this.video, 0, 0, this.videoWidth, this.videoHeight);
    }
    return canvasCtx.getImageData(0, 0, this.photo2Detect.width, this.photo2Detect.height);
  }

  /**
   * @description Función para añadir al eventListener 'orientationchange' para detectar cambios de
   * orientación del móvil
   */
  orientationChange() {
    // Se detecta nueva orientacion
    // Negate it because it check it at start not at final
    this.orientation.set();

    // Se vuelven a tomar las medidas de la pantalla para evitar problemas de consistecia
    this.canvasHeight = Math.floor(Math.min(window.innerWidth, window.innerHeight));
    this.canvasWidth = Math.floor(this.canvasHeight * this.aspectRatio);

    // Asignamos el nuevo tamaño del elemento de video el tamaño del canvas, pero tomando en cuenta la orientacion del movil
    if (this.vertical) {
      this.videoWidth = this.canvasHeight;
      this.videoHeight = this.canvasWidth;
    } else {
      this.videoWidth = this.canvasWidth;
      this.videoHeight = this.canvasHeight;
    }
  }

  updatePage() {
    this.actualSelfadjust.images.map((e) => {
      this.pictureDatas.forEach((element) => {
        if (element.type === e.type) {
          element.image = e.name;
          element.isBGShow = false;
        }
        if (element.fix && !this.remoteService.selfAdjustService.isPageFixed('vehicle-side-pictures')) {
          delete element.image;
          element.isBGShow = true;
        }
      });
    });
  }

  /**
   * Funcion para generar imagen de PREVIEW para mostrar en pantalla de camara con error o mensaje de aprovacion.
   * @returns dataUrl: string
   */
  generatePreview(): string {
    // Al generar un preview, se detecta la orientacion y se establecen las condiciones en las que se crea la imagen 
    // para manejarla en caso de cambios de orientacion 
    if (this.vertical) {
      this.previewOrientation = 'portrait';
      this.previewFixedStyles = {
        height: this.canvasWidth,
        width: this.canvasHeight
      };
      this.photo2preview.height = this.canvasWidth;
      this.photo2preview.width = this.canvasHeight;
    } else {
      this.previewOrientation = 'landscape';
      this.previewFixedStyles = {
        height: this.canvasHeight,
        width: this.canvasWidth
      };
      this.photo2preview.height = this.canvasHeight;
      this.photo2preview.width = this.canvasWidth;
    }

    this.photo2previewCtx.drawImage(this.video, 0, 0, this.videoWidth, this.videoHeight);

    return this.photo2preview.toDataURL("image/png")
  }

  async takePhoto() {
    console.log('Inicio medir tiempo takePhoto', Date.now());
    clearInterval(this.autoDetection);
    this.canvasForHDImage.width = this.canvasWidth;
    this.canvasForHDImage.height = this.canvasHeight;
    this.drawVideoImgInCanvas(this.canvasForHDImage.getContext('2d'));

    // Pintamos la imagen como aparece en pantalla (sin ninguna rotacion) para el preview
    this.photo2DetectCtx.drawImage(this.video, 0, 0, this.videoWidth, this.videoHeight);
    this.preview = this.generatePreview();

    // Volvemos a tomar la imagen debidamente rotada para continuar con las detecciones
    this.drawVideoImgInCanvas(this.photo2DetectCtx);
    const imageData = this.photo2DetectCtx.getImageData(0, 0, this.photo2Detect.width, this.photo2Detect.height);
    const dataURL = this.photo2Detect.toDataURL('image/jpeg', this.remoteService.nativeCameraService.quality.compression);

    this.imageValidity = await this.remoteService.selfAdjustService.validateImg(dataURL,
      this.photo2DetectCtx, this.canvasWidth, this.canvasHeight, this.photo2Detect, this.currentImageType, this.vehicleType);
    if (this.imageValidity.valid) {
      if (this.vehicleType === 'car' && this.supportWebGL2) {
        if (this.carPartDetection) {
          VehicleSidePicturesComponent.predictCarPartOcclusion(imageData).then((prediction) => {
            console.log('predictCarPartOcclusion bien');
            // Si la parte detectada no es la esperada muestro error y si es bloqueante oculto botón de aceptar
            if (prediction[0].label !== this.currentImageType) {
              this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang(
                `The ${VEHICLE_PARTS[this.vehicleType][this.currentImageType]} part of the vehicle is not correctly shown. Please, take it again.`);
              this.isNotPart = this.carPartBlocker;
              // Si hay oclusión y es bloqueante, enseño mensaje y oculto botón de aceptar
            }
            this.forceImgValidity();
            this.viewPhoto = true;
            console.log('Fin medir tiempo takePhoto', Date.now());
            this.cd.detectChanges();
          }).catch((carError) => {
            console.log('Error en predictCarPartOcclusion', carError);
          });
        } else {
          this.viewPhoto = true;
          console.log('Fin medir tiempo takePhoto', Date.now());
          this.cd.detectChanges();
        }
      } else if (this.vehicleType === 'moto' && this.supportWebGL2) {
        if (this.motoPartDetection) {
          VehicleSidePicturesComponent.predictMotoPart(this.photo2DetectCtx.getImageData(0, 0, this.canvasWidth, this.canvasHeight))
            .then((prediction) => {
              if (prediction[0].label !== this.currentImageType) {
                this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang(
                  `The ${VEHICLE_PARTS[this.vehicleType][this.currentImageType]} part of the vehicle is not correctly shown. Please, take it again.`);
                this.isNotPart = this.motoPartBlocker;
              }
            this.forceImgValidity();
            this.viewPhoto = true;
            console.log('Fin medir tiempo takePhoto', Date.now());
            this.cd.detectChanges();
          }).catch((motoErr) => {
            console.log('Error en predictMotoPart', motoErr);
          });
        } else {
          this.viewPhoto = true;
          console.log('Fin medir tiempo takePhoto', Date.now());
          this.cd.detectChanges();
        }
      } else {
        this.viewPhoto = true;
        console.log('Fin medir tiempo takePhoto', Date.now());
        this.cd.detectChanges();
      }
    } else {
      this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang(this.imageValidity.errorMessage);
      this.isNotPart = true;
      this.forceImgValidity();
      this.viewPhoto = true;
      console.log('Fin medir tiempo takePhoto', Date.now());
      this.cd.detectChanges();
    }
  }

  /**
   * @description Esta función se encarga de forzar la validación de imagen en caso de NO ser el primer intento de la foto.
   */
  forceImgValidity() {
    console.log(this.forceImgValidityParts, this.currentImageType, this.forceImgValidityParts[this.currentImageType]);
    // Esta comprobación asegura que si este flag está encendido, se aceptará la imagen aunque tenga errores.
    // La segunda parte del AND es para que el error de una imagen mal formada,
    // típicamente causado porque la cámara esta siendo utilizada en otro lado,
    // no cause el forzado de la validación positiva de la imagen.
    if (this.forceImgValidityParts[this.currentImageType] && this.preview.indexOf('image') !== -1) {
      this.errorMessage = undefined;
      this.isNotPart = false;
    } else {
      // En caso contrario, se verifica si la imagen tuvo error para encender el flag para forzar el aceptado en el próximo intento
      if (this.errorMessage) {
        this.forceImgValidityParts[this.currentImageType] = true;
      }
    }
  }

  closeCamera() {
    clearInterval(this.autoDetection);
    this.cameraControl(false);
  }

  switchCamera() {
    this.remoteService.nativeCameraService.switchCamera(this.video, this.stream2Delete, this.backCameras);
  }

  somePhotoUploadingError() {
    return this.pictureDatas.some((picture) => {
      return !picture.uploadOK;
    });
  }

  somoPhoNotTaken() {
    if (!this.sidePictures.mandatory) {
      return false;
    }
    return this.pictureDatas.some((picture) => {
      return !picture.image;
    });
  }

  somePhotoLoading() {
    return this.pictureDatas.some((picture) => {
      return picture.loading;
    });
  }

  private isFixed(index) {
    this.unfixedArray[index] = true;
    if (!this.unfixedArray.includes(false)) {
      this.unfixed = false;
      this.remoteService.selfAdjustService.fixedPage('vehicle-side-pictures');
    }
  }

  private imageUpload(image, imageType, index, coordinate?) {
    let oldImage;
    if (this.pictureDatas[index]) {
      oldImage = this.pictureDatas[index].image;
    }
    this.isNotPart = false;
    this.pictureDatas[index].isBGShow = false;
    this.pictureDatas[index].loading = true;
    this.pictureDatas[index].image = image;
    this.cd.detectChanges();
    const newImg = {
      name: image,
      type: imageType,
    };
    const contentType = image.slice(image.indexOf('image/'), image.indexOf(';'));
    const extension = contentType.split('image/')[1];
    const takePhotoAt = Math.trunc(new Date().getTime() / 1000);
    const caption = this.pictureDatas[index].name;
    this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust.id, extension).pipe(take(1)).subscribe((media) => {
      this.remoteService.selfAdjustService.bucketImage(image, media['media_url'], contentType).pipe(take(1)).subscribe(() => {
        const logInfo = {
          component: 'vehicle-side-pictures',
          action: 'push-image',
        };
        this.remoteService.selfAdjustService.addImage(
          this.actualSelfadjust.id,
          this.actualSelfadjust['securityKey'],
          media['media_id'],
          imageType,
          extension,
          logInfo,
          coordinate,
          caption,
          undefined,
          undefined).pipe(take(1)).subscribe(() => {
          this.pictureDatas[index].loading = false;
          this.remoteService.selfAdjustService.updateLocalImages(newImg);
          if (this.sidePictures.fixed !== undefined && !this.sidePictures.fixed) {
            this.isFixed(index);
          }
          if (this.pictureDatas[index].fix) {
            delete this.pictureDatas[index].fix;
          }
          if (!this.pictureDatas[index].uploadOK) {
            this.pictureDatas[index].uploadOK = true;
          }
          this.cd.detectChanges();
        },
        (err) => {
          console.log('Error in addImage', err);
          this.pictureDatas[index].loading = false;
          this.uploadKO(index);
          if (oldImage) {
            this.pictureDatas[index].image = oldImage;
            this.pictureDatas[index].loading = false;
          }
        });
      },
      (err) => {
        console.log('Error in bucketImage', err);
        this.pictureDatas[index].loading = false;
        this.uploadKO(index);
        if (oldImage) {
          this.pictureDatas[index].image = oldImage;
          this.pictureDatas[index].loading = false;
        }
      });
    },
    (err) => {
      console.log('Error in getMediaUrl', err);
      this.pictureDatas[index].loading = false;
      this.uploadKO(index);
      if (oldImage) {
        this.pictureDatas[index].image = oldImage;
        this.pictureDatas[index].loading = false;
      }
    });
  }

  async acceptPhoto() {
    this.viewPhoto = false;
    this.errorMessage = undefined;
    this.closeCamera();
    const image = new Image();
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    image.src = this.canvasForHDImage.toDataURL('image/jpeg', this.remoteService.nativeCameraService.quality.compression);
    image.onload = () => {
      this.imageUpload(image.src, this.currentImageType, this.currentImageIndex, coordinates);
    };
  }

  discardPhoto() {
    this.isNotPart = false;
    this.viewPhoto = false;
    this.errorMessage = undefined;
    this.detectPhoto();
  }

  getVehicleImg = () => `../../assets/images/logo/${this.vehicleType}Rotate.svg`;
}

export default VehicleSidePicturesComponent;
