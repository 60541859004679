import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  pageNumber: number;
  fillNavbar: number;
  totalPage: number = 0;
  actualPageSub: Subscription;
  @Input() hideNumbers;

  constructor(
    public remoteService: RemoteLibraryService,
    public location: Location
  ) { }
  ngOnInit() {
    console.log("Initializing NavBarComponent!");
    this.totalPages();
    this.actualPageSub = this.remoteService.selfAdjustService.actualPage.subscribe(val => {
      this.fillNavbar = (Math.ceil(100/this.totalPage)*val);
      this.pageNumber = val;
    })
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
  }


  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {

      }
    }
  }
  
  totalPages() {
    for(let element of this.remoteService.selfAdjustService.customNavigation){
      if(element.show !== false && element.name !== 'landing'  && element.name !== 'final') {
        this.totalPage++;
      }
    };
  }
  
  ngOnDestroy(){
    this.actualPageSub.unsubscribe();
    console.log("Destroying NavBarComponent!");
  }
}
