export class EnvironmentalCaptureZoneStepConstants {
  public static ExampleImages = {
    front0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/front/0.png',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    front1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/front/1.png',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    front2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/front/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    roof0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/roof/0.png',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    roof1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/roof/1.png',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    roof2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/roof/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    bonnet0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/bonnet/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    bonnet1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/bonnet/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    bonnet2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/bonnet/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    leftfrontdoor0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/leftfrontdoor/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    leftfrontdoor1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/leftfrontdoor/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    leftfrontdoor2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/leftfrontdoor/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rightfrontdoor0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rightfrontdoor/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rightfrontdoor1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rightfrontdoor/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rightfrontdoor2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/rightfrontdoor/2.mov',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    windscreen0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/windscreen/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    windscreen1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/windscreen/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    windscreen2:[
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/windscreen/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    frontwingleft0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/frontwingleft/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    frontwingleft1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/frontwingleft/1_0.jpg',
        badge: {
          text: 'Correct',
          color: 'green',
          icon: 'green-check'
        },
      },
      {
        type: 'img',
        src: '../../assets/environmentalHelp/frontwingleft/1_1.jpg',
        badge: {
          text: 'Incorrect',
          color: 'red',
          icon: 'red-close'
        },
      },
    ],
    frontwingleft2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/frontwingleft/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    frontwingright0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/frontwingright/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    frontwingright1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/frontwingright/1_0.jpg',
        badge: {
          text: 'Correct',
          color: 'green',
          icon: 'green-check'
        },
      },
      {
        type: 'img',
        src: '../../assets/environmentalHelp/frontwingright/1_1.jpg',
        badge: {
          text: 'Incorrect',
          color: 'red',
          icon: 'red-close'
        },
      },
    ],
    frontwingright2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/frontwingright/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindowLeft0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rearwindowLeft/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindowLeft1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rearwindowLeft/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindowLeft2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/frontwingleft/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindowRight0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rearwindowRight/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindowRight1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rearwindowRight/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindowRight2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/rearwindowRight/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    leftreardoor0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/leftreardoor/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    leftreardoor1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/leftreardoor/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    leftreardoor2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/frontwingleft/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rightreardoor0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rightreardoor/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rightreardoor1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rightreardoor/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rightreardoor2: [
      {
        type: 'video',
        src: '../../../../assets/environmentalHelp/rightreardoor/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rear0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rear/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rear1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rear/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rear2: [
      {
        type: 'video',
        src: '../../assets/environmentalHelp/rear/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindow0: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rearwindow/0.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindow1: [
      {
        type: 'img',
        src: '../../assets/environmentalHelp/rearwindow/1.jpg',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ],
    rearwindow2: [
      {
        type: 'video',
        src: '../../assets/environmentalHelp/rearwindow/2.mp4',
        badge: {
          text: 'Example',
          color: 'green',
        },
      },
    ]
  };
};
