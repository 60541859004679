import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Photo } from '../environmental-capture-zone-step/environmental-capture-zone-step.component';

type Step = {
  title: string,
  description: string,
  collapsed: boolean,
  disabled?: boolean,
  completed: boolean,
  photos: Photo[],
}

export type Zone = {
  name: string,
  title: string,
  completedSteps: number,
  steps: Step[]
}

@Component({
  selector: 'app-environmental-capture-zone',
  templateUrl: './environmental-capture-zone.component.html',
  styleUrls: [
    './environmental-capture-zone.component.scss',
  ],
})
export class EnvironmentalCaptureZoneComponent implements OnInit {

  @Input() zone: Zone;
  @Output() zoneChange = new EventEmitter<Zone>();

  currentStep?: Step;
  uploadingPhoto = false;
  takePictureButtonDisabled = false;
  additionalImages: number;

  @ViewChild('fileInput') fileInput: ElementRef;

  public steps: Step[] = [];

  constructor(
    public remoteService: RemoteLibraryService,
  ) {}

  ngOnInit() {
    const selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
  
    this.zone.steps.forEach(step => step.photos = []);
    
    this.zone.steps = selfadjust.images
    .filter(image => image.vehiclePart == this.zone.name && image.type == 'damage')
    .map(image => {
      return { src: image.name, uploading: false, step: image.step - 1 };
    })
    .reduce((steps, {src, uploading, step}) => {
      steps[step] = steps[step] || { photos: [] };
      steps[step].photos = [].concat(steps[step].photos).concat({ src, uploading });
      this.completeStep(steps[step]);
      return steps;
    }, this.zone.steps);
    
    const uncompleted = this.zone.steps.find(step => !step.completed);
    if (uncompleted) {
      this.showStep(uncompleted);
    }

    this.additionalImages = this.remoteService.selfAdjustService.myPage('environmental-capture').maxAdditionalImage;
  }

  goToEnviromentalCapture() {
    this.zone = undefined;
    this.zoneChange.next(this.zone);
  }

  takePicture() {
    this.fileInput.nativeElement.click();
  }
  
  async imageUpload(event: Event) {
    if(event.target instanceof HTMLInputElement) {
      if(event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        event.target.value = '';

        this.uploadingPhoto = true;
        const stepPhoto: Photo = {
          src: URL.createObjectURL(file),
          uploading: true,
        };
        this.currentStep.photos.push(stepPhoto);
        this.currentStep.photos = [].concat(this.currentStep.photos);

        const resized = await this.remoteService.selfAdjustService.resizeAndDraw({ files: [file] }, 1280);
        const contentType = resized.slice(resized.indexOf('image/'), resized.indexOf(';'));
        const extension = contentType.split('image/')[1];
        const selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
        const selfadjustId = selfadjust.id;
        const media = await this.remoteService.selfAdjustService.getMediaUrl(selfadjustId, extension).toPromise();
        await this.remoteService.selfAdjustService.bucketImage(resized, media['media_url'], contentType).toPromise();
        const securityKey = selfadjust.securityKey;
        const imageType = 'damage';
        const logInfo = {
          component: 'environmental-capture',
          action: 'push-image',
        };
        const coordinates = await this.remoteService.selfAdjustService.getCoordinates();

        const stepIndex = this.zone.steps.findIndex(item => item == this.currentStep);

        const number = this.zone.steps.reduce((totalPhotos, step) => totalPhotos + step.photos.length, 0);

        await this.remoteService.selfAdjustService.addImage(
          selfadjustId,
          securityKey,
          media['media_id'],
          imageType,
          extension,
          logInfo,
          coordinates,
          this.zone.name,
          this.zone.name,
          number,
          undefined,
          undefined,
          stepIndex == 0 ? 'context' : 'detail',
          stepIndex + 1, // have to do this since pushimage lambda check if falsy and 0 == false
        ).toPromise();

        selfadjust.images.push({
          type: imageType,
          vehiclePart: this.zone.name,
          coordinates,
          name: stepPhoto.src,
          uploading: false,
          step: stepIndex + 1,
          number,
        });

        stepPhoto.uploading = false;
        this.uploadingPhoto = false;
        this.completeStep(this.currentStep);
      }
    }
  }
  
  completeStep(step: Step) {
    step.completed = true;
    this.zone.completedSteps = this.zone.steps.filter(step => step.completed).length;
    const nextStep = this.zone.steps[this.zone.steps.findIndex(item => item == step) + 1];
    if(nextStep) {
      nextStep.disabled = false;
    }
  }

  showStep(step: Step) {
    if(step.disabled) {
      return;
    }

    this.takePictureButtonDisabled = true;
    setTimeout(() => {
      this.takePictureButtonDisabled = false;
    }, 2000);

    this.currentStep = step;

    step.collapsed = false;
    this.zone.steps.forEach(item => {
      if(item != step) {
        item.collapsed = true;
      }
    });
  }

  nextStep() {
    this.showStep(this.zone.steps[this.zone.steps.findIndex(item => item == this.currentStep) + 1]);
  }

  showTakePictureButton() {
    return !this.showNextButton() && !this.showFinishButton();
  }

  showNextButton() {
    if(!this.currentStep || this.showFinishButton()) {
      return false;
    }

    return this.currentStep.completed && this.currentStep != this.zone.steps[this.zone.steps.length - 1];
  }

  showFinishButton() {
    return !this.zone.steps.some(step => !step.completed);
  }
};
