import { Component, OnDestroy, OnInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { ClaimDamages } from './interfaces/claimDamages';
import { CarParts } from './interfaces/carParts';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GalleryComponent } from '../shared/gallery/gallery.component';

@Component({
  selector: 'app-claim-damages',
  templateUrl: './claim-damages.component.html',
  styleUrls: ['../../styles/layouts/page.scss',
    '../../styles/layouts/error-message.scss',
    '../../styles/layouts/main-content.scss',
    '../../styles/layouts/fit-content.scss',
    '../../styles/layouts/button-container.scss',
    '../../styles/layouts/loader.scss',
    '../../styles/layouts/button.scss',
    '../../styles/claim-damages/step-list.scss',
    '../../styles/claim-damages/gallery.scss',
    '../../styles/claim-damages/car-image.scss',
    '../../styles/claim-damages/help-modal.scss',
  ]
})
export class ClaimDamagesComponent implements OnInit, OnDestroy {
  @ViewChild('claimDamages', { read: ViewContainerRef }) container: ViewContainerRef;
  private galleryRef: ComponentRef<GalleryComponent>;
  private destroy$ = new Subject<void>();

  loaded = false;
  loading = true;
  openNotification = false;
  isMandatory: boolean;

  claimDamages: ClaimDamages;
  carParts: CarParts;

  damageData = [];
  damageParts: Array<string>;
  transformedDamageParts: Array<string>;

  minNumPhotos: number;
  maxNumPhotos: number;
  showHelp: number;
  numOfPart = 1;
  lastWarningIndex?: number;
  actualSelfadjust;
  reopened = false;
  fromButton = false;
  loadingPhoto = '';
  loadingNextPage = false;
  canGoToPhotoList = false;
  hasActiveWarning = false;
  warningIndex?: number;
  reopenedClaimDamage?: Record<string, any>;

  constructor(
    public remoteService: RemoteLibraryService,
    public cd: ChangeDetectorRef,
    public router: Router,
    private resolver: ComponentFactoryResolver,
  ) { }

  async ngOnInit() {
    this.remoteService.selfAdjustService.actualSelfAdjust =
      (await this.remoteService.selfAdjustService.loadData(this.remoteService.selfAdjustService.secretKey).pipe(take(1)).toPromise() as any).entity;

    this.claimDamages = this.remoteService.selfAdjustService.myPage('claim-damages');
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.reopenedClaimDamage = this.getReopenClaimDamages();
    if (this.actualSelfadjust.reopenInfo 
        && this.reopenedClaimDamage) {
      this.reopened = true;
      this.canGoToPhotoList = true;
    }
    this.carParts = this.actualSelfadjust.carpart || this.actualSelfadjust.vehicleParts;
    this.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
    this.updatePage();
  }

  /**
   * @description Función encargada de eliminar referencias externas que causen que el componente
   * no termine su ciclo de vida
   */
  ngOnDestroy() {
    delete this.cd;
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
    * @description Actualiza la información de claim-damages
    */
  updatePage() {
    this.minNumPhotos = this.claimDamages.minImagesPerDamage || 1;
    this.maxNumPhotos = this.claimDamages.maxImagesPerDamage || 6;
    this.isMandatory = this.claimDamages.mandatory || false;
    this.loading = false;
    this.loaded = true;
    this.damageParts = this.getDamageParts();
    this.transformedDamageParts = this.titleCaseDamagePart();
    this.updateDamageData();
  }

  /**
   * @description Actualiza la lista de imágenes subidas por parte del vehículo
   */
  updateDamageData(): void {
    const imageWarnings = JSON.parse(localStorage.getItem('imageWarnings') || '{}')[this.actualSelfadjust.id] || [];
    this.damageData = [];
    const hasFix = (image) => {
      if (this.reopened) {
        const claimDamagesPage = this.actualSelfadjust.reopenInfo.pages.find((page) => page.name === 'claim-damages');
        return claimDamagesPage.damagePictures.some((picture) => picture.number === image.number && picture.part === image.vehiclePart && picture.fix);
      } else {
        return false;
      }
    }
    const getPartImages = (part) => {
      const partImages = this.actualSelfadjust.images.filter((image) => image.vehiclePart === part);
      return partImages.map((image) => {
        return {
          ...image,
          loading: false,
          fix: hasFix(image),
          warning: imageWarnings.find((warn) => warn === image.uuid) ? true : false,
        };
      });
    };
    this.damageParts.forEach((part) => {
      const x = {
        part,
        images: getPartImages(part),
      };
      this.damageData.push(x);
    });

    setTimeout(() => {
      const warningElement = document.getElementById('warning');
      const addPhoto = document.getElementById('add-photo');
      if (!warningElement && addPhoto) {
        document.getElementById('add-photo').scrollIntoView({ behavior: 'smooth' });
      }
    }, 100)
  }

  /**
   * @description Carga estilos dinámicos en caso de que exista la carpeta personalizada
   */
  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`)
      } catch (error) {
      }
    }
  }

  /**
    * @description Devuelve las partes dañadas marcadas en car-parts
    * y en autojustes reabiertos solo las partes que tienen imagenes reabiertas
    */
  getDamageParts(): Array<string> {
    let damageParts: Array<string> = [];
    if (this.reopened) {
      this.reopenedClaimDamage.damagePictures.forEach((image) => {
        if (image.fix && !damageParts.includes(image.part)) {
          damageParts.push(image.part);
        }
      });
      damageParts = this.remoteService.selfAdjustService.orderedVehicleZones.filter(key => damageParts.includes(key));
    } else {
      damageParts = this.remoteService.selfAdjustService.orderedVehicleZones.filter(key => this.carParts[key]);
    }
    this.canGoToPhotoList = this.photoIsTaken(damageParts[this.numOfPart - 1]);
    return damageParts;
  }

  /**
   * @description Devuelve las claim-damages pages si existen
   */
  getReopenClaimDamages(): Record<string, any> | undefined {
    if(!this.actualSelfadjust || !this.actualSelfadjust.reopenInfo ){
      return 
    }
    
    const pages = this.actualSelfadjust.reopenInfo.pages;

    if(!Array.isArray(pages)) {
      return
    }

    return pages.find((page) => page.name === 'claim-damages');
 }

  /**
   * @description Devuelve las partes dañadas transformadas para la traducción
   * Se devuelven en mayúsculas porque ya se utilizan esas etiquetas capitalizadas
   */
  titleCaseDamagePart() {
    const titleCaseDamagePart = [];
    this.damageParts.forEach((part, index) => {
      let transformedPart;
      switch (part) {
        case 'frontwingleft':
          transformedPart = 'front wing left';
          break;
        case 'frontwingright':
          transformedPart = 'front wing right';
          break;
        case 'leftfrontdoor':
          transformedPart = 'front door left';
          break;
        case 'leftreardoor':
          transformedPart = 'rear door left';
          break;
        case 'rearwindow':
          transformedPart = 'rear window';
          break;
        case 'rearwindowLeft':
          transformedPart = 'rear window left';
          break;
        case 'rearwindowRight':
          transformedPart = 'rear window right';
          break;
        case 'rightfrontdoor':
          transformedPart = 'front door right';
          break;
        case 'rightreardoor':
          transformedPart = 'rear door right';
          break;
        default:
          transformedPart = part;
      }
      titleCaseDamagePart[index] = transformedPart.charAt(0).toUpperCase() + transformedPart.slice(1);
    });
    return titleCaseDamagePart;
  }

  /**
    * @description Devuelve si se ha tomado una foto del tipo que se pasa por parámetro
    * @param part Parte dañada del coche
    */
  photoIsTaken(part) {
    return this.actualSelfadjust.images.some(image => image.type === 'damage' && image.vehiclePart === part);
  }

  /**
    * @description Función que sube una imagen a s3 y guarda su referencia em BBDD
    * @param file Imagen que se quiere subir
    * @param index Posición de la imagen dentro de la lista de damageData
    */
  async uploadImage(inputFile, indexData, indexImage, event?: Event, action?: string) {
    this.loading = true;
    this.resetActiveWarning();

    const oldDamageData = JSON.parse(JSON.stringify(this.damageData[this.numOfPart - 1]));

    // Si ya se ha subido alguna imagen, se setea a loading y se actualiza la imagen si es una repetición de imagen
    if (this.damageData[this.numOfPart - 1].images[indexImage]) {
      this.damageData[this.numOfPart - 1].images[indexImage].loading = true;

      if (action === "repeat") {
        const reader = new FileReader();
        reader.readAsDataURL(inputFile.files[0]);
        reader.onload = () => {
          const base64data = reader.result;
          this.damageData[this.numOfPart - 1].images[indexImage].warning = false;
          this.damageData[this.numOfPart - 1].images[indexImage].fix = false;
          this.damageData[this.numOfPart - 1].images[indexImage].fixed = true;
          this.damageData[this.numOfPart - 1].images[indexImage].name = base64data as string;
        }
      }
    }

    // Si la llamada viene con evento es que es una nueva imagen
    if (event) {
      const { files } = event.target as HTMLInputElement

      if (!files || !files.length) {
        return
      }

      const image = {
        loading: true,
        name: undefined,
      };
      this.damageData[this.numOfPart - 1].images.push(image);

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        this.loadingPhoto = base64data as string;
        image.name = base64data;
      }
    }

    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    this.cd.detectChanges();

    try {
      const result = await this.remoteService.selfAdjustService.resizeAndDraw(inputFile, 1280);
      const vehiclePart = this.damageData[indexData].part;
      const contentType = result.slice(result.indexOf('image/'), result.indexOf(';'));
      const extension = contentType.split('image/')[1];
      const caption = this.damageData[indexData].part;
      const newImg = {
        name: result,
        type: 'damage',
        vehiclePart,
        number: indexImage + 1,
        uuid: undefined,
        fixed: true,
        loading: true
      };

      // Get media URL
      const media = await this.remoteService.selfAdjustService.getMediaUrl(
        this.actualSelfadjust.id, 
        extension
      ).pipe(take(1)).toPromise();

      // Upload to bucket
      await this.remoteService.selfAdjustService.bucketImage(
        result, 
        media['media_url'], 
        contentType
      ).pipe(take(1)).toPromise();

      const logInfo = {
        component: 'claim-damages',
        action: 'push-image'
      };

      // Add image
      const response = await this.remoteService.selfAdjustService.addImage(
        this.actualSelfadjust.id,
        this.actualSelfadjust['securityKey'],
        media['media_id'],
        'damage',
        extension,
        logInfo,
        coordinates,
        caption,
        vehiclePart,
        indexImage + 1
      ).pipe(take(1)).toPromise();

      this.remoteService.selfAdjustService.updateLocalImages(newImg);
      const image = this.damageData[this.numOfPart - 1].images[indexImage];

      const imageUuid = (await response.json()).entity.image_uuid;
      newImg.uuid = imageUuid;

      if (this.claimDamages.warnings && this.claimDamages.warnings.includes('no_damages')) {
        const warning = await this.remoteService.selfAdjustService.checkImageForDamages(inputFile, caption);
        this.remoteService.selfAdjustService.saveImageWarning(imageUuid, warning);

        image.warning = warning;
        if (warning) {
          this.setDamageWarning(indexImage);
        }
      }

      this.updateDamageData();
      this.loading = false;
      if (indexImage && image) {
        image.loading = false;
      }
      inputFile.value = null;
      this.cd.detectChanges();

    } catch (error) {
      console.log('Error during image upload:', error);
      this.showNotification(indexData);
      // Rollback images to last state
      this.damageData[this.numOfPart - 1] = oldDamageData;
    }
  }

  /**
    * @description Muestra un mensaje de error cuando no se ha subido bien una imagen
    * @param index Índice de la imagen que ha dado problemas
    */
  showNotification(index) {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      this.damageData[index].loading = false;
      this.loading = false;
      this.cd.detectChanges();
    }, 5000);
    this.cd.detectChanges();
  }

  /**
    * @description Si tiene más fotografías que tomar muestra la nueva parte del vehíclo, sino avanza a la siguiente página
    */
  async nextPage() {
    this.loadingNextPage = true;

    if (this.numOfPart !== this.damageData.length) {
      this.numOfPart++;
      this.resetActiveWarning();
      this.loadingNextPage = false
    } else {
      const data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'claim-damages',
          action: 'continue'
        },
        fields2Update: {}
      };

      try {
        await this.remoteService.selfAdjustService.pushData(data).toPromise();

        console.log(
          'INFO: Navega a:', this.remoteService.selfAdjustService.nextPage,
          'con secretKey:', this.remoteService.selfAdjustService.secretKey
        );
      } catch (err) {
        console.log('Error in selfadjust-update', err);
        this.showNotification(undefined);
      }

      await this.router.navigate([
        ...this.remoteService.selfAdjustService.nextPage,
        { secretKey: this.remoteService.selfAdjustService.secretKey }
      ]);
    }
  }

  /**
    * @description Si es la primera parte dañada vuelve a la página anterior, sino vuelve a la parte dañada anterior
    */
  backPage() {
    if (this.numOfPart === 1) {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:',
        this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage,
      { secretKey: this.remoteService.selfAdjustService.secretKey }]);
    } else {
      this.numOfPart--;
      this.resetActiveWarning();
    }
  }

  /**
    * @description Comprueba que si la toma de fotografías es obligatoria tiene que haberse tomado más o igual de las mínimas establecidas
    */
  nextAvailable() {
    let nextAvailable;
    if (this.reopened) {
      nextAvailable = this.damageData[this.numOfPart - 1].images.every((image) => {
        return !image.fix || (image.fix && image.fixed);
      });
    } else {
      nextAvailable = !this.isMandatory ? true : this.damageData[this.numOfPart - 1].images.length >= this.minNumPhotos;
    }
    return nextAvailable;
  }

  /**
     * @description Abre la ayuda de la toma de fotografías
     * @param file Imagen que se quiere subir
     * @param index Posición de la imagen dentro de la lista de damageData
     * @param event Evento que desencadena la función
    */
  openHelpOrTakePhoto(file, indexData, indexImage, event) {
    if (!this.showHelp && this.showHelp != 0) {
      event.preventDefault();
      this.showHelp = 0;
    } else {
      document.getElementById('image').addEventListener('change', async (event) => {
        this.canGoToPhotoList = true;
        await this.uploadImage(file, indexData, indexImage, event);
      });
    }
  }

  /**
   * @description Abre la ayuda de la toma de fotografías desde el botón de ayuda
   */
  public openHelpFromButton() {
    this.showHelp = 0;
    this.fromButton = true;
  }

  /**
     * @description Cierra el modal de ayuda y hace click en el botón de tomar fotografías
    */
  takePhoto() {
    this.showHelp++;
    document.getElementById('image').click();
  }

  /**
     * @description Cierra la ayuda de la toma de fotografías
     */
  closeHelp() {
    this.showHelp++;
  }

  /**
   * @description Devuelve el índice de la última imagen con un warning
   * @returns {number} Índice de la última imagen con un warning, o undefined si no hay ninguna
   */
  protected getLastWarningIndex(): number | undefined {
    const index = this.damageData[this.numOfPart - 1].images.findLastIndex((image) => image.warning);
    return (index !== -1) ? index + 1 : undefined;
  }

  /**
   * @description Abre la galería de imágenes con la imagen que se ha seleccionado.
   * @param index Posición de la imagen dentro de damageData
   */
  public onPhotoClick(index: number): void {
    if (this.loading) {
      return;
    }

    const factory = this.resolver.resolveComponentFactory(GalleryComponent);
    this.galleryRef = this.container.createComponent(factory);

    this.galleryRef.instance.images = this.damageData[this.numOfPart - 1].images;
    this.galleryRef.instance.index = index;
    this.galleryRef.instance.reopened = this.reopened;

    this.galleryRef.instance.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.galleryRef.destroy();
      });

    this.galleryRef.instance.onRepeat
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: { file: HTMLInputElement, index: number }) => {
        const { file, index } = data;
        await this.uploadImage(file, this.numOfPart - 1, index, null, "repeat");
        this.galleryRef.destroy();
      });
  }

  private resetActiveWarning(): void {
    this.hasActiveWarning = false;
    this.warningIndex = undefined;
  }

  public setDamageWarning(index: number): void {
    this.hasActiveWarning = true;
    this.warningIndex = index;

    setTimeout(() => {
      const warningElement = document.getElementById('warning');
      if (warningElement) {
        warningElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100)
  }
}
