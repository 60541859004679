import {Component, OnInit, ChangeDetectorRef, AfterContentChecked, AfterContentInit} from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

const DEFAULT_ABSENCE_REASON = [
  {
    name: 'Está ausente',
    result: 'Ausente'
  },
  {
    name: 'No quiere contratar',
    result: 'Ausente',
    observations: 'No quiere contratar'
  },
  {
    name: 'No se ha podido contactar',
    result: 'Ausente',
    observations: 'No contactado'
  },
  {
    name: 'Toma de fotos por el asegurado'
  },
  {
    name: 'Otra Incidencia',
    result: 'Incidencia'
  },
];

@Component({
  selector: 'app-presence-insured',
  templateUrl: './presence-insured.component.html',
  styleUrls: ['./presence-insured.component.scss']
})
export class PresenceInsuredComponent implements OnInit , AfterContentInit {

  presenceInsured: any;
  loaded = false;
  loading = false;
  actualSelfadjust: any;
  absenceReasons = DEFAULT_ABSENCE_REASON;
  presencePicture = {
    id: 16,
    name: 'presencePicture',
    type: 'presence',
    image: null,
    bgImage: 'assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    mandatory: false,
    isFullfilled: false,
  };
  openNotification = false;
  nextAvailable = false;
  reason = '';
  observations = '';

  constructor(public remoteService: RemoteLibraryService, public cd: ChangeDetectorRef, public router: Router) { }

  ngOnInit() {
    this.presencePicture.bgImage = (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') ? '/assets/images/logo/camera_new.svg' : this.presencePicture.bgImage;

    this.presenceInsured = this.remoteService.selfAdjustService.myPage('presence-insured');
    this.loaded = true;
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.updatePage(this.actualSelfadjust);
    this.nextAvailable = this.checkMandatoryFullfilled();
  }

  checkMandatoryFullfilled() {
    let mandatoryFullfilled = true;
    if (this.presencePicture.mandatory && !this.presencePicture.isFullfilled) {
      mandatoryFullfilled = false;
    }
    if (!this.reason) {
      mandatoryFullfilled = false;
    }
    return mandatoryFullfilled;
  }

  checkMandatoryPhoto(reason) {
    if (reason === 'Está ausente') {
      this.presencePicture.mandatory = true;
    } else {
      this.presencePicture.mandatory = false;
    }
    this.nextAvailable = this.checkMandatoryFullfilled();
  }
  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }
  async imageUpload(file) {
    const imageType = this.presencePicture.type;
    const index = this.presencePicture.id;
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    this.presencePicture.loading = true;
    this.cd.detectChanges();
    this.remoteService.selfAdjustService.resizeAndDraw(file, 1280).then(result => {
      const newImg = {
        name: result,
        type: imageType
      };
      const contentType = result.slice(result.indexOf('image/'), result.indexOf(';'));
      const extension = contentType.split('image/')[1];
      const caption = 'Presence insured';
      this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust.id, extension).pipe(take(1)).subscribe(media => {
        this.remoteService.selfAdjustService.bucketImage(result, media['media_url'], contentType).pipe(take(1)).subscribe(() => {
          const logInfo = {
            component: 'presence-insured',
            action: 'push-image'
          };
          this.remoteService.selfAdjustService.addImage(this.actualSelfadjust.id, this.actualSelfadjust['securityKey'],
          media['media_id'], imageType, extension, logInfo, coordinates, caption).subscribe(() => {
            this.remoteService.selfAdjustService.updateLocalImages(newImg);
            this.presencePicture.image = result;
            this.presencePicture.isBGShow = false;
            this.presencePicture.loading = false;
            this.presencePicture.isFullfilled = true;
            file.value = '';
            this.nextAvailable = this.checkMandatoryFullfilled();
            this.cd.detectChanges();
          }, err => {
            console.log('Error in addImage', err);
            this.showNotification(index);
          });
        }, err => {
          console.log('Error in bucketImage', err);
          this.showNotification(index);
        });
      }, err => {
        console.log('Error in getMediaUrl', err);
        this.showNotification(index);
      });
    });
  }

  updatePage(actualSelfadjust) {
    if (actualSelfadjust.images) {
      actualSelfadjust.images.map(async (e) => {
        if (this.presencePicture.type === e.type) {
          this.presencePicture.image = e.name;
          this.presencePicture.isBGShow = false;
          this.presencePicture.isFullfilled = true;
        }
      });
    }
    if (actualSelfadjust.cancellationReason) {
      this.reason = actualSelfadjust.cancellationReason;
      for (let i = 0; i < this.absenceReasons.length; i++) {
        if (this.absenceReasons[i].result === actualSelfadjust.cancellationReason) {
          this.reason = this.absenceReasons[i].name;
        }
      }
    }
    if (actualSelfadjust.custom_field_4) { // Observaciones
      this.observations = actualSelfadjust.custom_field_4;
    }
  }
  ngAfterContentInit() {
    this.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }
  showNotification(index) {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      if (typeof index === 'number') {
        this.presencePicture.loading = false;
      }
      this.loading = false;
      this.cd.detectChanges();
    }, 5000);
    this.cd.detectChanges();
  }

  next(myForm) {
    const presented = 'Toma de fotos por el asegurado';
    this.loading = true;
    const status = myForm.value.reason === presented ? this.remoteService.selfAdjustService.AppStatus.inprocess : this.remoteService.selfAdjustService.AppStatus.cancelled;
    const nextPage = myForm.value.reason === presented ? this.remoteService.selfAdjustService.nextPage : 'cancelled';
    const action = myForm.value.reason === presented ? 'continue' : 'cancel';
    const data = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      logInfo: {
        component: 'presence-insured',
        action
      },
      status,
      fields2Update: {},
    };

    if (myForm.value.reason !== presented) {
      let reason = myForm.value.reason;
      let observations = myForm.value.observations;

      for (let i = 0; i < this.absenceReasons.length; i++) {
        if (this.absenceReasons[i].name === myForm.value.reason) {
          if (this.absenceReasons[i].result) {
            reason = this.absenceReasons[i].result;
          }
          if (this.absenceReasons[i].observations) {
            observations = this.absenceReasons[i].observations;
          }
        }
      }

      data.fields2Update = {
        cancellationReason: reason,
        custom_field_4: observations,
      };
    }

    this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(() => {
      console.log('INFO: Navega a:', nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      if (myForm.value.reason === presented) {
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {
          secretKey: this.remoteService.selfAdjustService.secretKey
        }]);
      } else {
        this.router.navigate([nextPage, { secretKey: this.remoteService.selfAdjustService.secretKey }]);
      }
    }, err => {
      console.log('Error in selfadjust-update', err);
      this.showNotification(undefined);
    });

  }

  back() {
    console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage,
      'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    this.router.navigate([...this.remoteService.selfAdjustService.backPage, { secretKey: this.remoteService.selfAdjustService.secretKey }]);
  }

}
