import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, OnDestroy, AfterViewInit } from "@angular/core";
import { RemoteLibraryService } from "remote-library";
import { Subject, Subscription } from "rxjs";
import { take, skip } from "rxjs/operators"
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";




@Component({
  selector: "app-damage-pictures",
  templateUrl: "./damage-pictures.component.html",
  styleUrls: ["./damage-pictures.component.scss"]
})
export class DamagePicturesComponent implements OnInit, AfterViewInit, OnDestroy {
  numImages: number;
  @Output() onChangedNumImgDmg = new EventEmitter<boolean>();
  public pictureDatas: any = [];
  public notfication = new Subject();
  public reader: FileReader = new FileReader();
  public data;
  actualSelfadjust;
  loading: boolean = false;
  loaded: boolean = false;
  damagePictures
  openNotification: Boolean = false;
  msgUploading: string;
  nextAvailable: Boolean = false;
  unfixed: boolean = false;
  formUnfixed: boolean = false;
  imgUnfixed: boolean = false;
  unfixedArray: boolean[];
  photosQuantity: number;
  @ViewChild('myForm')
  public myForm: NgForm;
  private formSub: Subscription;
  private compareReopenInfo;

  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.damagePictures = this.remoteService.selfAdjustService.myPage('damage-pictures');
    this.nextAvailable = !this.damagePictures.mandatory;
    this.numImages = 0;
    if (this.damagePictures.damagePictures) {
      this.pictureDatas = this.damagePictures.damagePictures;
    }
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.updatePage(this.actualSelfadjust);
  }

  ngAfterViewInit(){
    if (this.damagePictures.fixed !== undefined && !this.damagePictures.fixed) {
      this.unfixedArray = [];
      this.unfixed = !this.remoteService.selfAdjustService.isPageFixed('damage-pictures');
      for (let img of this.pictureDatas) {
        for (let data of this.actualSelfadjust.images) {
          if (img.type === data.type) {
            this.unfixedArray.push(!img.fix);
          }
          if (img.fix && !this.imgUnfixed) {
            this.imgUnfixed = true;
          }
        }
      }
      if (this.damagePictures.selfadjustFields) {
        this.reopenFormFields();
        this.formSub = this.myForm.valueChanges.pipe(skip(1)).subscribe((element) => {
          this.isFormFixed(element); 
        })
      }
    }
  }

  loadDynamicStyles(customStylesFolder){
    if (customStylesFolder) {
      if (customStylesFolder === 'Banorte' || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-damage-pictures')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }
  
  clickForced(name, event) {
    if (event.isTrusted == false || event.target.className == "upImg" || event.target.className == "damageImg") {
      document.getElementById(`${name}`).click();
    }
  }

  async imageUpload(file, imageType, index) {
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    this.pictureDatas[index].loading = true;
    this.cd.detectChanges();
    this.remoteService.selfAdjustService.resizeAndDraw(file, 1280).then(result => {
      let newImg = {
        name: result,
        type: imageType
      }
      let contentType = result.slice(result.indexOf("image/"),result.indexOf(";"));
      let extension = contentType.split("image/")[1];
      const caption = this.pictureDatas[index].name || 'Damage';
      this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust.id, extension).pipe(take(1)).subscribe(media => {
        this.remoteService.selfAdjustService.bucketImage(result, media['media_url'],contentType).pipe(take(1)).subscribe(response => {
          let logInfo = {
            component: 'damage-pictures',
            action: 'push-image'
          }
          this.remoteService.selfAdjustService.addImage(this.actualSelfadjust.id, this.actualSelfadjust['securityKey'],
          media['media_id'], imageType, extension, logInfo, coordinates, caption).subscribe(e => {
            this.remoteService.selfAdjustService.updateLocalImages(newImg);
            this.pictureDatas[index].image = result;
            this.pictureDatas[index].isBGShow = false;
            delete this.pictureDatas[index].fix;
            this.numImages = this.numImages + 1;
            this.pictureDatas[index].loading = false;
            if (this.damagePictures.mandatory) {
              if (this.numImages < this.photosQuantity) {
                this.nextAvailable = false;
              } else {
                this.nextAvailable = true;
              }
            }
            if (this.damagePictures.fixed != undefined && !this.damagePictures.fixed) {
              this.isFixed(index);
            }
            file.value = "";
            this.cd.detectChanges();                
          },
          err => {
            console.log("Error in addImage",err);
            this.showNotification(index);
          });
        },
        err =>{
          console.log("Error in bucketImage",err);
          this.showNotification(index);
        })
      },
      err =>{
        console.log("Error in getMediaUrl",err);
        this.showNotification(index);
      })
    });
  }

  isFixed(index){
    if (this.remoteService.selfAdjustService.isPageFixed('damage-pictures')) {
      this.imgUnfixed = false;
    } else {
      this.unfixedArray[index] = true;
      if (!this.unfixedArray.includes(false)) {
        this.imgUnfixed = false;
      }
    }
    if (this.imgUnfixed || this.formUnfixed) {
      this.unfixed = true;
    } else {
      this.unfixed = false;
    }
  }

  reopenFormFields(){
    this.compareReopenInfo = {};
    if (this.damagePictures.selfadjustFields) {
      for (let ele of this.damagePictures.selfadjustFields) {        
        if (ele.fix && !this.formUnfixed) {
          this.compareReopenInfo[`${ele.fieldName}`] = this.actualSelfadjust[`${ele.fieldName}`];
          this.formUnfixed = true;
        } else {
          this.compareReopenInfo[`${ele.fieldName}`] = null;
        }
      }
    }
  }

  isFormFixed(val) {
    if (this.remoteService.selfAdjustService.isPageFixed('damage-pictures')) {
      this.formUnfixed = false;
    } else {
      for (let key in val) {
        if (val[key] === this.compareReopenInfo[key]) {
          this.formUnfixed = true;
          break;
        } else {
          this.formUnfixed =false;
        } 
      }
    }
    if (!(this.imgUnfixed || this.formUnfixed)) {
      this.unfixed = false;
    } else {
      this.unfixed = true;
    }
  }

  updatePage(actualSelfadjust) {
    this.photosQuantity = this.damagePictures.photosQuantity ? this.damagePictures.photosQuantity : 4;
    this.fillPictureDatas();
    if (actualSelfadjust.images) {
      actualSelfadjust.images.map(e => {
        this.pictureDatas.forEach(element => {
          if(element.type == e.type){
            element.image = e.name;
            element.isBGShow = false;
            element.name = 'Damage';
            element.name_es = 'Damage';
            this.numImages++;
          }
          if(element.fix && !this.remoteService.selfAdjustService.isPageFixed('damage-pictures')){
            element.image = null;
            element.isBGShow = true;
          }
        }) 
      });
    }
    if (this.damagePictures.mandatory) {
      if (this.numImages < this.photosQuantity) {
        this.nextAvailable = false;
      } else {
        this.nextAvailable = true;
      }
    }
    if (this.myForm) {
      this.myForm['_directives'].forEach(e => {
        if (actualSelfadjust[e.name]) {
          e._updateValue(actualSelfadjust[e.name]);
        }
      });
    }
    this.loading = false;
    this.loaded = true;
  }

  fillPictureDatas() {
    let aux = this.damagePictures.photosQuantity - this.pictureDatas.length;
    const pathDefaultCameraImg = (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') ? '/assets/images/logo/camera_new.svg' : '/assets/images/logo/camera.svg';

    const cameraLogoImg = this.remoteService.selfAdjustService.logo_camera ? this.remoteService.selfAdjustService.logo_camera : pathDefaultCameraImg;
      while (aux > 0) {
        let num = this.pictureDatas.length + 1;
        this.pictureDatas.push({
          id: `image${num}`,
          name: 'Damage',
          name_es: 'Damage',
          type: `damage_${num}`,
          image: null,
          bgImage: cameraLogoImg,
          isBGShow: true,
          loading: false 
        })
        aux--;
      }
  }

  movePage(action) {
    this.loading = true;
    if (action == "next") {
      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'damage-pictures',
          action: 'continue'
        },
        fields2Update: {}
      };
      if(this.damagePictures.selfadjustFields){
        this.myForm['_directives'].forEach( (e) => {
          data.fields2Update[e.name] = e.value;
        })
      }
      if (this.damagePictures.fixed !== undefined && !this.damagePictures.fixed) {
        this.remoteService.selfAdjustService.fixedPage('information-form');
      }
      if(this.remoteService.selfAdjustService.getReopenInfo() && this.damagePictures.fixed != undefined){
        data.fields2Update['reopenInfo'] = { pages: null };
        data.fields2Update['reopenInfo'].pages = this.remoteService.selfAdjustService.getReopenInfo()
      }
      this.remoteService.selfAdjustService.pushData(data).subscribe( (res) => {
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
      },
      err=>{
        console.log("Error in selfadjust-update",err);
        let index = undefined;
        this.showNotification(index);
      });
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }

  showNotification(index) {
    this.openNotification = true;
    setTimeout(()=>{
      this.openNotification = false;
      if (typeof index === 'number') this.pictureDatas[index].loading = false;
      this.loading = false;
      this.cd.detectChanges();
    },5000)
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }
}
