import { Component, OnInit } from '@angular/core';
import { ISelfadjustError } from 'projects/remote-library/src/interfaces/selfadjust-error.interface';
import { RemoteLibraryService } from 'remote-library';

@Component({
  selector: 'app-no-camera-error',
  templateUrl: './no-camera-error.component.html',
  styleUrls: ['./no-camera-error.component.scss'],
})
export class NoCameraErrorComponent implements OnInit {
  public error: ISelfadjustError = {
    icon: '../../assets/images/error/shared/camera.svg',
    name: 'Camera not found',
    description: [
      'Sorry, we were unable to access the camera on this device to continue the process.',
      'Do you have another mobile phone at hand? Try completing the process from it by sending this link to yourself.',
    ],
    button: 'Copy link',
  };

  constructor(private remoteService: RemoteLibraryService) {}

  ngOnInit() {}

  public copy(link: string) {
    (window.navigator as any).clipboard.writeText(link);
  }
}
