import { Component, OnInit, ViewChild, NgZone,ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { RemoteLibraryService } from 'remote-library';
import { of, Subject, Subscription } from "rxjs";
import { catchError, take, skip } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-signature-page',
  templateUrl: './signature-page.component.html',
  styleUrls: [
    './signature-page.component.scss'
  ]
})

export class SignaturePageComponent implements OnInit, OnDestroy {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  selfAdjustInfo
  loading: Boolean = false;
  loaded: Boolean = false;
  openNotification: Boolean = false;
  msgUploading: string;
  public openURLError = false;
  public formSubmited = false;
  padWidth: any;
  signaturePage
  nextAvailable:boolean = false;
  public signaturePadOptions: Object;
  public notfication = new Subject();
  @ViewChild('myForm')
  public myForm: NgForm;
  compareReopenInfo;
  unfixed = false;
  formSub: Subscription;
  
  constructor(
    private router: Router,
    public remoteService:RemoteLibraryService,
    public zone: NgZone,
    public cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.signaturePage = this.remoteService.selfAdjustService.myPage('signature');
    this.nextAvailable = !this.signaturePage.mandatory;
    this.selfAdjustInfo = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.updatePage(this.selfAdjustInfo);
    this.msgUploading = this.remoteService.selfAdjustLanguageService.strLang('Error in image upload. Please, try again.');
    this.resizeSignaturePad();
    this.remoteService.selfAdjustService.removeIndexLoader();
    if(this.signaturePage.selfAdjustFields && this.signaturePage.fixed != undefined && !this.signaturePage.fixed){
      this.unfixed = !this.remoteService.selfAdjustService.isPageFixed('signature')
      this.reopenInputFields();
      this.formSub = this.myForm.valueChanges.subscribe((element) => {
        this.isFixed(element);
      })
    }
  }

  isFixed(val) {
    if (this.remoteService.selfAdjustService.isPageFixed('signature')) {
      this.unfixed = false;
    } else {
      for (let key in val) {
        if (val[key] == this.compareReopenInfo[key]) {
          this.unfixed = true;
          break;
        } else {
          this.unfixed =false
        } 
      }
    }
  }

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      if(customStylesFolder === 'Banorte' || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-signature-page')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  updatePage(selfAdjustInfo) {
    if (this.myForm) {
      this.myForm['_directives'].forEach(e => {
        if (selfAdjustInfo[e.name]) {
          e._updateValue(selfAdjustInfo[e.name]);
        }
      });
    }
    this.loaded = true;
  }

  resizeSignaturePad() {
    this.padWidth = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;
    let mobileMaxWidth = 380;
    this.padWidth = this.padWidth < mobileMaxWidth ? this.padWidth : mobileMaxWidth;
    this.padWidth = this.padWidth - 40;
    console.log("padWidth",this.padWidth)
    this.signaturePadOptions = {
    'minWidth': 1,
    'maxWidth': 2,
    'canvasWidth': this.padWidth,
    'canvasHeight': 230,
    'backgroundColor': 'rgba(95, 192, 249, 0.1)'
    };
  }

  clearCanvas() {
    this.signaturePad.clear();
    this.nextAvailable = false;
  }

  async onFormSubmit(){
    this.loading = true
    this.cd.detectChanges()
    this.signaturePad.off();
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    const imageSignature = this.signaturePad.toDataURL();
    this.remoteService.selfAdjustService.getMediaUrl(this.selfAdjustInfo.id, 'png').pipe(take(1)).subscribe(media=>{
        this.remoteService.selfAdjustService.bucketImage(imageSignature, media['media_url'],'image/png').pipe(take(1)).subscribe(response=>{
          let logInfo = {
            component: 'signature-page',
            action: 'push-image'
          }
          const caption = 'signature';        
          this.remoteService.selfAdjustService.addImage( this.selfAdjustInfo.id, this.selfAdjustInfo['securityKey'], media['media_id'], 'signature', 'png', logInfo, coordinates, caption).pipe(take(1)).subscribe(e=>{
            const formData = {
              security_key: this.remoteService.selfAdjustService.secretKey,
              status: this.signaturePage.reopen ? this.remoteService.selfAdjustService.AppStatus.reopened : this.remoteService.selfAdjustService.AppStatus.inprocess,
              fields2Update: {}
            };
            if(this.signaturePage.selfadjustFields){
              this.myForm['_directives'].forEach(e=>{
                formData.fields2Update[e.name] = e.value
              })
            }
            this.remoteService.selfAdjustService.pushData(formData).pipe(take(1)).subscribe(result => {
                this.formSubmited = true;
                this.movePage('next')
            })
            catchError((err) => {
                const str = this.remoteService.selfAdjustLanguageService.strLang('Ooops, something went wrong');
                this.notfication.next({ message: str });
                return of(err)
            });
        },
        err=>{
          console.log("Error in addImage",err)
          this.showNotification()
        })
      },
      err=>{
        console.log("Error in bucketImage",err)
        this.showNotification()
      })
    },
    err=>{
      console.log("Error in getMediaUrl",err)
      this.showNotification()
    })
  }

  showNotification() {
    this.openNotification = true;
    setTimeout(()=>{
      this.openNotification = false
      this.loading = false;
      this.cd.detectChanges() 

    },5000)
    this.cd.detectChanges() 
  }
  
  drawStart() {
    this.nextAvailable = true;
  }

  movePage(action){
    if (action=='next') {
      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'signature-page',
          action: 'continue'
        },
        fields2Update: {}
      };
      this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe( (res) => {
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
      },
      err=>{
          console.log("Error in selfadjust-update",err);
          this.showNotification();
      });
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }

  reopenInputFields(){
    this.compareReopenInfo = {};
    if(this.signaturePage.selfadjustFields){
      for(let ele of this.signaturePage.selfadjustFields) {
          this.compareReopenInfo[`${ele.fieldName}`] = this.selfAdjustInfo[`${ele.fieldName}`];
      }
    }
  }

  ngOnDestroy() {
    if(this.formSub){
      this.formSub.unsubscribe()
    }
    console.log('Destroying SignaturePage Component!');
  }
}
