import { Component, OnInit } from '@angular/core';
import { ISelfadjustError } from 'projects/remote-library/src/interfaces/selfadjust-error.interface';

@Component({
  selector: 'app-permission-camera-error',
  templateUrl: './permission-camera-error.component.html',
  styleUrls: ['./permission-camera-error.component.scss'],
})
export class PermissionCameraErrorComponent implements OnInit {
  public error: ISelfadjustError = {
    icon: '../../assets/images/error/shared/camera.svg',
    name: 'Camera permissions denied',
    description: [
      'Sorry, you cannot continue without accepting camera permissions.',
      'Please follow the steps below to fix this:',
    ],
    steps: {
      android: [
        {
          description: 'Click on the lock icon in your browser.',
          image: '../step1.png',
        },
        {
          description: 'Click on \"permissions\".',
          image: 'step2.png',
        },
        {
          description: 'A drop-down will open with a list of available permissions. Click on "camera".',
          image: 'camera/step3.png',
        },
        {
          description: 'Click outside the drop-down to hide the actions again and start the process again.',
        },
      ],
      ios: [
        {
          description: 'Click on the AA icon in your browser.',
          image: '../step1.png',
        },
        {
          description: 'Click on "website settings".',
          image: 'step2.png',
        },
        {
          description: 'Click on "camera" to grant permissions',
          image: 'camera/step3.png',
        },
        {
          description: 'Click on "microphone" and grant permissions',
          image: 'camera/step4.png',
        },
        {
          description: 'Click "ok" to hide the actions again and start the process again.',
        },
      ],
    },
    button: 'Continue',
  };

  constructor() {}

  ngOnInit() {}

  public continue(link: string) {
    window.location = link as any;
  }
}
