import { Component, OnInit } from '@angular/core';

import { ISelfadjustError } from 'projects/remote-library/src/interfaces/selfadjust-error.interface';

@Component({
  selector: 'app-permission-location-error',
  templateUrl: './permission-location-error.component.html',
  styleUrls: ['./permission-location-error.component.scss'],
})
export class PermissionLocationErrorComponent implements OnInit {
  public error: ISelfadjustError = {
    icon: '../../assets/images/error/shared/location.svg',
    name: 'Location permissions denied',
    description: [
      'Sorry, you cannot continue without accepting location permissions.',
      'Please follow the steps below to fix this:',
    ],
    steps: {
      android: [
        {
          description: 'Click on the lock icon in your browser.',
          image: '../step1.png',
        },
        {
          description: 'Click on \"permissions\".',
          image: 'step2.png',
        },
        {
          description: 'A drop-down will open with a list of available permissions. Click on "location".',
          image: 'location/step3.png',
        },
        {
          description: 'Click outside the drop-down to hide the actions again and start the process again.',
        },
      ],
      ios: [
        {
          description: 'Click on the AA icon in your browser.',
          image: '../step1.png',
        },
        {
          description: 'Click on "website settings".',
          image: 'step2.png',
        },
        {
          description: 'Click on "Location" to grant permissions',
          image: 'location/step3.png',
        },
        {
          description: 'Click "ok" to hide the actions again and start the process again.',
        },
      ],
    },
    button: 'Continue',
  };
  constructor() {}

  ngOnInit() {}

  public continue(link: string) {
    window.location = link as any;
  }
}
