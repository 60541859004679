import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { RemoteLibraryService } from 'remote-library';

@Injectable({
    providedIn: 'root'
})
export class BlockedGuard implements CanActivate {

    constructor(private router: Router,
                private remoteLibraryService: RemoteLibraryService) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const can = !this.remoteLibraryService.selfAdjustService.blocked;

        if (!can) {
            this.router.navigateByUrl('/blocked');
        }
        
        return can;
    }
}