import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';


@Component({
  selector: 'app-warning-device',
  templateUrl: './warning-device.component.html',
  styleUrls: ['./warning-device.component.scss'],
})
export class WarningDeviceComponent implements OnInit, AfterViewInit {

  companyName: any;
  public showLegalFooter = false;
  public cookiesPolicy = false;

  constructor(
    public remoteService: RemoteLibraryService,
  ) {
    this.companyName = this.remoteService.selfAdjustService.actualSelfAdjust.company_name;
  }

  ngOnInit() {
    this.remoteService.selfAdjustService.changeLabels();
    this.remoteService.selfAdjustLanguageService.browserLanguage();
    const { cookiesPolicy = {}, showLegalFooter } = this.remoteService.selfAdjustService.actualCompany;
    this.cookiesPolicy = this.remoteService.commonService.showCookiesModal(cookiesPolicy);
    this.showLegalFooter = showLegalFooter;
  }

  ngAfterViewInit() {
    this.remoteService.selfAdjustService.removeIndexLoader();
    WarningDeviceComponent.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }

  /**
     * @description Función para carga de estilos personalizados
     * @param customStylesFolder Nombre de la carpeta donde están los estilos personalizados
     */
   private static loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
        console.log('Error loading the dynamic styles');
      }
    }
  }
}
