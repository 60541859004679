import { Component, OnInit, AfterContentInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancelled-view',
  templateUrl: './cancelled-view.component.html',
  styleUrls: ['./cancelled-view.component.scss']
})
export class CancelledViewComponent implements OnInit, AfterContentInit {
  
  selfAdjustInfo: any;
  public showLegalFooter = false;
  public cookiesPolicy = false;

  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router
  ) {}

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.selfAdjustInfo = this.remoteService.selfAdjustService.actualSelfAdjust;
    const { cookiesPolicy = {}, showLegalFooter } = this.remoteService.selfAdjustService.actualCompany;
    this.showLegalFooter = showLegalFooter;
    this.cookiesPolicy = this.remoteService.commonService.showCookiesModal(cookiesPolicy);
  }

  ngAfterContentInit() {
    this.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
        console.log('Not found folder customStyle', customStylesFolder);
      }
    }
  }
}
