import { Injectable } from '@angular/core';
import { environment } from '../../../remote-library/src/environments/environment';
import { Http } from '@angular/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RemoteLibraryService } from 'remote-library';

@Injectable({
  providedIn: 'root'
})
export class StatusGuard implements CanActivate {

  statusList = this.remoteService.selfAdjustService.AppStatus;

  private statusByComponent = {
    '': [
      this.statusList.start,
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'landing': [
      this.statusList.start,
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'vehicle-type': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'information-form': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'moto-parts': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'car-parts': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'vehicle-parts': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'damage-pictures': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'vehicle-side-pictures': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'presence-insured': [
      this.statusList.inprocess
    ],
    'side-pictures': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'moto-side-pictures': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'side-pictures-ai': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'moto-side-pictures-ai': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'truck-side-pictures-ai': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'truck-side-pictures': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'document-pictures': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'signature': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'damage-question': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'feedback': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'final': [
      this.statusList.final,
      this.statusList.review
    ],
    'repaircenters': [
      this.statusList.review
    ],
    'use-conditions': [
      this.statusList.start,
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'cancelled': [
      this.statusList.cancelled
    ],
    'claim-damages': [
      this.statusList.inprocess,
      this.statusList.reopened
    ],
    'environmental-capture': [
      this.statusList.inprocess
    ],
    'document-files': [
      this.statusList.inprocess
    ],
    'information-page': [
      this.statusList.inprocess
    ],
  }

  constructor(public http: Http, private remoteService: RemoteLibraryService, private router: Router){

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('Triggered StatusGuard...');
    return new Promise(async (resolve) => {

      if (!this.remoteService.selfAdjustService.actualSelfAdjust) {
        return resolve(true);
      }

      const body = {
        security_key: this.remoteService.selfAdjustService.secretKey,
      };
      try {
        const response = await this.http.post(`${environment.baseUrl}/selfadjust-get`, body).toPromise();
        const { entity: { status } = { status: undefined } } = response.json();
        const { AppStatus } = this.remoteService.selfAdjustService;
        if (status === AppStatus.cancelled) {
          this.remoteService.selfAdjustService.actualSelfAdjust.status = status
        }
  
        const component = next.routeConfig.path === ':order' ? next.children[0].routeConfig.path : next.routeConfig.path;
  
        if ([AppStatus.inprocess, AppStatus.reopened].includes(status)) {
          this.remoteService.selfAdjustService.endSelfadjust(component);
        }
  
        if (!this.statusByComponent[component]) {
          return resolve(false);
        }

        resolve(this.statusByComponent[component].includes(status) || this.redirectSelfadjust(status as number));
      } catch(e) {
        console.log('Error in status guard: ', e);
        resolve(this.redirectSelfadjust());
      }
    });
  }

  private redirectSelfadjust = function(actualStatus?: number) {
    const status = actualStatus || this.remoteService.selfAdjustService.actualSelfAdjust.status;
    if (status === this.remoteService.selfAdjustService.AppStatus.start) {
      console.log('INFO: Navega a: landing con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate(['landing', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
    } else if (status === this.remoteService.selfAdjustService.AppStatus.final) {
      console.log('INFO: Navega a: final con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate(['final', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
    } else if (status === this.remoteService.selfAdjustService.AppStatus.review) {
      if (this.remoteService.selfAdjustService.actualSelfAdjust.repair_centers) {
        console.log('INFO: Navega a: repaircenters con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate(['repaircenters', {secretKey: this.remoteService.selfAdjustService.secretKey}]);
      } else {
        console.log('INFO: Navega a: final con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate(['final', {secretKey: this.remoteService.selfAdjustService.secretKey}]);
      }
    } else if (status === this.remoteService.selfAdjustService.AppStatus.cancelled) {
      console.log('INFO: Navega a: cancelled con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate(['cancelled', { secretKey: this.remoteService.selfAdjustService.secretKey }])
    }
    console.log('No se redirecciona');
    return false;
  };
}
