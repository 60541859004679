import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';
import { take } from "rxjs/operators";
import * as ia from '../../aiHandlers/motoHandler.js';
import { ImageValidation } from 'projects/remote-library/src/interfaces/selfadjust/ImageValidation.interfaces.js';

declare var window: Window & { screen: any };

@Component({
  selector: 'app-moto-side-pictures',
  templateUrl: './moto-side-pictures.component.html',
  styleUrls: [
    './moto-side-pictures.component.scss'
  ]
})
export class MotoSidePicturesComponent implements OnInit {

  public pictureDatas: any = [
    { 
      id: 'front', name: 'Front', name_es: 'Parte delantera', type: 'front', image: null,
     bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newFront', uploadOK: true},
    { 
      id: 'rear', name: 'Rear', name_es: 'Parte trasera', type: 'rear', image: null, 
     bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newRear', uploadOK: true}, 
    { 
      id: 'left', name: 'Left', name_es: 'Lat. izquierdo', type: 'left', image: null,
     bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newLeft', uploadOK: true}, 
    { 
      id: 'right', name: 'Right', name_es: 'Lat. derecho', type: 'right', image: null,
      bgImage: '../../assets/images/logo/camera.svg', isBGShow: true, newImg: 'newRight', uploadOK: true}, 
  ];

  autoDetection: any;
  authPhoto: Boolean = false;
  canvasWidth: number;
  canvasHeight: number;
  canvasForHDImage: any;
  context: CanvasRenderingContext2D;
  currentImageType: string;
  currentImageIndex: number;
  enableCamera: Boolean = false;
  infoModalBright: Boolean = false;
  intervalTime: number = 1000;
  isIos: Boolean = false;
  isModalOpen = 0;
  msg: string = '';
  luminosity = {
    bright: false,
    dark: false
  }

  photoType: string;
  object: string = '';
  openNotification: Boolean = false;
  photo2Detect: any;
  photo2DetectCtx: any;
  preview: any;
  stream2Delete: any;
  actualSelfadjust;
  motoSidePictures
  supportWebGL2: Boolean;
  vertical: Boolean = true;
  video: any;
  loaded: Boolean = false;
  firstTime: Boolean = true;
  oneTime: number = 0;
  companyEnableIA: Boolean;
  viewPhoto: Boolean = false;
  isNotPart: Boolean = false; // controla si la imagen tomada es incorrecta, no pertenecea a la parte que se pide
  forceIsPart: Boolean = false; //  fuerza a mostrar el mensaje para que puedas aceptar la foto aunque sea incorrecta
  imageValidity: ImageValidation;
  errorMessage: string;
  loadingCameraAndAI: Boolean = false;
  tryIAFlag: Boolean = true;
  motoPartDetection: Boolean = true;
  motoPartBlocker: Boolean = true;
  loading: Boolean;
  motoParts = {
    'front':'front',
    'rear':'rear',
    'left': 'left',
    'right': 'right',
  }
  reopened: boolean = false;
  unfixed: boolean = false;
  unfixedArray: boolean[];
  attempts:number = 0;
  backCameras: any;
  deviceRotationSrc: string = this.remoteService.selfAdjustService.rotateDevice || "../../assets/images/gifgirar.gif";

  private forceImgValidityParts: { [key: string]: boolean } = {};

  private orientation = {
    get: () => {
      let result: 'portrait' | 'landscape';

      try {
        result = window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape';
      } catch(e) {
        // Older versions of iOS trigger event at start and is still portrait when changing to landscape
        result = !window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape';
      }

      return result;
    },
    set: () => {
      setTimeout(() => {
        this.vertical = this.orientation.get() === 'portrait';
      }, 150);
      setTimeout(() => {
        this.video = document.getElementById('videoElement');
        this.video.srcObject = this.stream2Delete
      }, 200);
    }
  }


  constructor(
   public remoteService: RemoteLibraryService,
   public router: Router,
   public cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.isIos = navigator.userAgent.indexOf('iPhone') >= 0;
    this.supportWebGL2 = this.detectWebGLContext() || this.isIos;
    this.motoSidePictures = this.remoteService.selfAdjustService.myPage('moto-side-pictures');
    this.companyEnableIA = (localStorage.getItem('enableIA') != undefined || this.remoteService.selfAdjustService.actualCompany.enableIA) ? true : false;
    if (!this.supportWebGL2 || !this.companyEnableIA) {
      console.log('INFO: Navega a:',this.remoteService.selfAdjustService.actualPage.value, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    } else {
      this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    }
  }

  ngAfterContentInit() {
    this.loadModels().then(async() => {
      const stream = await this.remoteService.nativeCameraService.autoInitVideo(false, this.backCameras, this.attempts);
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.backCameras = this.remoteService.selfAdjustService.filterBackCameras(devices);
      });
      this.stream2Delete = stream;
      window['stream'] = stream;
      this.video = document.getElementById('videoElement');
      this.video.srcObject = stream;

      this.remoteService.nativeCameraService.on.stream.subscribe((stream) => {
        this.stream2Delete = stream;
        window['stream'] = stream;
        this.video.srcObject = stream;
      });
    });
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    /* if (this.motoSidePictures.pictureDatas) {
      this.pictureDatas = this.motoSidePictures.pictureDatas;
    } */
    this.motoPartDetection = this.motoSidePictures.motoPartDetection === false ? this.motoSidePictures.motoPartDetection : true;
    this.motoPartBlocker = this.motoSidePictures.motoPartBlocker === false ? this.motoSidePictures.motoPartBlocker : true;
    this.canvasWidth = screen.width < screen.height ?  (screen.width)*16/9 : (screen.height)*16/9;
    this.canvasHeight = screen.width < screen.height ? screen.width : (screen.height);
    this.orientation.set();

    try {
      window.screen.orientation.addEventListener('change', this.orientation.set, false);
    } catch(e) {
      window.addEventListener('orientationchange', this.orientation.set, false);
    }
    this.canvasForHDImage = document.createElement('canvas');
    this.photo2Detect = document.createElement("canvas");
    this.photo2DetectCtx = this.photo2Detect.getContext('2d');
    this.photo2Detect.width = this.canvasWidth;
    this.photo2Detect.height = this.canvasHeight;
    this.loaded = true;
  }

  ngAfterContentChecked() {
    if (this.actualSelfadjust && this.oneTime < 1) {
      if (this.actualSelfadjust.reopenInfo && this.motoSidePictures.sidePictures) {
        this.reopened = true
        this.unfixedArray = []
        this.unfixed = this.remoteService.selfAdjustService.isPageFixed('moto-side-pictures') ? false : true;    
        for(let data of this.motoSidePictures.sidePictures){
          for(let img of this.pictureDatas){
            if(data.type == img.type){
              img.fix = data.fix
              this.unfixedArray.push(!data.fix)
            }
          }
        }
      }
      this.updatePage();
      this.oneTime++;
    }
  }

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  async predictMotoPart(image) {
    const prediction = await ia.predictMotoPart(image);
    return prediction;
  }

  clickForced(carimage, event) {
    if(carimage.fix == undefined || carimage.fix == true){
      if (event.isTrusted === false || event.target.className === 'upImg') {
        document.getElementById(`${carimage.newImg}`).click();
      }
    }
  }

  closeCamera() {
    clearInterval(this.autoDetection);
    this.cameraControl(false)
  }

  switchCamera() {
    this.remoteService.nativeCameraService.switchCamera(this.video, this.stream2Delete, this.backCameras);
  }

  detectWebGLContext() {
    var canvas = document.createElement("canvas");
    var gl = canvas.getContext("webgl2");
    if (gl) {
      return true;
    } else {
      return false;
    }
  }

  isFixed(index){
    console.log('index',index)
    this.unfixedArray[index] = true;
    if(!this.unfixedArray.includes(false)){
      this.unfixed = false
      this.remoteService.selfAdjustService.fixedPage('side-pictures')
    }
  }

  somoPhoNotTaken() {
    if (!this.motoSidePictures.mandatory) {
      return false;
    }
    return this.pictureDatas.some((picture) => {
      return !picture.image;
    });
  }

  somePhotoLoading() {
    return this.pictureDatas.some((picture) => {
      return picture.loading;
    });
  }

  somePhotoUploadingError() {
    return this.pictureDatas.some((picture) => {
      return !picture.uploadOK;
    });
  }

  movePage(action) {
    this.loading = true;
    if (action == 'next') {
      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'side-pictures',
          action: 'continue'
        },
        fields2Update: {}
      };
      if(this.remoteService.selfAdjustService.getReopenInfo() && this.motoSidePictures.fixed != undefined){
        data.fields2Update['reopenInfo'] = { pages: null };
        data.fields2Update['reopenInfo'].pages = this.remoteService.selfAdjustService.getReopenInfo()
      }
      this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe( (res) => {
        this.remoteService.selfAdjustService.toggleFullscreen(false);
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
      },
      err=>{
        console.log("Error in selfadjust-update",err);
        let index = undefined;
        this.showNotification(index);
      });   
    } else {
      this.remoteService.selfAdjustService.toggleFullscreen(false);
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }

  showNotification(index) {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      if (typeof index === 'number') this.pictureDatas[index].uploadOK = false
      this.loading = false;
      this.cd.detectChanges();
    }, 5000);
    this.cd.detectChanges();
  }

  async acceptPhoto() {
    this.viewPhoto = false;
    this.errorMessage = undefined;
    this.closeCamera();
    const image = new Image();
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    image.src = this.canvasForHDImage.toDataURL('image/jpeg', this.remoteService.nativeCameraService.quality.compression);
    image.onload = () => {
      this.imageUpload(image.src, this.currentImageType, this.currentImageIndex, coordinates);
    };
  }

  discardPhoto() {
    this.isNotPart = false;    
    this.viewPhoto = false;
    this.errorMessage = undefined;
    this.detectPhoto()
  }

  updatePage() {
    if (this.actualSelfadjust.images) {
      this.actualSelfadjust.images.map(e => {
        this.pictureDatas.forEach(element => {
          if(element.type == e.type){
            element.image = e.name;
            element.isBGShow = false;
          }
          if(element.fix && !this.remoteService.selfAdjustService.isPageFixed('side-pictures')){
            delete element.image;
            element.isBGShow = true
          }
        }) 
      });
    }
  }

  detectPhoto() {
    this.infoModalBright = true;
    this.autoDetection = setInterval(() => {
      this.photo2DetectCtx.drawImage(this.video, 0, 0, this.canvasWidth, this.canvasHeight);
      var data = this.photo2DetectCtx.getImageData(0,0,this.photo2Detect.width,this.photo2Detect.height).data;
      var colorSum = 0;
      for(var x = 0, len = data.length; x < len; x+=4) {
        colorSum += Math.floor(0.299*data[x]+0.587*data[x+1]+0.114*data[x+2]);
      }
      var brightness = Math.floor(colorSum / (this.photo2Detect.width*this.photo2Detect.height));

      this.luminosity.bright = brightness > 200;
      this.luminosity.dark = brightness < 80;

      this.authPhoto = true;
    },this.intervalTime)
  }

  getInformation() {
    return {
      warning: {
        skipped: {
          isWrongZone: this.isNotPart,
          isTransparent: this.imageValidity.isTransparent,
          isBlack: this.imageValidity.isBlack,
          isIOSError: this.imageValidity.isIOSError,
        },
        luminosity: this.luminosity,
      },
    };
  }

  imageUpload(image, imageType, index, coordinate?) {
    let oldImage;
    if (this.pictureDatas[index]) oldImage = this.pictureDatas[index].image;
    const information = this.getInformation();
    this.isNotPart = false;
    this.isModalOpen = 0;
    this.pictureDatas[index].isBGShow = false;
    this.pictureDatas[index].loading = true;
    this.pictureDatas[index].image = image;
    this.cd.detectChanges();
    const contentType = image.slice(image.indexOf('image/'), image.indexOf(';'));
    const extension = contentType.split('image/')[1];
    const caption = this.pictureDatas[index].name;
    let newImg = {
      name: image,
      type: imageType
    }
    this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust.id, extension).pipe(take(1)).subscribe(media => {
      this.remoteService.selfAdjustService.bucketImage(image, media['media_url'], contentType).pipe(take(1)).subscribe(response => {
        this.remoteService.selfAdjustService.addImage(
          this.actualSelfadjust.id,
          this.actualSelfadjust.securityKey,
          media['media_id'],
          imageType,
          extension,
          null,
          coordinate,
          caption).pipe(take(1)).subscribe(() => {
          this.pictureDatas[index].loading = false;
          this.remoteService.selfAdjustService.updateLocalImages(newImg);
          if(this.motoSidePictures.fixed !== undefined && !this.motoSidePictures.fixed) {
            this.isFixed(index);
          }
          // Set upload as OK
          this.pictureDatas[index].uploadOK = true;
          this.cd.detectChanges();
        },
        err => {
          console.log('Error in addImage', err);
          this.showNotification(index);
          if (oldImage) {
            this.pictureDatas[index].image = oldImage;
            this.pictureDatas[index].loading = false;
          }
        });
      },
      err => {
        console.log('Error in bucketImage', err);
        this.showNotification(index);
        if (oldImage) {
          this.pictureDatas[index].image = oldImage;
          this.pictureDatas[index].loading = false;
        }
    });
    },
    err => {
      console.log('Error in getMediaUrl', err);
      this.showNotification(index);
      if (oldImage) {
        this.pictureDatas[index].image = oldImage;
        this.pictureDatas[index].loading = false;
      }
    });
  }

  async loadModels() {
    let loaded: boolean;

    try {
      loaded = await this.remoteService.aiService.ready();
    } catch(e) {
      loaded = false;
    }
    if (!loaded) {
      this.remoteService.aiService.load({ carAI: undefined, motoAI: ia });
      await this.remoteService.aiService.ready();
    }
  }
  
  loadTemplates(imageType, index) {
    this.cameraControl(true);
    this.photoType = this.pictureDatas[index].type;
    this.currentImageType = imageType;
    this.currentImageIndex = index;
    this.cd.detectChanges();
  } /* REVISAR */

  async takePhoto() {
    clearInterval(this.autoDetection);
    this.canvasForHDImage.width = this.video.videoWidth;
    this.canvasForHDImage.height = this.video.videoHeight;
    this.canvasForHDImage.getContext('2d').drawImage(this.video, 0, 0);    
    this.photo2DetectCtx.drawImage(this.video, 0, 0, this.canvasWidth, this.canvasHeight);
    const dataURL = this.photo2Detect.toDataURL('image/jpeg', this.remoteService.nativeCameraService.quality.compression);
    this.preview = dataURL;
    this.imageValidity = await this.remoteService.selfAdjustService.validateImg(dataURL,
      this.photo2DetectCtx, this.canvasWidth, this.canvasHeight);
    if (this.imageValidity.valid) {
      if (this.motoPartDetection) {
        this.predictMotoPart(this.photo2DetectCtx.getImageData(0,0,this.canvasWidth,this.canvasHeight)).then(prediction => {
          if (prediction[0].label != this.currentImageType) {
            this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang(`The ${this.motoParts[this.currentImageType]} part of the vehicle is not correctly shown. Please, take it again.`);
            this.isNotPart = this.motoPartBlocker;
          }
  
          this.forceImgValidity();
          this.viewPhoto = true;
          this.cd.detectChanges();
        })
      } else {
        this.viewPhoto = true;
        this.cd.detectChanges();
      }
    } else {
      this.errorMessage = this.remoteService.selfAdjustLanguageService.strLang(this.imageValidity.errorMessage);
      this.isNotPart = true;
      this.forceImgValidity();
      this.viewPhoto = true;
      this.cd.detectChanges();
    }
  }

  /**
   * @description Esta funcion se encarga de forzar la validacion de imagen en caso de NO ser el primer intento de la foto.
   */
  forceImgValidity() {
    console.log(this.forceImgValidityParts, this.currentImageType, this.forceImgValidityParts[this.currentImageType]);
    //Esta comprobacion asegura que si este flag esta encendido, se aceptara la imagen aunque tenga errores.
    //La segunda parte del AND es para que el error de una imagen mal formada, tipicamente causado porque la camara esta siendo utilizada en otro lado,
    //no cause el forzado de la validacion positiva de la imagen.
    if(this.forceImgValidityParts[this.currentImageType] && this.preview.includes('image')){
      this.forceIsPart = true;
    } else {
      this.forceIsPart = false;
      //En caso contrario, se verifica si la imagen tuvo error para encender el flag para forzar el aceptado en el proximo intento
      if (this.errorMessage) {
        this.forceImgValidityParts[this.currentImageType] = true;
      }
    }
  }

  cameraControl(toggle) {
    if (toggle) {
      this.enableCamera = true;
    } else {
      this.enableCamera = false;
    }
  }

  /**
   * @description Funcion encargada de eliminar referencias externas que causen que el componente no termine su ciclo de vida
   */
  ngOnDestroy(){
    if (this.stream2Delete) {
      this.stream2Delete.getTracks().forEach(track => track.stop());
      window['stream'] = undefined;
      this.video.srcObject = undefined;
    }

    try {
      window.screen.orientation.removeEventListener('change', this.orientation.set);
    } catch(e) {
      window.removeEventListener('orientationchange', this.orientation.set);
    }

    delete this.cd;
    console.log('Destroying Side-Pictures Component!');
  }
}