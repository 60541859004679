import { Component, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-repaircenters-page',
  templateUrl: './repaircenters-page.component.html',
  styleUrls: [
    './repaircenters-page.component.scss',
  ]
})

export class RepairCentersPageComponent implements OnInit {
  public openURLError = false;
  public repairCentersList;
  public allCenters;
  public filteredCenters;
  public selectedCenter;
  public statesList;
  public municipalitiesFiltered;
  public municipalitiesList;
  public providerTypesFiltered;
  public providerTypesList;
  public reportPdf = '';
  public state = '';
  public municipality = '';
  public providerType = '';
  public notificationMessage = '';
  constructor(
    public remoteService: RemoteLibraryService,
    private router: Router
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.reportPdf = this.remoteService.selfAdjustService.actualSelfAdjust.report ? this.remoteService.selfAdjustService.actualSelfAdjust.report : '';
    this.allCenters = this.remoteService.selfAdjustService.actualSelfAdjust.repair_centers;
    this.filteredCenters = [];
    this.municipalitiesFiltered = [];
    this.providerTypesFiltered = [];
    this.statesList = this.getStates();
    this.municipalitiesList = this.getMunicipalities();
    this.providerTypesList = this.getProviderTypes();
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) { }
    }
  }

  getUniqueArray(input: any[]) {
    let uniqueArray = [];
    input.forEach(item => {
      let contained = false;
      uniqueArray.forEach(i => {
        if (i.id === item.id) {
          contained = true;
        }
      });
      if (!contained) {
        uniqueArray.push(item);
      }
    });
    return uniqueArray;
  }

  getUniqueArrayWithStateIdAndMunicipallity(input: any[]) {
    let uniqueArray = [];
    input.forEach(item => {
      let contained = false;
      uniqueArray.forEach(i => {
        if (i.id == item.id && i.stateId == item.stateId && i.municipalityId == item.municipalityId) {
          contained = true;
        }
      });
      if (!contained) {
        uniqueArray.push(item);
      }
    });
    return uniqueArray;
  }

  getStates() {
    const initialStates = this.allCenters.map((state, index, array) => {
      return {
        id: state.stateId,
        name: state.state
      };
    });
    return this.getUniqueArray(initialStates);
  }

  getMunicipalities() {
    const initialMunicipalities = this.allCenters.map((municipallity, index, array) => {
      return {
        id: municipallity.municipalityId,
        name: municipallity.municipality,
        stateId: municipallity.stateId
      };
    });
    return this.getUniqueArray(initialMunicipalities);
  }

  getProviderTypes() {
    const initialProviders = this.allCenters.map((provider, index, array) => {
      return {
        id: provider.providerTypeId,
        name: provider.providerTypeDesc,
        stateId: provider.stateId,
        municipalityId: provider.municipalityId
      };
    });
    return this.getUniqueArrayWithStateIdAndMunicipallity(initialProviders);
  }

  selectCenter(id) {
    this.selectedCenter = this.filteredCenters.find(e => e.providerId === id);
  }

  filterMunicipalities() {
    this.filteredCenters = [];
    this.selectedCenter = undefined;
    this.municipality = '';
    this.providerType = '';
    if (this.state) {
      this.municipalitiesFiltered = this.municipalitiesList.filter( element => this.state == element.stateId );
    }
  }

  filterProviderTypes() {
    this.filteredCenters = [];
    this.selectedCenter = undefined;
    this.providerType = '';
    if (this.municipality && this.state) {
      this.providerTypesFiltered = this.providerTypesList.filter( element => (this.state == element.stateId && this.municipality == element.municipalityId));
    }
  }

  filterCenters() {
    let preparedItems = this.allCenters;
    this.selectedCenter = undefined;
    if (this.state != '' && this.municipality != '' && this.providerType != '') {
      this.filteredCenters = preparedItems
        .filter(e => (e.stateId == this.state && e.municipalityId == this.municipality && e.providerTypeId == this.providerType));
    } else {
      this.filteredCenters = [];
    }
  }

  updateSelfAdjust() {
    // Llamar a selfAdjustUpdate
    const data = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      status: 5,
      fields2Update: {
        selected_center: this.selectedCenter
      },
      logInfo: {
        component: 'repaircenters-form',
        action: 'select-center'
      }
    };
    // TODO: Falta que Chubb confirme que se hace una vez seleccionado el taller
    this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(res => {
      this.notificationMessage = this.remoteService.selfAdjustLanguageService.strLang('Thank you for your information, you can go to the selected repair center; presenting your vehicle and the admission valuation notice sent. You can now close this window.');
    },
    err => {
      console.log('Error actualizando taller seleccionado', err);
      this.notificationMessage = 'Se ha producido un error seleccionado el taller';
    });
  }

  /**
   * @description Función para manejar la apertura de un mapa externo. En iOS abre Apple Maps y en cualquier otro caso intenta abrir Google Maps.
   * De no tener instalado Google Maps, lo abre en el navegador en una pestaña o ventana nueva.
   */
  redirectToMap() {
    let appleMaps = "http://maps.apple.com/?ll=";
    let googleMaps = "https://www.google.com/maps/search/?api=1&query=";
    let latitude = this.selectedCenter.coordinates.latitude;
    let longitude = this.selectedCenter.coordinates.longitude;
    let geoURI;

    if (this.remoteService.selfAdjustService.isIos) {
      geoURI = `${appleMaps}${latitude},${longitude}&q=${this.selectedCenter.providerName}`;
    } else {
      geoURI = `${googleMaps}${latitude},${longitude}`;
    }
    console.log("geoURI",geoURI);
    window.open(geoURI);
  }

}
