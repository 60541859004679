import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';

type Errors = {
  isNotPart: boolean,
  isScreener: boolean,
  isBadLight?: boolean,
  isDirty?: boolean,
  isCropped?: boolean
}
@Component({
  selector: 'app-warning-photo',
  templateUrl: './warning-photo.component.html',
  styleUrls: ['./warning-photo.component.scss']
})
export class WarningPhotoComponent implements OnInit {
  public hidden = false;

  @Input() isNotPart: boolean;
  @Input() errors: Errors = { isNotPart: false, isScreener: false };
  @Input() errorMessage: string;
  @Input() forceIsPart: string;

  @Output('next') onNext = new EventEmitter();
  @Output('repeat') onRepeat = new EventEmitter();

  constructor(private remoteService: RemoteLibraryService) {}

  ngOnInit() { }

  public hasError() {
    const errors = JSON.parse(JSON.stringify(this.errors));
    errors.isNotPart = errors.isNotPart || this.isNotPart;
    return Object.values(errors).some((value) => !!value);
  }

  public next() {
    this.onNext.emit();
  }

  public repeat() {
    this.onRepeat.emit();
  }

  public close() {
    this.hidden = true;
  }

  public open() {
    this.hidden = false;
  }
}
