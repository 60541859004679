import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentFile, DocumentState } from '../document';

@Component({
  selector: 'app-document-file',
  templateUrl: './document-file.component.html',
  styleUrls: ['./document-file.component.scss']
})
export class DocumentFileComponent implements OnInit {
  @Input()
  public documentFile: DocumentFile;

  @Input()
  public title: string;

  @Input()
  public accept: string;

  @Output('fileChanged')
  public changeEmitter = new EventEmitter<DocumentFile>();

  @Output('deleted')
  public deletedEmitter = new EventEmitter<DocumentFile>();

  @ViewChild('fileInput')
  public fileInputRef: ElementRef<HTMLInputElement>;

  constructor() { }

  ngOnInit() {
  }

  fileInputClickHandler() {
    const canClick = this.documentFile.state === DocumentState.Empty && !this.documentFile.disabled;
    if(canClick) {
      this.fileInputRef.nativeElement.click();
    }
  }

  fileChangeHandler() {
    if(this.fileInputRef.nativeElement.files.length == 1) {
      this.documentFile.file = this.fileInputRef.nativeElement.files[0];
      this.changeEmitter.emit(this.documentFile);
      this.fileInputRef.nativeElement.value = '';
    }
  }

  deleteFileHandler() {
    const canClick = this.documentFile.state === DocumentState.Loaded && !this.documentFile.disabled;
    if(canClick) {
      this.deletedEmitter.emit(this.documentFile);
    }
  }

  getText() {
    return this.documentFile.state === DocumentState.Loaded ? this.documentFile.name : this.title;
  }

}
