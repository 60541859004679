import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RemoteLibraryService } from 'remote-library';

@Injectable({
  providedIn: 'root'
})
export class SupportBrowsersGuard implements CanActivate {

  constructor(
    private remoteService: RemoteLibraryService, private router: Router
  ) { }
  isIos = navigator.userAgent.indexOf('iPhone') >= 0;
  notSupportBrowsersiOS = [navigator.userAgent.match('CriOS'), navigator.userAgent.match('EdgiOS'), navigator.userAgent.match('FxiOS'),
    navigator.userAgent.indexOf('OPT') >= 0];
  notSupportBrowsers = [navigator.userAgent.indexOf('MiuiBrowser') >= 0, navigator.userAgent.indexOf('FB') >= 0,
    navigator.userAgent.toLowerCase().indexOf('firefox') >= 0, navigator.userAgent.indexOf('Edg') >= 0,
    navigator.userAgent.indexOf('OPR') >= 0];

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      console.log('BrowserGuard triggered');

      if (this.remoteService.selfAdjustService.secretKeyPromise) {
        await this.remoteService.selfAdjustService.secretKeyPromise;
      }

      if (this.isIos) {
        for (const notSupportiOS of this.notSupportBrowsersiOS) {
          if (notSupportiOS) {
            console.log('INFO: Navega a: warning-browser con secretKey:', this.remoteService.selfAdjustService.secretKey);
            this.router.navigate(['warning-browser', {secretKey: this.remoteService.selfAdjustService.secretKey}]);
            return false;
          }
        }
      } else {
        for (const notSupport of this.notSupportBrowsers) {
          if (notSupport) {
            console.log('INFO: Navega a:warning-browser con secretKey:', this.remoteService.selfAdjustService.secretKey);
            this.router.navigate(['warning-browser', {secretKey: this.remoteService.selfAdjustService.secretKey}]);
            return false;
          }
        }
      }
    return true;
  }
}
