import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { RemoteLibraryService } from 'remote-library';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotGuard<T> implements CanDeactivate<T> {

  constructor(private remoteLibraryService: RemoteLibraryService) {}
  public canDeactivate(component: T, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('Can deactivate?');
    console.log('currentState: ', currentState);
    const { caption, name } = currentRoute.data;

    return new Promise<boolean>((resolve, reject) => {
      setTimeout(async () => {
        try {
          await this.remoteLibraryService.selfAdjustService.captureScreen(caption, document.querySelector('.screenshot'), name);
        } catch(e) {
          console.log(e);
        }
        // Always resolve to true to avoid block the process
        resolve(true);
      }, 0);
      
    });
  }
}
