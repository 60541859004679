import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';

@Component({
  selector: 'app-warning-browser',
  templateUrl: './warning-browser.component.html',
  styleUrls: ['./warning-browser.component.scss']
})
export class WarningBrowserComponent implements OnInit, AfterViewInit {

  copiedUrl: string = "";
  openNotification: boolean;
  backgroundColor;
  logoNotSupported;
  isIOS: boolean;
  selfAdjustObject;
  public showLegalFooter = false;
  public cookiesPolicy = false;

  constructor(
    public remoteService: RemoteLibraryService,
  ) {
    this.isIOS = navigator.userAgent.indexOf('iPhone') >= 0;
    this.copiedUrl = window.location.href.replace('warning-browser','');
    this.selfAdjustObject = this.remoteService.selfAdjustService.actualSelfAdjust;
  }


  ngOnInit() {
    this.remoteService.selfAdjustService.changeLabels();
    this.remoteService.selfAdjustLanguageService.browserLanguage();
    this.openNotification = false;
    const { cookiesPolicy = {}, showLegalFooter } = this.remoteService.selfAdjustService.actualCompany;
    this.cookiesPolicy = this.remoteService.commonService.showCookiesModal(cookiesPolicy);
    this.showLegalFooter = showLegalFooter;
  }

  ngAfterViewInit() {
    this.remoteService.selfAdjustService.removeIndexLoader();
    WarningBrowserComponent.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }

    /**
     * @description Función para carga de estilos personalizados
     * @param customStylesFolder Nombre de la carpeta donde están los estilos personalizados
     */
    private static loadDynamicStyles(customStylesFolder) {
      if (customStylesFolder) {
        try {
          require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
        } catch (error) {
          console.log('Error loading the dynamic styles');
        }
      }
    }

  copyLink() {
    var aux = document.createElement("input");
    aux.setAttribute("value",this.copiedUrl);
    document.body.appendChild(aux);

    if (this.isIOS) {
      // Se guarda el contentEditable y readOnly
      const editable = aux.contentEditable;
      const readOnly = aux.readOnly;
      // Se crea un rango seleccionable
      const range = document.createRange();
      range.selectNodeContents(aux);
      // Se selecciona el rango
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      aux.setSelectionRange(0, 999999);
      // Se restaura los estados originales de contentEditable y readOnly
      aux.contentEditable = editable;
      aux.readOnly = readOnly;
    } else {
      // Unica accion a hacer en Android, que no se realiza para IOS
      aux.select();
    }

    // Se ejecuta el copiado
    const result = document.execCommand('copy');
    if (result) {
      this.showNotification();
    }
    document.body.removeChild(aux);
  }

  showNotification() {
    this.openNotification = true;
    setTimeout(()=>{
      this.openNotification = false;
    }, 2000)
  }
}