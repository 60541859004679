import { Component, Input, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';

@Component({
  selector: 'app-warning-luminosity',
  templateUrl: './warning-luminosity.component.html',
  styleUrls: ['./warning-luminosity.component.scss']
})
export class WarningLuminosityComponent implements OnInit {

    @Input() bright: boolean = false;
    @Input() dark: boolean = false;

    @Input() set type(value: photoType) {
      switch(value) {
        case 'frontRight':
        case 'rearLeft':
            this.horizontal = 'right';
            this.vertical = 'top';
            break;
        case 'front':
        case 'rear':
        case 'frontLeft':
        case 'rearRight':
        default:
            this.horizontal = 'left';
            this.vertical = 'top';
      }
    }
    @Input() horizontal: 'left' | 'right' = 'left';
    @Input() vertical: 'top' | 'bottom' = 'top';

    public message = {
      bright: '',
      dark: ''
    }

    constructor(private remoteService: RemoteLibraryService) {

      try {
        this.message.bright = this.remoteService.selfAdjustLanguageService.strLang('There is too much light');
        this.message.dark = this.remoteService.selfAdjustLanguageService.strLang('There is not enough light');
      } catch(e) {
        console.log(e);
      }
    }

    ngOnInit() { }
}

type photoType = 'front' | 'rear' | 'rearLeft' | 'frontLeft' | 'rearRight' | 'frontRight';