import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { RemoteLibraryService } from "remote-library";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: [
    "./gallery.component.scss",
    "../../../styles/layouts/button.scss",
  ],
})
export class GalleryComponent implements AfterViewInit, OnDestroy {
  @Input() images: {
    name: string;
    number: number;
    fix: boolean;
    fixed: boolean;
    isDocument?: boolean;
    captureMode?: 'camera' | 'none';
    warning: boolean;
  }[];
  @Input() index = 0;
  @Input() reopened = false;

  @Output() onClose = new EventEmitter<void>();
  @Output() onRepeat = new EventEmitter<{
    file: HTMLInputElement;
    index: number;
  }>();

  @ViewChild("swiper") swiper: ElementRef<any>;

  constructor(public remoteService: RemoteLibraryService) {}

  ngAfterViewInit(): void {
    this.swiper.nativeElement.swiper.activeIndex = this.index;
    this.swiper.nativeElement.addEventListener("swiperslidechange", () => {
      this.setIndexFromSwiper();
    });
    this.setIndexFromSwiper();
  }

  private setIndexFromSwiper(): void {
    this.index = this.swiper.nativeElement.swiper.activeIndex;
    this.remoteService.nativeCameraService.setCameraCapture(document.querySelector("#galleryInput"), this.images[this.index].captureMode || 'camera', this.images[this.index].isDocument);
  }

  public closeClick(): void {
    this.onClose.emit();
  }

  public repeatClick(): void {
    const input: HTMLInputElement = document.querySelector("#galleryInput");
    input.click();
  }

  public emitUpload(): void {
    this.onRepeat.emit({
      file: document.querySelector("#galleryInput"),
      index: this.index,
    });
  }

  public ngOnDestroy(): void {
    this.swiper.nativeElement.removeEventListener("swiperslidechange", () => {
      this.setIndexFromSwiper();
    });
  }
}
