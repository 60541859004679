import { Component, OnInit, ViewChild, ElementRef, SimpleChange, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import { take } from "rxjs/operators";

@Component({
  selector: 'app-damage-question',
  templateUrl: './damage-question.component.html',
  styleUrls: ['./damage-question.component.scss']
})
export class DamageQuestionComponent implements OnInit {

  damageQuestion: any;
  isMandatory: boolean = true;
  selected: boolean;
  loading: boolean = false;

  constructor(
    public router: Router,
    public remoteService: RemoteLibraryService,
    public cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.damageQuestion = this.remoteService.selfAdjustService.myPage('damage-question');
    this.isMandatory = this.damageQuestion.mandatory
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    if (this.remoteService.selfAdjustService.actualSelfAdjust.reportedDamage != undefined) {
      this.selected = this.remoteService.selfAdjustService.actualSelfAdjust.reportedDamage;
    }
  };

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      if(customStylesFolder === 'Banorte' || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-damage-question')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  toggleSelection(bool){
    this.selected = bool;
  }

  backPage(){
    console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    this.loading = true;
    this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
  }

  next() {
    this.loading = true;
    if(this.selected != undefined){
      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        fields2Update: {
          reportedDamage: this.selected,
        },
        status: this.damageQuestion.reopen ? this.remoteService.selfAdjustService.AppStatus.reopened : this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'damage-question',
          action: 'continue'
        }
      };
      this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(res => {
        localStorage.setItem('specialNav', JSON.stringify(
          {
            to: this.remoteService.selfAdjustService.nextPage[( this.selected ? 'forYes' : 'forNo')][1],
            from: [this.remoteService.selfAdjustService.getOrder('damage-question'),'damage-question']
          }
        ))
        if(this.selected){
          console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage['forYes'], 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
          this.router.navigate([...this.remoteService.selfAdjustService.nextPage['forYes'],{secretKey: this.remoteService.selfAdjustService.secretKey}])
        }else{
          console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage['forNo'], 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
          this.router.navigate([...this.remoteService.selfAdjustService.nextPage['forNo'], {secretKey: this.remoteService.selfAdjustService.secretKey}])
        }
      },err => {
        console.log(err);
        this.loading = false;
      })
    }
  }
}
