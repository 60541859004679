import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import { Zone } from '../environmental-capture-zone/environmental-capture-zone.component';

@Component({
  selector: 'app-environmental-capture',
  templateUrl: './environmental-capture.component.html',
  styleUrls: [
    './environmental-capture.component.scss',
  ],
})
export class EnvironmentalCaptureComponent {

  environmentalCapture;
  @Input() zones: Zone[] = [];
  @Input() zone: Zone;
  @Output() zoneChange = new EventEmitter<Zone>();

  constructor(public router: Router, public remoteService: RemoteLibraryService) {}

  selectPart(zone) {
    this.zone = zone;
    this.zoneChange.next(this.zone);
  };

  nextPageAvailable() {
    return !this.zones.some(zone => zone.completedSteps < 3);
  };

  private navigate(page) {
    console.log('INFO: Navega a:', page, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    const linkParametersArray = [
      ...page,
      {
        secretKey: this.remoteService.selfAdjustService.secretKey,
      },
    ];
    this.router.navigate(linkParametersArray);
  };

  backPage() {
    this.navigate(this.remoteService.selfAdjustService.backPage);
  };

  nextPage() {
    this.navigate(this.remoteService.selfAdjustService.nextPage);
  };
};
