import { Component, OnInit, ViewChild, ElementRef, SimpleChange, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import { take } from "rxjs/operators";

@Component({
  selector: 'app-information-page',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss']
})
export class InformationPageComponent implements OnInit {

  informationPage: any;
  isMandatory: boolean = true;
  selected: boolean;
  loading: boolean = false;
  selfadjust: undefined;

  constructor(
    public router: Router,
    public remoteService: RemoteLibraryService,
    public cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.informationPage = this.remoteService.selfAdjustService.myPage('information-page');
    this.isMandatory = this.informationPage.mandatory;
    this.selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
  };

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      if(customStylesFolder === 'Banorte' || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-information-page')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  public translate(key: string) {
    console.log(key);
    return this.remoteService.selfAdjustLanguageService.strLang(key, this.selfadjust);
  }

  backPage(){
    console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
    this.loading = true;
    this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
  }

  next() {
    this.loading = true;
      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'information-page',
          action: 'continue'
        },
        fields2Update: {},
      };
      this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(res=>{
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}])
      })
  }
}
