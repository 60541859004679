import { Component, OnInit, Input, ViewChild,ChangeDetectorRef, NgZone } from "@angular/core";
import html2canvas from 'html2canvas';
import { RemoteLibraryService } from "remote-library";
import { catchError, take } from "rxjs/operators";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";


@Component({
  selector: "app-car-parts",
  templateUrl: "./car-parts.component.html",
  styleUrls: ["./car-parts.component.scss"],
})
export class CarPartsComponent implements OnInit {
  inputsArr:Array<any>;
  loading: Boolean = false;
  loaded: Boolean = false;
  checkedArr:Array<any> = [];
  availableCar:Boolean = false;
  actualSelfadjust: any;
  carPartsDataBase: any;
  carParts: any;
  partVals: Object = {
    front: false,
    leftfrontdoor: false,
    rightfrontdoor: false,
    windscreen: false,
    rear: false,
    frontwingleft: false,
    frontwingright: false,
    leftreardoor: false,
    rightreardoor: false,
    rearwindow: false,
    rearwindowRight: false,
    rearwindowLeft: false,
    bonnet: false,
    roof: false
  };
  hasChanged: boolean = false;
  @ViewChild("carPartsContainer")
  public carPartsContainer: any;

  @Input()
  public carPartsImage: string;
  @ViewChild('myForm')
  public myForm: NgForm;
  public secretKey;
  public msg;
  public msgUploading;
  openNotification: Boolean = false;
  unfixed: boolean;

  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public zone: NgZone,
    public cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.carParts = this.remoteService.selfAdjustService.myPage('car-parts');
    if (this.carParts.fixed != undefined && !this.carParts.fixed) {
      this.unfixed = true;
    }
    this.availableCar = !this.carParts.mandatory;
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.updatePage(this.actualSelfadjust);
  };

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      if (customStylesFolder === 'Banorte' || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-car-parts')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  checked(aux) {
    if (this.carParts.fixed != undefined && !this.carParts.fixed) {
      this.unfixed = false;
    }
    this.partVals[aux] = !this.partVals[aux];
    if (this.partVals[aux]==true) {
      this.checkedArr.push('1');
    } else {
      this.checkedArr.pop();
    }
    if (this.carParts.mandatory) {
      if (this.checkedArr.length >= 1) {
        this.availableCar = true;
      } else {
        this.availableCar = false;
      }
    }
  }

  async submitCarMap(action) {
    this.loading = true;
    this.hasChanged = this.compareCarParts();
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    if (!this.carParts.reopen && this.carPartsDataBase && !this.hasChanged) {
      this.movePage(action);
    } else {
      html2canvas(this.carPartsContainer.nativeElement, {} as any).then(canvas => {
        this.carPartsImage = canvas.toDataURL('image/jpeg', 0.7);
        let contentType = this.carPartsImage.slice(this.carPartsImage.indexOf('image/'),this.carPartsImage.indexOf(';'));
        const caption = 'Damaged parts';
        this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust['id'], 'jpeg').pipe(take(1)).subscribe(res => {
          this.remoteService.selfAdjustService.bucketImage(this.carPartsImage, res['media_url'], contentType).pipe(take(1)).subscribe((result: any) => {
            let logInfo = {
              component: 'car-parts',
              action: 'push-image'
            }
            this.remoteService.selfAdjustService.addImage(this.actualSelfadjust['id'], this.actualSelfadjust['securityKey'],
                res['media_id'], 'car', 'jpeg', logInfo, coordinates, caption).subscribe(e => {
                this.movePage(action);
                if (this.carParts.fixed != undefined && !this.carParts.fixed) {
                  this.remoteService.selfAdjustService.fixedPage('car-parts');
                }
              },
              err => {
                  console.log("Error in addImage",err);
                  this.showNotification();
                  return of(err);
              });                   
          },
          err=>{
              console.log("Error in bucketImage",err);
              this.showNotification();
          })
        },
        err=>{
            console.log("Error in getMediaUrl",err);
            this.showNotification();
        })
      });
    }
  }

  compareCarParts(){
    if(this.actualSelfadjust.carpart){
      for(let key in this.partVals){
        if(this.partVals[key] != this.carPartsDataBase[key]){
          return true;
        }
      }
      return false;
    } else {
      for(let key in this.partVals){
        if(this.partVals[key]){
          return true;
        }
      }
      return false;
    }
  }

  showNotification() {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
      this.cd.detectChanges();
    },5000)
    this.loading = false;
    this.cd.detectChanges();
  }

  updatePage(actualSelfadjust) {
    console.log("actualSelfadjust",actualSelfadjust)
    if (actualSelfadjust.carpart) {
      this.carPartsDataBase = actualSelfadjust.carpart;
      for (let part in this.carPartsDataBase) {
        if (this.carPartsDataBase[part]) {
        this.partVals[part] = this.carPartsDataBase[part];
        this.checkedArr.push('1');
        this.availableCar = true;
        }
      }
    }
    if (this.myForm) {
      this.myForm['_directives'].forEach(e => {
        if (actualSelfadjust[e.name]) {
          e._updateValue(actualSelfadjust[e.name]);
        }
      });
    }
    this.loaded = true;
  }

  movePage(action) {
    this.loading = true;
    if (action=='next') {
      let data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.carParts.reopenFields ? this.remoteService.selfAdjustService.AppStatus.reopened :  this.remoteService.selfAdjustService.AppStatus.inprocess,
        fields2Update: {
          carpart: this.partVals
        },
        logInfo: {
          component: 'car-parts',
          action: 'continue'
        }
      };
      this.remoteService.selfAdjustService.actualSelfAdjust.carpart = this.partVals;
      if(this.remoteService.selfAdjustService.getReopenInfo() && this.carParts.fixed != undefined){
        data.fields2Update['reopenInfo'] = { pages: null };
        data.fields2Update['reopenInfo'].pages = this.remoteService.selfAdjustService.getReopenInfo()
      }
      if (this.carParts.selfadjustFields) {
        this.myForm["_directives"].forEach(e=>{
          data.fields2Update[e.name] = e.value
        })
      }
      if (this.carPartsDataBase && !this.hasChanged) {
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);

      } else {
        this.remoteService.selfAdjustService.pushData(data).subscribe(res=>{
          console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
          this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
        },
        err=>{
          console.log("Error in selfadjust-update",err);
          this.showNotification();
        });
      }
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }
}
