import { Component, Input, OnInit } from '@angular/core';
import { RemoteLibraryService } from "remote-library";

@Component({
  selector: 'app-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss']
})
export class ErrorNotificationComponent implements OnInit {

  @Input()
  public message = 'Error in image upload. Please, try again.';

  constructor(
    public remoteService: RemoteLibraryService
  ) { }

  ngOnInit() {
  }

}
