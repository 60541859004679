import { AfterContentInit, Component, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-use-conditions',
  templateUrl: './use-conditions.component.html',
  styleUrls: ['./use-conditions.component.scss']
})
export class UseConditionsComponent implements OnInit, AfterContentInit {
  loading: boolean = true;
  customConditions: boolean = false;
  userLang: any;
  templateString: string;
  cookiesPolicy: Boolean = false;
  showCookiesPolicy: Boolean = false;

  protected documents = [];
  protected documentTypesMap = {
    useConditionsSelfadjust: ['all', 'terms'],
    usePrivacySelfadjust: ['use'],
    cookiesPolicy: {
      content: ['cookies'],
    }
  };

  constructor(
    public remoteService: RemoteLibraryService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
  ) { }

  ngOnInit() {
    const lng: string = localStorage.getItem('fixedLanguage') || window.navigator.language;
    const type: 'all' | 'terms' | 'use' | 'cookies' = this.route.snapshot.params.type;

    this.userLang = lng ? lng.slice(0, 2) : 'en';
    this.remoteService.selfAdjustService.reloadStylesFromComponent();

    Object.keys(this.documentTypesMap).forEach((key) => {
      if (Array.isArray(this.documentTypesMap[key])) {
        if (this.documentTypesMap[key].includes(type)) {
          const document = this.remoteService.selfAdjustService.actualCompany[key];
          if (document) {
            this.documents.push(document);
          }
        }
      } else {
        if (this.documentTypesMap[key].content.includes(type)) {
          const document = this.remoteService.selfAdjustService.actualCompany[key].content;
          if (document) {
            this.documents.push(document);
          }
        }
      }
    });

    this.customConditions = this.documents.length > 0;

    if (!this.customConditions) {
      try {
        this.templateString = require(`./contents/${type}/${this.userLang}.html`);
      } catch (error) {
        console.error(error);
        this.templateString = require(`./contents/${type}/en.html`);
      }
    }

    if (this.location.path().includes('cookies')) {
      this.cookiesPolicy = (this.remoteService.selfAdjustService.actualCompany.cookiesPolicy || {}).show || false;
    }
    this.loading = false;
  }

  backClicked() {
    this.location.back();
  }

  ngAfterContentInit() {
    this.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {}
    }
  }
}
