import { Component, OnInit, Input, Output, EventEmitter, IterableDiffers, IterableDiffer, DoCheck, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { RemoteLibraryService } from 'remote-library';
import { EnvironmentalCaptureZoneStepConstants } from './constants';

type Example = {
  type: 'video' | 'img',
  src: string,
  badge?: {
    text: string,
    color: string,
    icon?: string,
  }
};

export type Photo = {
  src: SafeStyle,
  uploading: boolean,
}

@Component({
  selector: 'app-environmental-capture-zone-step',
  templateUrl: './environmental-capture-zone-step.component.html',
  styleUrls: [
    './environmental-capture-zone-step.component.scss',
  ],
})
export class EnvironmentalCaptureZoneStepComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() zone: string;
  @Input() number: number;
  @Input() collapsed: boolean = false;
  @Input() disabled: boolean = false;
  @Input() photos: Photo[] = [];
  @Input() ruler = false;
  @Input() additionalImages = 0;
  
  @Input() completed: boolean = false;
  @Output() completedChange = new EventEmitter<boolean>();
  @Output() upload: EventEmitter<Event> = new EventEmitter();

  exampleImages: Example[];
  @ViewChild('inputAdditionalPhoto') inputAdditionalPhoto: ElementRef;

  constructor(
    public remoteService: RemoteLibraryService,
    protected domSanitizer: DomSanitizer
  ) {};
  
  ngOnInit() {
    this.exampleImages = this.getExampleImages();
  };


  getExampleImages(): Example[] {
    return EnvironmentalCaptureZoneStepConstants.ExampleImages[this.zone+(this.number - 1)];
  };

  getSafeStyleUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  imgUpload(event: Event) {
    this.upload.next(event);
  }

  canAddAdditionalImages() {
    return this.photos.length < this.additionalImages + 1;
  }

  takeAdditionalPhoto() {
    this.inputAdditionalPhoto.nativeElement.click();
  }
};
