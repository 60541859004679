import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from "@angular/core";
import { RemoteLibraryService } from "remote-library";

export enum UploadAreaState {
    READY = 'ready',
    LOADING = 'loading',
    DISABLED = 'disabled',
    COMPLETED = 'completed',
    RETAKE = 'retake'
}

type Image = {
    id: string;
    auxId: string;
    name: string;
    icon: string;
    state: UploadAreaState;
    mandatory: boolean;
    warning?: boolean;
}

@Component({
    selector: 'upload-area',
    templateUrl: './upload-area.component.html',
    styleUrls: ['./upload-area.component.scss'],
    encapsulation: ViewEncapsulation.None // Permite al component ser estilado desde fuera (estilos custom)
})
export class UploadAreaComponent implements OnChanges {
    @Input() public image: Image;
    @Input() public imagePreview: string | undefined;
    @Output() public warningClick = new EventEmitter<void>();

    public readonly remoteService: RemoteLibraryService;
    protected isDocument = false;

    public constructor(remoteService: RemoteLibraryService) {
        this.remoteService = remoteService;
    }

    private checkIsDocument(url: URL) {
        if (url.toString().startsWith('data:application/pdf')) {
            return true;
        }
        return url.pathname.split('.').pop().toLowerCase() === 'pdf';
    }

    public ngOnChanges(): void {
        if (this.imagePreview) {
            const url = new URL(this.imagePreview);
            this.isDocument = this.checkIsDocument(url);
        }
    }
}
