import { Component, OnInit, AfterContentInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

import * as carAI from '../../aiHandlers/carHandler.js';
import * as motoAI from '../../aiHandlers/motoHandler.js';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: [
    './landing-page.component.scss'
    ]
})
export class LandingPageComponent implements OnInit, AfterContentInit, AfterContentChecked {
  coords: any = {
    lat: '',
    lng: ''
  };
  errorLoading = false;//controla si se muestre el error de "esta pagina no existe"
  showCameraPermissions: Boolean = false;
  showLocationPermissions: Boolean = false;
  nextAvailable: Boolean = false;
  loaded: Boolean = false;
  outOfDate:any = false;
  dateTo: any;
  dateFrom:any;
  oneTime: number = 0;
  deadLineActive = false;
  loading: boolean = false;
  cancelled:boolean = false;
  firstOpen: boolean = true;
  showCheck: boolean;
  hideTermsAndConditions: boolean = false;
  smartphotosplitToS: boolean = false;
  packagedVersion: String;
  actualAgent: any = undefined;
  acceptConditions: Boolean = null;
  acceptPrivacy: Boolean = null;
  device: object;
  cookiesPolicy: Boolean = null;
  showLegalFooter: Boolean = null;

  public permissionLoad = {
    camera: false,
    location: false,
  }

  constructor(
    private router: Router,
    public remoteService: RemoteLibraryService,
    public cd: ChangeDetectorRef,
  ) {
    this.packagedVersion = this.remoteService.selfAdjustService.packagedVersion;
    this.actualAgent = this.remoteService.selfAdjustService.actualAgent;
    console.log('VERSION: ', this.packagedVersion)
  }

  ngOnInit() {
    this.loaded = true;
    if (!this.remoteService.selfAdjustService.actualSelfAdjust) {
      this.errorLoading = true;
    }

    if (this.remoteService.selfAdjustService.actualSelfAdjust.assigned_agent_id) {
      this.loadAssignedAgent(
        this.remoteService.selfAdjustService.secretKey,
        this.remoteService.selfAdjustService.actualSelfAdjust.assigned_agent_id
      );
    }
    this.device = this.remoteService.selfAdjustService.deviceInfo();

    const { cookiesPolicy = {}, showLegalFooter } = this.remoteService.selfAdjustService.actualCompany;
    this.cookiesPolicy = this.remoteService.commonService.showCookiesModal(cookiesPolicy);
    this.showLegalFooter = showLegalFooter;
  }

  ngAfterContentInit() {
    this.loadDynamicStyles(this.remoteService.selfAdjustService.actualCompany.customStylesFolder);
  }

  goToCameraPermissions() {
    this.showCameraPermissions = !this.showCameraPermissions;
    try {
      this.remoteService.aiService.load({ carAI, motoAI });
    } catch(e) { 
      console.log('Error loading models...');
      console.log('Error: ', e);
    }
  }

  private starSelfadjust = function () {
    const dataPush = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      status: this.remoteService.selfAdjustService.AppStatus.inprocess,
      fields2Update:{
        remote_web_device: this.device,
      },
      logInfo: {
        component: "landing-page",
        action: "start"
      }
    };
    this.remoteService.selfAdjustService.pushData(dataPush).pipe(take(1)).subscribe();
  };

  ngAfterContentChecked() {
    if (!this.errorLoading) {
      if (this.oneTime < 1) {
        if (this.remoteService.selfAdjustService.actualSelfAdjust.reopenInfo) {
          this.firstOpen = false;
          if(this.remoteService.selfAdjustService.actualCompany.hideTermsAndConditionsForReopened) {
            this.hideTermsAndConditions = true;
            this.showCheck =true;
            this.nextAvailable = true;
          }
        } else {
          this.starSelfadjust();
          if (this.remoteService.selfAdjustService.actualSelfAdjust.deadlineSelfadjust) {
            this.checkLimitDay(this.remoteService.selfAdjustService.actualSelfAdjust);
          }
        }
        if ((this.remoteService.selfAdjustService.actualCompany.hideTermsAndConditionsForReopened && !this.firstOpen) || this.remoteService.selfAdjustService.actualCompany.hideRemotesToS) {
          console.log('ToS escondidos por configuracion.');
          this.hideTermsAndConditions = true;
          this.showCheck = true;
          this.nextAvailable = true;
        }
        this.oneTime++;
      }
    }
  }

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  async getLocation() {
    if (!navigator.geolocation || this.permissionLoad.location) {
      return;
    }

    try {
      this.permissionLoad.location = true;
      const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
      this.GPSPermissionsAccepted(!!coordinates);
      
      if (!coordinates) {
        console.log('Permisos de GPS denegados');
      } else {
        this.coords = coordinates;
      }

      this.submitInsuranceFormPage();
    } catch(e) {
      console.log('Error requesting location permission: ', e);
      throw e;
    } finally {
      this.permissionLoad.location = false;
    }
  }

  public async getCameraPermissions() {
    try {
      if (this.permissionLoad.camera) {
        return;
      }

      this.permissionLoad.camera = true;
      await this.remoteService.nativeCameraService.getCameraPermissions();
    } catch(e) {
      console.log('Error requesting camera permission: ', e);
      throw e;
    } finally {
      this.permissionLoad.camera = false;
      const { hideLocation = false } = this.remoteService.selfAdjustService.myPage('landing');
      this.showLocationPermissions = !hideLocation;

      if (!this.showLocationPermissions) {
        this.submitInsuranceFormPage();
      }
    }
  }

  /**
   * @description guardar en el autoajuste e histórico si se han aceptado los permisos de GPS.
   */
  private GPSPermissionsAccepted(accepted) {
    const data = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      status: this.remoteService.selfAdjustService.AppStatus.inprocess,
      logInfo: {
        component: 'landing-page',
        action: accepted ? 'GPS Permissions were accepted' : 'GPS Permissions were denied',
        when: Math.trunc(Date.now() / 1000),
        who: 'anon',
        rol: '(remote user)',
        key: accepted ? 'user_gps_accepted' : 'user_gps_denied',
      },
      fields2Update: {
        GPSPermissionsAccepted: accepted,
      },
    };
    this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(() => {}, (err) => {
      console.log('selfAdjustService.pushData error', err);
    });
  }

  checkLimitDay(selfAdjust) {
    var today = moment(new Date());
    var deadLineDate = moment(new Date(selfAdjust.deadlineSelfadjust.split("/").reverse().join("/")));

    if(deadLineDate.isSameOrAfter(today,'day')){
      this.deadLineActive = true;
    } else {
      this.errorLoading = true;
      this.outOfDate = true;
      this.loaded = true;
    }

    this.dateTo = selfAdjust.deadlineSelfadjust;
  }

  showPosition(position) {
    this.coords.lat = position.coords.latitude;
    this.coords.lng = position.coords.longitude;
  }

  viewConditions() {
    const { secretKey } =  this.remoteService.selfAdjustService;

    if(this.remoteService.selfAdjustService.actualCompany.splitToS){
      this.router.navigate([`use-conditions/terms`, { secretKey }]);
    }
    else{
      this.router.navigate([`use-conditions/all`, { secretKey }]);
    }

  }

  viewPrivacy() {
    this.router.navigate(['use-conditions/use', { secretKey: this.remoteService.selfAdjustService.secretKey }]);
  }

  checkLegalConditions() {
    this.acceptConditions = !this.acceptConditions;
    if(this.remoteService.selfAdjustService.actualCompany.splitToS) {
      this.nextAvailable = this.acceptPrivacy && this.acceptConditions;
    } else {
      this.nextAvailable = this.acceptConditions;
    }
  }

  checkPrivacy() {
    this.acceptPrivacy = !this.acceptPrivacy;
    this.nextAvailable = this.acceptPrivacy && this.acceptConditions;
  }

  /**
   * @description navegar hasta el check de permisos y mostrar mensaje.
   */
  goToTerms() {
    if (this.nextAvailable) {
      const data: any = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        fields2Update: {
          startedAt: Math.trunc(Date.now() / 1000),
        },
      };
      
      if (!this.hideTermsAndConditions) {
        data.fields2Update.termsAndConditionsAcceptedAt = Math.trunc(Date.now() / 1000);
      }

      if(this.remoteService.selfAdjustService.actualSelfAdjust.reopenInfo) {
        data.fields2Update.reopeningStartedAt = Math.trunc(Date.now() / 1000);
      }

      this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe();
    } else {
      document.getElementById('warning').scrollIntoView();
    }
  }

  submitInsuranceFormPage() {
    // Screen Recorder Consent & Identify
    this.remoteService.selfAdjustService.recorderIdentify();
    this.loading = true;
    let data: any = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      status: this.remoteService.selfAdjustService.AppStatus.inprocess,
      logInfo: {
        component: 'landing-page',
        action: 'continue'
      }, 
      fields2Update: {
        onBoardingFinishedAt: Math.trunc(Date.now() / 1000),
      },
    };
    if (this.remoteService.selfAdjustService.actualSelfAdjust.reopenInfo) {
      data.fields2Update.reopeningOnBoardingFinishedAt = Math.trunc(Date.now() / 1000);
    }
    if (!this.remoteService.selfAdjustService.actualSelfAdjust.lat || !this.remoteService.selfAdjustService.actualSelfAdjust.lng) {
      data.fields2Update.lat = this.coords.lat || undefined;
      data.fields2Update.lng = this.coords.lng || undefined;
    }
    this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(res => {
      this.redirect();
    });
  }

  redirect() {
    var pageName = this.remoteService.selfAdjustService.customNavigation[1].name;
    if ((pageName.startsWith('side-pictures') || pageName.startsWith('moto-side-pictures') || pageName.startsWith('truck-side-pictures')) && this.remoteService.selfAdjustService.enableIA) {
      console.log(`INFO: Navega a: ${this.remoteService.selfAdjustService.customNavigation[1].name}-ai con secretKey:`, this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([1,`${this.remoteService.selfAdjustService.customNavigation[1].name}-ai`, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.customNavigation[1].name, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([1,this.remoteService.selfAdjustService.customNavigation[1].name, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }

  private loadAssignedAgent = function (secretKey, assignedAgentId) {
    this.remoteService.selfAdjustService.getAgentData(secretKey, assignedAgentId).pipe(take(1)).subscribe((result: any) => {
      this.actualAgent = result.entity;
    }, (err: any) => {
      console.log('getAgentData catch', err);
    });
  };
}
