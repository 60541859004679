import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RemoteLibraryService } from 'remote-library';

@Injectable({
  providedIn: 'root'
})
export class CoherenceGuard implements CanActivate {

  constructor(private remoteService: RemoteLibraryService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const params = state.root.children[0].children[0].params;
      console.log('params en coherence guard', params);
      if(params.order == '0'){
        console.log('INFO: Navega a: landing con secretKey:', params.secretKey);
        this.router.navigate(['landing', {secretKey: params.secretKey}]);
        return true
      } else {
        if (!this.remoteService.selfAdjustService.coherentRoute(state.url)) {
          console.log('INFO: Navega a: landing con secretKey:', params.secretKey);
          this.router.navigate(['landing', {secretKey: params.secretKey}]);
        } else {
          return this.remoteService.selfAdjustService.coherentRoute(state.url);
        }
      }

  }
}
