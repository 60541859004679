import { Component, OnInit } from '@angular/core';
import { ISelfadjustError } from 'projects/remote-library/src/interfaces/selfadjust-error.interface';

@Component({
  selector: 'app-in-use-camera-error',
  templateUrl: './in-use-camera-error.component.html',
  styleUrls: ['./in-use-camera-error.component.scss'],
})
export class InUseCameraErrorComponent implements OnInit {
  public error: ISelfadjustError = {
    icon: '../../assets/images/error/shared/camera.svg',
    name: 'Camera already in use',
    description: [
      'Another application is using the camera and we cannot access it.',
      'Close the application on your device to continue the process.',
    ],
    button: 'Start over',
  };

  constructor() {}

  ngOnInit() {}

  public restart(link: string) {
    console.log('Restarting...');
    window.location = link as any;
  }
}
