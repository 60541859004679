import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RemoteLibraryService } from "remote-library";
import { Router } from '@angular/router';

@Component({
  selector: 'app-final-page',
  templateUrl: './final-page.component.html',
  styleUrls: [
    './final-page.component.scss',
  ]
})

export class FinalPageComponent implements OnInit, AfterViewInit {
  public openURLError = false;
  public finalPage;
  public showCaseNumber = false;
  public caseNumber = "";
  public selfadjust = undefined;
  public showLegalFooter = false;
  public alerts: {condition: string, label: string}[] = [];
  public resultMessage?: string;
  public resultType?: 'ok' | 'warning' = undefined;

  constructor(
    public remoteService: RemoteLibraryService,
    private router: Router
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.finalPage = this.remoteService.selfAdjustService.myPage('final');
    this.showCaseNumber = this.finalPage.showCaseNumber ? this.finalPage.showCaseNumber : false;
    this.caseNumber = this.remoteService.selfAdjustService.actualSelfAdjust.caseRef;
    this.selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.remoteService.selfAdjustService.saUrls = this.remoteService.selfAdjustService.actualCompany.saUrls;
    this.showLegalFooter = this.remoteService.selfAdjustService.actualCompany.showLegalFooter;
    if(![this.remoteService.selfAdjustService.AppStatus.final, this.remoteService.selfAdjustService.AppStatus.review].includes(this.remoteService.selfAdjustService.actualSelfAdjust.status)) {
      this.redirect();
    }
    
    this.alerts = this.finalPage.alerts;
    if(this.alerts !== undefined) {

      if (this.selfadjust.waitingForAI === false) {
        return this.setResultMessage();
      }

      this.getSelfadjustWhenAIReady().then(selfadjust => {
        this.selfadjust = this.remoteService.selfAdjustService.actualSelfAdjust = selfadjust;
        this.setResultMessage();
      });
    }
  };
  
  ngAfterViewInit() {
    this.remoteService.selfAdjustService.clearImageWarnings();
    this.remoteService.nativeCameraService.removeCameraUse();
    this.remoteService.selfAdjustService.stopCoordinates();
  }

  redirect(){
    this.router.navigate(['landing', {secretKey: this.remoteService.selfAdjustService.secretKey}]).then( () => {
      location.reload();
    });
  }

  formatedDate() {
    var options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date().toLocaleDateString('es-ES', options);
  };

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      if(customStylesFolder === 'Banorte' || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-final-page')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }
  
  onClickFinish() {
    window.close();
  }
  
  redirectToCompany(){
    window.location.href = this.remoteService.selfAdjustService.saUrls.companyWeb
  }

  protected getSelfadjustWhenAIReady = () => new Promise(resolve => {
    const fetchSelfadjust = async () => {
      const secretKey = this.remoteService.selfAdjustService.secretKey;
      
      let selfadjust;
      try {
        selfadjust = (await this.remoteService.selfAdjustService.loadData(secretKey).toPromise() as any).entity;
      } catch {};

      if (selfadjust && selfadjust.waitingForAI === false) {
        resolve(selfadjust);
      } else {
        setTimeout(fetchSelfadjust, 30 * 1000);
      }
    };
    fetchSelfadjust();
  });

  protected setResultMessage() {
    let message = this.generateResultMessage();
    
    this.resultType = 'warning';
    if(message === undefined) {
      this.resultType = 'ok';
      message = 'smartphoto_final_ok';
    }

    this.resultMessage = message;
  }

  protected generateResultMessage(): string | undefined {
    const alert = this.alerts.find(({ condition }) => {
      try {
        return this.remoteService.selfAdjustService.evalInScope(condition, this.selfadjust);
      } catch(e) {
        console.error(e);
      }

      return false;
    });

    return alert ? alert.label : undefined;
  }

}
