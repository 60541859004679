import { Component, OnInit, OnDestroy } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.scss']
})
export class FeedbackPageComponent implements OnInit, OnDestroy {
  grade;
  feedBackPage: any;
  loading: Boolean = false;
  loaded: Boolean = false;
  nextAvailable: boolean = false;
  happy: boolean = false;
  regular: boolean = false;
  sad: boolean = false;
  feedbackComment: string;
  pushDataSub: Subscription;

  constructor(
    public remoteService:RemoteLibraryService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();
    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    if(this.remoteService.selfAdjustService && this.remoteService.selfAdjustService.actualSelfAdjust && this.remoteService.selfAdjustService.actualSelfAdjust.feedbackComment){
      this.feedbackComment = this.remoteService.selfAdjustService.actualSelfAdjust.feedbackComment;
    }else{
      this.feedbackComment = "";
    }
    this.feedBackPage = this.remoteService.selfAdjustService.myPage('feedback');
    this.nextAvailable = !this.feedBackPage.mandatory;
    this.loaded = true
  };

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      console.log(customStylesFolder);
      if(customStylesFolder === 'Banorte' || customStylesFolder === 'banorteIV') {
        // document.getElementsByTagName('app-feedback-page')[0]['style'].position = 'fixed';
      }
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  feedback(mood){
    if(mood == "happy"){
      this.nextAvailable = true
      this.happy = true
      this.regular = false
      this.sad = false
      this.grade= 10;
    }
    if(mood == "regular"){
      this.nextAvailable = true
      this.happy = false
      this.regular = true
      this.sad = false
      this.grade= 6;

    }
    if(mood == "sad"){
      this.nextAvailable = true
      this.happy = false
      this.regular = false
      this.sad = true
      this.grade= 1;
    }
  }

  next(){
    this.loading = true;
    let data = {
      security_key: this.remoteService.selfAdjustService.secretKey,
      status: this.feedBackPage.reopen ? this.remoteService.selfAdjustService.AppStatus.reopened : this.remoteService.selfAdjustService.AppStatus.inprocess,
      logInfo: {
        component: 'feedback-page',
        action: 'continue'
      },
      fields2Update: {
        grade: this.grade,
        feedbackComment: (this.feedbackComment && (this.feedBackPage.activateCommentValue >= this.grade)) ? this.feedbackComment : null
      }
    }
    console.log(this.feedbackComment)
    console.log(this.feedbackComment && (this.feedBackPage.activateCommentValue >= this.grade), (this.feedbackComment),this.feedBackPage.activateCommentValue >= this.grade)
    // Para activar los comentarios de feedback, el valor de 'activateCommentValue' debe ser un numero 'mayor o igual' 
    // que los valores de 'this.grade' para los que se quiere hacer posible comentar
    // EJ: if(this.feedBackPage.activateCommentValue == 6) => Solo es posible comentar cuando se selecciona 'regular' o 'sad'
    this.remoteService.selfAdjustService.pushData(data).pipe(take(1)).subscribe(res=>{
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}])
    })
  }

  ngOnDestroy(){
    window.releaseEvents()
    console.log('Destroying Feedback Page!')
  }
}
