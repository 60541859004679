export const VEHICLE_PARTS = {
    car: {
        'front': 'front',
        'rear': 'rear',
        'frontLeft': 'front left',
        'frontRight': 'front right',
        'rearLeft': 'rear left',
        'rearRight': 'rear right'
    },
    moto: {
        'front': 'front',
        'rear': 'rear',
        'left': 'left',
        'right': 'right',
    }
};
