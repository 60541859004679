import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { RemoteLibraryService } from 'remote-library';

@Component({
  selector: 'app-warning-blocked',
  templateUrl: './warning-blocked.component.html',
  styleUrls: ['./warning-blocked.component.scss']
})
export class WarningBlockedComponent implements OnInit {

    public logo: string;
    public warningStatement: string;
    public warningRequest: string;
    public day: string;
    public warningDismissal: string;
    public errors: string[];
    public date: string;

    constructor(private remoteLibraryService: RemoteLibraryService) { }

    ngOnInit() {
        this.logo = this.remoteLibraryService.selfAdjustService.logo.navbar() || '../../assets/images/logo/bdeoLogoNew.svg';

        const translate = this.remoteLibraryService.selfAdjustLanguageService.strLang.bind(this.remoteLibraryService.selfAdjustLanguageService);
        this.warningStatement = translate('We are sorry, the photos do not meet our quality standards for the following reasons');
        const warningRequest = translate('Please take photos directly above your vehicle using the link provided above. It will be active until the day');
        const splitted = warningRequest.split(' ');
        const day = splitted.pop();
        this.warningRequest = splitted.join(' ');
        this.day = day;
        this.warningDismissal = translate('Thank you for your understanding and collaboration');

        const errors = JSON.parse(localStorage.getItem('blocked') || "{}");
        const { part } = errors;

        const translations = {
          isScreener: `${translate("Photos should not be on the screen of a device")}.`,
          isBadLight: `${translate("The photo is a little dark")}.`,
          isDirty: `${translate("The vehicle appears to be covered in dust or dirt")}.`,
          isCropped: `${translate("It looks like the vehicle part is cut off in the photo")}.`,
          isNotPart: translate(`The ${part} part of the vehicle is not correctly shown.`)
        }

        const errorsTranslated = Object.entries(errors)
                                  .filter(([ , value ]) => value === true)
                                  .map(([ key ]) => translations[key]);
        console.log('Errors: ', errors);
        this.errors = errorsTranslated;
        const { deadlineSelfadjust } = this.remoteLibraryService.selfAdjustService.actualSelfAdjust;
        var deadLineDate = moment(new Date(deadlineSelfadjust.split("/").reverse().join("/")));

        this.date = deadLineDate.format('DD/MM/yyyy');
    }
}